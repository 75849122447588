@import '../../../../resources/styles/variables/references';

.pager{   
    display: flex;

    .page-size-selection{
        vertical-align:middle;
        padding-top: 1px;

        select{
            height: 25px;
        }
    }

    .pagination-label{
        padding-left: 10px;
        padding-right: 10px;
        vertical-align:middle;
        padding-top: 4px;
    }
  
    //For small screen
    @media (min-width: 320px) and (max-width:479px){
        .pagination-label{
            padding: 0px !important;
            margin-top:5px !important;
        }
    }
    //For Medium sized Screen
    @media (min-width: 480px) and (max-width:639px){
        .pagination-label{
            padding: 0px !important;
            margin-top:5px !important;
        }
    }
    //For Large sized Screen
    @media (min-width: 640px) and (max-width:1023px){
        .pagination-label{
            padding: 0px !important;
            margin-top:5px !important;
        }
    }

    .pagination{   
        display: inherit;
        margin-left: auto;

        .pageno-input {
            vertical-align:middle;
            padding-right: 20px;
            padding-left: 10px;
            input{
                width: 56px;
                height: 20px;
                font-size: 13px;
            }
        }

    //For small screen
    @media (min-width: 320px) and (max-width:479px){
        .pageno-input{
            width:140px ;
            padding: 0px ;
        }
        .pageLabel {
            display: none
        }
        .pagesizeLabel{
            display: none 
        }
    
    }
    //For Medium sized Screen
    @media (min-width: 480px) and (max-width:639px){
        .pageno-input{
            width:140px ;
            padding: 0px ;
        }
        .pageLabel {
            display: none
        }
        .pagesizeLabel{
            display: none 
        }
    
    }
    //For Large sized Screen
    @media (min-width: 640px) and (max-width:1023px){
        .pageno-input{
            width:140px ;
            padding: 0px ;
        }
        .pageLabel {
            display: none
        }
        .pagesizeLabel{
            display: none 
        }
    
    }
      
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        ul a {
            height: 24px;
            width: 24px;
            display: flex;        
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            color: $ms-color-black;
            position: relative;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
        }     

        a::before a::after{
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            content: "";
            position: absolute;
            height: 50%;
            background-color: #aaa;
        }
        a:hover {
            background-color: #0079c1;
            color: #ffffff;
            border: 1px solid #0079c1;
        }

        a:hover::before, a:hover::after {
            background-color: #fff; 
        }
        a.active {
            background-color: #0079c1;
            color: #fff;
            border: 1px solid #0079c1; 
        }  
    }
}
//For small screen
@media (min-width: 320px) and (max-width:479px){
    .search-result-view{
        display: inline-block;
    }
    .pages{
        margin-top:5px !important; 
    }
    #pageNo{
        width:45px;
    }
    .page-size{
        margin-bottom: 30px;
    }
}
//For Medium sized Screen
@media (min-width: 480px) and (max-width:639px){
    .search-result-view{
        display: inline-block;
    }
    .pages{
        margin-top:5px; 
    }
    #pageNo{
        width:45px;
    }
    .page-size{
        margin-bottom: 30px;
    }
}
//For Large sized Screen
@media (min-width: 640px) and (max-width:1023px){
    .search-result-view{
        display: inline-block;
    }
    .pages{
        margin-top:5px; 
    }
    #pageNo{
        width:45px;
    }
}

@media (min-width: 641px) and (max-width:800px){
    .page-size{
        margin-bottom: 30px;
    }
}