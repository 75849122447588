@import '../../../../resources/styles/variables/references';

.autocomplete {
    text-align: left;
    .autocomplete-details {
        height: 125px;
        max-height: 125px;
        right: 15px;
        left: 15px;
        border-top: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: absolute;
        z-index: 1;
        }
        .ms-DetailsRow-cell
        {
           
            font-size: 16px;
            color: $ms-color-black;
            background-color: $ms-color-card-background;
            &:hover, &:focus{
                background: $ms-color-active-button ;
                color: $ms-color-white;
                font-weight: bold;
            }
        }
    .loader{
        position: relative;
    }
    .loader-position{
        position: absolute;
        right: 2px;
        padding: 34px 4px 0 0;
        z-index: 1;
    }
}



.auto-complete {   
    position: relative;
    display: inline-block;
    width: 100%;
    
    .auto-complete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;   
        top: 100%;
        left: 0;
        right: 0;
        overflow-y: scroll;
        max-height: 200px;
    }

    .auto-complete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff; 
        border-bottom: 1px solid #d4d4d4; 
    }
  
    .auto-complete-items div:hover {
        background-color: #e9e9e9; 
    }
    
    .auto-complete-active {
        background-color: #0078d4 !important; 
        color: #ffffff; 
    }
  }

  @media screen and (-ms-high-contrast: active) {
    .auto-complete { 
     .auto-complete-active {
       -ms-high-contrast-adjust:none;
        }
    }
 }
 @media screen and (-ms-high-contrast: black-on-white) {
    .auto-complete { 
        .auto-complete-active {
            background-color: black !important;
            color:white; 
        }
    }
  }
  @media screen and (-ms-high-contrast: white-on-black) {
    .auto-complete { 
        .auto-complete-active {
            background-color: blue !important;
            color: white;             
        }
    }
  }
