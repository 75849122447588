.alertList{
    margin: 15px 0;
}

.hidden-alert
  {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; /* added line */
    width: 1px;
  }

  @media screen and (-ms-high-contrast: black-on-white) {
    .ms-MessageBar{
      border: 1px solid;
    .ms-MessageBar-content{
      .ms-MessageBar-text,.ms-MessageBar-icon,.ms-MessageBar-dismissSingleLine{
        color:black;
        i{color: black} 
      }
               
                color:black; 
        }
      }
  }
  @media screen and (-ms-high-contrast: white-on-black) {
    .ms-MessageBar{
      border: 1px solid;
    .ms-MessageBar-content{
      .ms-MessageBar-text,.ms-MessageBar-icon,.ms-MessageBar-dismissSingleLine{
        color:black; 
        i{color: black} 
      }             
        color:black; 
      }
    }
  }