@import '../../../../resources/styles/variables/references';

.order-line-items {
    .header-text{
      margin-bottom: 10px;      
    }
    .order-lineitems-collapse-section{      
      display: flex;
      .order-lineitems-collapse{     
        margin-left: auto;     
        text-align: right;
        padding-right: 14px;
        margin-bottom: 10px;
        i{
          font-weight: bold;
        }  
        
        .line-items-toggle-icon{
          font-size: 100%;
          font-weight: bold;
        }
      }
    }   
    
    .card{
      background-color: $ms-color-card-background;     
      padding: 7px 10px 3px 5px;
    }
    .order-type{
      margin: 0px 0px 10px 0px;
    }

    .lineitem-card{
      margin-bottom: 10px;
      margin-right: 4px;
  
      .line-item-header{       
        display: flex;

        .line-item-part{
          margin-left: 15px;
        }
        .line-item-qty{
          margin-left: 15px;
        }
        .line-item-remove{
          margin-left: auto;
        }
      }
      .remove-button{
        padding-right: 3px;
      }     
    }     
    .card-body {
      background-color: inherit;
      padding: 0px; 
    }
    .card-header {
      padding: 0px; 
    }         
    
    .labelHeader {
      font-size: 14px;
      color: #626262;
    }
    .labelValue {
      font-size: 16px;
      color: $ms-color-black;
    } 

    .lineitem-info-msg{
      font-size: 14px !important;
      margin-bottom: 22px;
      font-weight: lighter;
    }
    .add-line-item-button{
      float: right;
      font-size: 18px;  
      margin-top: 20px;
      margin-bottom: 20px;
      color: $ms-color-active-button;
      .iconButtonAddition{
          font-size: 20px;
          margin: 3px 0px 10px 7px;
      }
      .ms-Button-icon {
        font-size: 25px !important;
        margin-left: 10px;
        color: $ms-color-active-button
      }
    }
    .shipment-type-error{
      font-size: 12px;
      color: $ms-color-redDark
    }
    
}

.order-line-items-info{
    .header-text{
      margin-bottom: 10px; 
      width:98%;
    }
    .order-lineitems-collapse-section{      
      display: flex;
      .order-lineitems-collapse{     
        margin-left: auto;     
        text-align: right;
        padding-right: 4px;
        margin-bottom: 10px;
        i{
          font-weight: bold;
        }    
        .line-items-toggle-icon{
          font-size: 100%;
          font-weight: bold;
        } 
      }
    }  
    .order-type-option{
      margin-top: 0px;     
    } 
    .labelHeader {
      font-size: 14px;
      color: #626262;
    }
    .labelValue {
      font-size: 16px;
      color: $ms-color-black;
    } 
   
    
  .line-item-info {
    font-family: $ms-font-family-fallbacks;
    border: 1px solid $ms-color-neutralTertiaryAlt;
    background-color: $ms-color-card-background;  
    border-width: 1px;
    margin-top: 10px;        
    margin-bottom: 10px;  
    
    .accordion-header-icon{
      padding-right: 5px !important;
    }

    .line-item-header{
      display: flex;
      padding: 10px;  
      .line-item-part{
        margin-left: 15px;
      }
      .line-item-qty{
        margin-left: 15px;
      }     
    }
    .remove-button{
      padding-right: 3px;
    } 
    
    .line-item-body{
      background-color: white;
      border-left: 1px solid $ms-color-neutralTertiaryAlt;
      border-right: 1px solid $ms-color-neutralTertiaryAlt;
    }     
    .labelHeader {
            font-size: 14px;
            font-weight: lighter;
            color: #626266;
      }
    .labelValue {
        font-size: 16px;
        font-weight: normal;
      }
  }
}