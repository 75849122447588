@charset "UTF-8";
/*
  Your use of the content in the files referenced here is subject to the terms of the license at http://aka.ms/fabric-assets-license
*/
@keyframes ms-slideRightIn10 {
  from {
    transform: translate3d(-10px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideRightIn20 {
  from {
    transform: translate3d(-20px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideRightIn40 {
  from {
    transform: translate3d(-40px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideRightIn400 {
  from {
    transform: translate3d(-400px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideRightOut40 {
  from {
    transform: translate3d(0px, 0px, 0px); }
  to {
    transform: translate3d(40px, 0px, 0px); } }

@keyframes ms-slideRightOut400 {
  from {
    transform: translate3d(0, 0px, 0px); }
  to {
    transform: translate3d(400px, 0px, 0px); } }

@keyframes ms-slideLeftIn10 {
  from {
    transform: translate3d(10px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideLeftIn20 {
  from {
    transform: translate3d(20px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideLeftIn40 {
  from {
    transform: translate3d(40px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideLeftIn400 {
  from {
    transform: translate3d(400px, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideLeftOut40 {
  from {
    transform: translate3d(0, 0px, 0px); }
  to {
    transform: translate3d(-40px, 0px, 0px); } }

@keyframes ms-slideLeftOut400 {
  from {
    transform: translate3d(0, 0px, 0px); }
  to {
    transform: translate3d(-400px, 0px, 0px); } }

@keyframes ms-slideUpIn10 {
  from {
    transform: translate3d(0px, 10px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideUpIn20 {
  from {
    transform: translate3d(0px, 20px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideDownIn10 {
  from {
    transform: translate3d(0px, -10px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideDownIn20 {
  from {
    transform: translate3d(0px, -20px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ms-slideUpOut10 {
  from {
    transform: translate3d(0px, 0, 0px); }
  to {
    transform: translate3d(0px, -10px, 0px); } }

@keyframes ms-slideUpOut20 {
  from {
    transform: translate3d(0px, 0, 0px); }
  to {
    transform: translate3d(0px, -20px, 0px); } }

@keyframes ms-slideDownOut10 {
  from {
    transform: translate3d(0px, 0, 0px); }
  to {
    transform: translate3d(0px, 10px, 0px); } }

@keyframes ms-slideDownOut20 {
  from {
    transform: translate3d(0px, 0, 0px); }
  to {
    transform: translate3d(0px, 20px, 0px); } }

@keyframes ms-scaleUp100 {
  from {
    transform: scale3d(0.98, 0.98, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

@keyframes ms-scaleUp103 {
  from {
    transform: scale3d(1, 1, 1); }
  to {
    transform: scale3d(1.03, 1.03, 1); } }

@keyframes ms-scaleDown98 {
  from {
    transform: scale3d(1, 1, 1); }
  to {
    transform: scale3d(0.98, 0.98, 1); } }

@keyframes ms-scaleDown100 {
  from {
    transform: scale3d(1.03, 1.03, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

@keyframes ms-fadeIn {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  to {
    opacity: 1; } }

@keyframes ms-fadeOut {
  from {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  to {
    opacity: 0; } }

@keyframes ms-rotate90 {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(90deg); } }

@keyframes ms-rotateN90 {
  from {
    transform: rotateZ(90deg); }
  to {
    transform: rotateZ(0deg); } }

[dir='ltr'] .ms-slideRightIn10 {
  animation-name: ms-fadeIn, ms-slideRightIn10;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightIn10 {
      animation: none; } }

[dir='rtl'] .ms-slideRightIn10 {
  animation-name: ms-fadeIn, ms-slideLeftIn10;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightIn10 {
      animation: none; } }

[dir='ltr'] .ms-slideRightIn20 {
  animation-name: ms-fadeIn, ms-slideRightIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightIn20 {
      animation: none; } }

[dir='rtl'] .ms-slideRightIn20 {
  animation-name: ms-fadeIn, ms-slideLeftIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightIn20 {
      animation: none; } }

[dir='ltr'] .ms-slideRightIn40 {
  animation-name: ms-fadeIn, ms-slideRightIn40;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightIn40 {
      animation: none; } }

[dir='rtl'] .ms-slideRightIn40 {
  animation-name: ms-fadeIn, ms-slideLeftIn40;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightIn40 {
      animation: none; } }

[dir='ltr'] .ms-slideRightIn400 {
  animation-name: ms-fadeIn, ms-slideRightIn400;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightIn400 {
      animation: none; } }

[dir='rtl'] .ms-slideRightIn400 {
  animation-name: ms-fadeIn, ms-slideLeftIn400;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightIn400 {
      animation: none; } }

[dir='ltr'] .ms-slideRightOut40 {
  animation-name: ms-fadeOut, ms-slideRightOut40;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightOut40 {
      animation: none; } }

[dir='rtl'] .ms-slideRightOut40 {
  animation-name: ms-fadeOut, ms-slideLeftOut40;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightOut40 {
      animation: none; } }

[dir='ltr'] .ms-slideRightOut400 {
  animation-name: ms-fadeOut, ms-slideRightOut400;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideRightOut400 {
      animation: none; } }

[dir='rtl'] .ms-slideRightOut400 {
  animation-name: ms-fadeOut, ms-slideLeftOut400;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideRightOut400 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftIn10 {
  animation-name: ms-fadeIn, ms-slideLeftIn10;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftIn10 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftIn10 {
  animation-name: ms-fadeIn, ms-slideRightIn10;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftIn10 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftIn20 {
  animation-name: ms-fadeIn, ms-slideLeftIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftIn20 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftIn20 {
  animation-name: ms-fadeIn, ms-slideRightIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftIn20 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftIn40 {
  animation-name: ms-fadeIn, ms-slideLeftIn40;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftIn40 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftIn40 {
  animation-name: ms-fadeIn, ms-slideRightIn40;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftIn40 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftIn400 {
  animation-name: ms-fadeIn, ms-slideLeftIn400;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftIn400 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftIn400 {
  animation-name: ms-fadeIn, ms-slideRightIn400;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftIn400 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftOut40 {
  animation-name: ms-fadeOut, ms-slideLeftOut40;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftOut40 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftOut40 {
  animation-name: ms-fadeOut, ms-slideRightOut40;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftOut40 {
      animation: none; } }

[dir='ltr'] .ms-slideLeftOut400 {
  animation-name: ms-fadeOut, ms-slideLeftOut400;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-slideLeftOut400 {
      animation: none; } }

[dir='rtl'] .ms-slideLeftOut400 {
  animation-name: ms-fadeOut, ms-slideRightOut400;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-slideLeftOut400 {
      animation: none; } }

.ms-slideUpIn10 {
  animation-name: ms-fadeIn, ms-slideUpIn10;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideUpIn10 {
      animation: none; } }

.ms-slideUpIn20 {
  animation-name: ms-fadeIn, ms-slideUpIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideUpIn20 {
      animation: none; } }

.ms-slideDownIn10 {
  animation-name: ms-fadeIn, ms-slideDownIn10;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideDownIn10 {
      animation: none; } }

.ms-slideDownIn20 {
  animation-name: ms-fadeIn, ms-slideDownIn20;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideDownIn20 {
      animation: none; } }

.ms-slideUpOut10 {
  animation-name: ms-fadeOut, ms-slideUpOut10;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideUpOut10 {
      animation: none; } }

.ms-slideUpOut20 {
  animation-name: ms-fadeOut, ms-slideUpOut20;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideUpOut20 {
      animation: none; } }

.ms-slideDownOut10 {
  animation-name: ms-fadeOut, ms-slideDownOut10;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideDownOut10 {
      animation: none; } }

.ms-slideDownOut20 {
  animation-name: ms-fadeOut, ms-slideDownOut20;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-slideDownOut20 {
      animation: none; } }

.ms-scaleUpIn100 {
  animation-name: ms-fadeIn, ms-scaleUp100;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-scaleUpIn100 {
      animation: none; } }

.ms-scaleUpOut103 {
  animation-name: ms-fadeOut, ms-scaleUp103;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-scaleUpOut103 {
      animation: none; } }

.ms-scaleDownOut98 {
  animation-name: ms-fadeOut, ms-scaleDown98;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-scaleDownOut98 {
      animation: none; } }

.ms-scaleDownIn100 {
  animation-name: ms-fadeIn, ms-scaleDown100;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-scaleDownIn100 {
      animation: none; } }

.ms-fadeIn100 {
  animation-name: ms-fadeIn;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeIn100 {
      animation: none; } }

.ms-fadeIn200 {
  animation-name: ms-fadeIn;
  animation-duration: 0.267s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeIn200 {
      animation: none; } }

.ms-fadeIn400 {
  animation-name: ms-fadeIn;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeIn400 {
      animation: none; } }

.ms-fadeIn500 {
  animation-name: ms-fadeIn;
  animation-duration: 0.467s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeIn500 {
      animation: none; } }

.ms-fadeOut100 {
  animation-name: ms-fadeOut;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeOut100 {
      animation: none; } }

.ms-fadeOut200 {
  animation-name: ms-fadeOut;
  animation-duration: 0.167s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeOut200 {
      animation: none; } }

.ms-fadeOut400 {
  animation-name: ms-fadeOut;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeOut400 {
      animation: none; } }

.ms-fadeOut500 {
  animation-name: ms-fadeOut;
  animation-duration: 0.467s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    .ms-fadeOut500 {
      animation: none; } }

.ms-expandCollapse100 {
  transition: height 0.1s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  @media (prefers-reduced-motion: reduce) {
    .ms-expandCollapse100 {
      transition: none; } }

.ms-expandCollapse200 {
  transition: height 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  @media (prefers-reduced-motion: reduce) {
    .ms-expandCollapse200 {
      transition: none; } }

.ms-expandCollapse400 {
  transition: height 0.367s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  @media (prefers-reduced-motion: reduce) {
    .ms-expandCollapse400 {
      transition: none; } }

.ms-delay100 {
  animation-delay: 0.167s; }

.ms-delay200 {
  animation-delay: 0.267s; }

[dir='ltr'] .ms-rotate90deg {
  animation-name: ms-rotate90;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-rotate90deg {
      animation: none; } }

[dir='rtl'] .ms-rotate90deg {
  animation-name: ms-rotateN90;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-rotate90deg {
      animation: none; } }

[dir='ltr'] .ms-rotateN90deg {
  animation-name: ms-rotateN90;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='ltr'] .ms-rotateN90deg {
      animation: none; } }

[dir='rtl'] .ms-rotateN90deg {
  animation-name: ms-rotate90;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
  animation-fill-mode: both; }
  @media (prefers-reduced-motion: reduce) {
    [dir='rtl'] .ms-rotateN90deg {
      animation: none; } }

.ms-BrandIcon--access.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_16x1.png); }

.ms-BrandIcon--access.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_48x1.png); }

.ms-BrandIcon--access.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_96x1.png); }

.ms-BrandIcon--excel.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_16x1.png); }

.ms-BrandIcon--excel.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_48x1.png); }

.ms-BrandIcon--excel.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_96x1.png); }

.ms-BrandIcon--infopath.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_16x1.png); }

.ms-BrandIcon--infopath.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_48x1.png); }

.ms-BrandIcon--infopath.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_96x1.png); }

.ms-BrandIcon--office.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_16x1.png); }

.ms-BrandIcon--office.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_48x1.png); }

.ms-BrandIcon--office.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_96x1.png); }

.ms-BrandIcon--onedrive.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_16x1.png); }

.ms-BrandIcon--onedrive.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_48x1.png); }

.ms-BrandIcon--onedrive.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_96x1.png); }

.ms-BrandIcon--onenote.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_16x1.png); }

.ms-BrandIcon--onenote.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_48x1.png); }

.ms-BrandIcon--onenote.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_96x1.png); }

.ms-BrandIcon--outlook.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_16x1.png); }

.ms-BrandIcon--outlook.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_48x1.png); }

.ms-BrandIcon--outlook.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_96x1.png); }

.ms-BrandIcon--powerpoint.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_16x1.png); }

.ms-BrandIcon--powerpoint.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_48x1.png); }

.ms-BrandIcon--powerpoint.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_96x1.png); }

.ms-BrandIcon--project.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_16x1.png); }

.ms-BrandIcon--project.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_48x1.png); }

.ms-BrandIcon--project.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_96x1.png); }

.ms-BrandIcon--sharepoint.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_16x1.png); }

.ms-BrandIcon--sharepoint.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_48x1.png); }

.ms-BrandIcon--sharepoint.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_96x1.png); }

.ms-BrandIcon--visio.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_16x1.png); }

.ms-BrandIcon--visio.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_48x1.png); }

.ms-BrandIcon--visio.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_96x1.png); }

.ms-BrandIcon--word.ms-BrandIcon--icon16 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_16x1.png); }

.ms-BrandIcon--word.ms-BrandIcon--icon48 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_48x1.png); }

.ms-BrandIcon--word.ms-BrandIcon--icon96 {
  background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_96x1.png); }

.ms-BrandIcon--accdb.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/accdb.png); }

.ms-BrandIcon--accdb.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/accdb.png); }

.ms-BrandIcon--accdb.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/accdb.png); }

.ms-BrandIcon--archive.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/archive.png); }

.ms-BrandIcon--archive.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/archive.png); }

.ms-BrandIcon--archive.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/archive.png); }

.ms-BrandIcon--audio.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/audio.png); }

.ms-BrandIcon--audio.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/audio.png); }

.ms-BrandIcon--audio.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/audio.png); }

.ms-BrandIcon--code.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/code.png); }

.ms-BrandIcon--code.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/code.png); }

.ms-BrandIcon--code.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/code.png); }

.ms-BrandIcon--csv.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/csv.png); }

.ms-BrandIcon--csv.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/csv.png); }

.ms-BrandIcon--csv.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/csv.png); }

.ms-BrandIcon--docset.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/docset.png); }

.ms-BrandIcon--docset.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/docset.png); }

.ms-BrandIcon--docset.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/docset.png); }

.ms-BrandIcon--docx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/docx.png); }

.ms-BrandIcon--docx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/docx.png); }

.ms-BrandIcon--docx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/docx.png); }

.ms-BrandIcon--dotx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/dotx.png); }

.ms-BrandIcon--dotx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/dotx.png); }

.ms-BrandIcon--dotx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/dotx.png); }

.ms-BrandIcon--email.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/email.png); }

.ms-BrandIcon--email.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/email.png); }

.ms-BrandIcon--email.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/email.png); }

.ms-BrandIcon--exe.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/exe.png); }

.ms-BrandIcon--exe.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/exe.png); }

.ms-BrandIcon--exe.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/exe.png); }

.ms-BrandIcon--folder.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/folder.png); }

.ms-BrandIcon--folder.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/folder.png); }

.ms-BrandIcon--folder.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/folder.png); }

.ms-BrandIcon--font.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/font.png); }

.ms-BrandIcon--font.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/font.png); }

.ms-BrandIcon--font.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/font.png); }

.ms-BrandIcon--genericfile.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/genericfile.png); }

.ms-BrandIcon--genericfile.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/genericfile.png); }

.ms-BrandIcon--genericfile.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/genericfile.png); }

.ms-BrandIcon--html.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/html.png); }

.ms-BrandIcon--html.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/html.png); }

.ms-BrandIcon--html.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/html.png); }

.ms-BrandIcon--link.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/link.png); }

.ms-BrandIcon--link.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/link.png); }

.ms-BrandIcon--link.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/link.png); }

.ms-BrandIcon--listitem.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/listitem.png); }

.ms-BrandIcon--listitem.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/listitem.png); }

.ms-BrandIcon--listitem.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/listitem.png); }

.ms-BrandIcon--model.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/model.png); }

.ms-BrandIcon--model.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/model.png); }

.ms-BrandIcon--model.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/model.png); }

.ms-BrandIcon--mpp.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/mpp.png); }

.ms-BrandIcon--mpp.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/mpp.png); }

.ms-BrandIcon--mpp.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/mpp.png); }

.ms-BrandIcon--mpt.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/mpt.png); }

.ms-BrandIcon--mpt.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/mpt.png); }

.ms-BrandIcon--mpt.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/mpt.png); }

.ms-BrandIcon--odp.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/odp.png); }

.ms-BrandIcon--odp.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/odp.png); }

.ms-BrandIcon--odp.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/odp.png); }

.ms-BrandIcon--ods.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/ods.png); }

.ms-BrandIcon--ods.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/ods.png); }

.ms-BrandIcon--ods.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/ods.png); }

.ms-BrandIcon--odt.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/odt.png); }

.ms-BrandIcon--odt.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/odt.png); }

.ms-BrandIcon--odt.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/odt.png); }

.ms-BrandIcon--one.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/one.png); }

.ms-BrandIcon--one.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/one.png); }

.ms-BrandIcon--one.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/one.png); }

.ms-BrandIcon--onetoc.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/onetoc.png); }

.ms-BrandIcon--onetoc.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/onetoc.png); }

.ms-BrandIcon--onetoc.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/onetoc.png); }

.ms-BrandIcon--pdf.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/pdf.png); }

.ms-BrandIcon--pdf.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/pdf.png); }

.ms-BrandIcon--pdf.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/pdf.png); }

.ms-BrandIcon--photo.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/photo.png); }

.ms-BrandIcon--photo.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/photo.png); }

.ms-BrandIcon--photo.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/photo.png); }

.ms-BrandIcon--potx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/potx.png); }

.ms-BrandIcon--potx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/potx.png); }

.ms-BrandIcon--potx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/potx.png); }

.ms-BrandIcon--ppsx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/ppsx.png); }

.ms-BrandIcon--ppsx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/ppsx.png); }

.ms-BrandIcon--ppsx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/ppsx.png); }

.ms-BrandIcon--pptx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/pptx.png); }

.ms-BrandIcon--pptx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/pptx.png); }

.ms-BrandIcon--pptx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/pptx.png); }

.ms-BrandIcon--pub.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/pub.png); }

.ms-BrandIcon--pub.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/pub.png); }

.ms-BrandIcon--pub.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/pub.png); }

.ms-BrandIcon--rtf.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/rtf.png); }

.ms-BrandIcon--rtf.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/rtf.png); }

.ms-BrandIcon--rtf.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/rtf.png); }

.ms-BrandIcon--sharedfolder.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/sharedfolder.png); }

.ms-BrandIcon--sharedfolder.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/sharedfolder.png); }

.ms-BrandIcon--sharedfolder.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/sharedfolder.png); }

.ms-BrandIcon--spo.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/spo.png); }

.ms-BrandIcon--spo.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/spo.png); }

.ms-BrandIcon--spo.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/spo.png); }

.ms-BrandIcon--sysfile.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/sysfile.png); }

.ms-BrandIcon--sysfile.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/sysfile.png); }

.ms-BrandIcon--sysfile.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/sysfile.png); }

.ms-BrandIcon--txt.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/txt.png); }

.ms-BrandIcon--txt.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/txt.png); }

.ms-BrandIcon--txt.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/txt.png); }

.ms-BrandIcon--vector.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/vector.png); }

.ms-BrandIcon--vector.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/vector.png); }

.ms-BrandIcon--vector.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/vector.png); }

.ms-BrandIcon--video.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/video.png); }

.ms-BrandIcon--video.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/video.png); }

.ms-BrandIcon--video.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/video.png); }

.ms-BrandIcon--vsdx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/vsdx.png); }

.ms-BrandIcon--vsdx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/vsdx.png); }

.ms-BrandIcon--vsdx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/vsdx.png); }

.ms-BrandIcon--vssx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/vssx.png); }

.ms-BrandIcon--vssx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/vssx.png); }

.ms-BrandIcon--vssx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/vssx.png); }

.ms-BrandIcon--vstx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/vstx.png); }

.ms-BrandIcon--vstx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/vstx.png); }

.ms-BrandIcon--vstx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/vstx.png); }

.ms-BrandIcon--xlsx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/xlsx.png); }

.ms-BrandIcon--xlsx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/xlsx.png); }

.ms-BrandIcon--xlsx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/xlsx.png); }

.ms-BrandIcon--xltx.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/xltx.png); }

.ms-BrandIcon--xltx.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/xltx.png); }

.ms-BrandIcon--xltx.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/xltx.png); }

.ms-BrandIcon--xml.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/xml.png); }

.ms-BrandIcon--xml.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/xml.png); }

.ms-BrandIcon--xml.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/xml.png); }

.ms-BrandIcon--xsn.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/xsn.png); }

.ms-BrandIcon--xsn.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/xsn.png); }

.ms-BrandIcon--xsn.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/xsn.png); }

.ms-BrandIcon--zip.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/zip.png); }

.ms-BrandIcon--zip.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/zip.png); }

.ms-BrandIcon--zip.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/zip.png); }

.ms-BrandIcon--icon16 {
  background-size: 100% 100%;
  width: 16px;
  height: 16px; }

.ms-BrandIcon--icon48 {
  background-size: 100% 100%;
  width: 48px;
  height: 48px; }

.ms-BrandIcon--icon96 {
  background-size: 100% 100%;
  width: 96px;
  height: 96px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .ms-BrandIcon--access.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_16x1_5.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_48x1_5.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_96x1_5.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_16x1_5.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_48x1_5.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_96x1_5.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_16x1_5.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_48x1_5.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_96x1_5.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_16x1_5.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_48x1_5.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_96x1_5.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_16x1_5.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_48x1_5.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_96x1_5.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_16x1_5.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_48x1_5.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_96x1_5.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_16x1_5.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_48x1_5.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_96x1_5.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_16x1_5.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_48x1_5.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_96x1_5.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_16x1_5.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_48x1_5.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_96x1_5.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_16x1_5.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_48x1_5.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_96x1_5.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_16x1_5.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_48x1_5.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_96x1_5.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_16x1_5.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_48x1_5.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_96x1_5.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/accdb.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/archive.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/audio.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/code.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/csv.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/docset.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/docx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/dotx.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/email.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/exe.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/folder.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/font.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/genericfile.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/html.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/link.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/listitem.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/model.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/mpp.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/mpt.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/odp.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/ods.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/odt.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/one.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/onetoc.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/pdf.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/photo.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/potx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/ppsx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/pptx.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/pub.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/rtf.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/sharedfolder.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/spo.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/sysfile.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/txt.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/vector.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/video.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/vsdx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/vssx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/vstx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/xlsx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/xltx.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/xml.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/xsn.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/zip.png); } }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
  .ms-BrandIcon--access.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_16x2.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_48x2.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_96x2.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_16x2.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_48x2.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_96x2.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_16x2.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_48x2.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_96x2.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_16x2.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_48x2.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_96x2.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_16x2.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_48x2.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_96x2.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_16x2.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_48x2.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_96x2.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_16x2.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_48x2.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_96x2.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_16x2.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_48x2.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_96x2.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_16x2.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_48x2.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_96x2.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_16x2.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_48x2.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_96x2.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_16x2.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_48x2.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_96x2.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_16x2.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_48x2.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_96x2.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/accdb.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/archive.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/audio.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/code.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/csv.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/docset.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/docx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/dotx.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/email.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/exe.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/folder.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/font.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/genericfile.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/html.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/link.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/listitem.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/model.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/mpp.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/mpt.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/odp.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/ods.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/odt.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/one.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/onetoc.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/pdf.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/photo.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/potx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/ppsx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/pptx.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/pub.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/rtf.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/sharedfolder.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/spo.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/sysfile.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/txt.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/vector.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/video.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/vsdx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/vssx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/vstx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/xlsx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/xltx.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/xml.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/xsn.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/zip.png); } }

@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min-resolution: 288dpi) {
  .ms-BrandIcon--access.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_16x3.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_48x3.png); }
  .ms-BrandIcon--access.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/access_96x3.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_16x3.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_48x3.png); }
  .ms-BrandIcon--excel.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/excel_96x3.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_16x3.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_48x3.png); }
  .ms-BrandIcon--infopath.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/infopath_96x3.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_16x3.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_48x3.png); }
  .ms-BrandIcon--office.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/office_96x3.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_16x3.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_48x3.png); }
  .ms-BrandIcon--onedrive.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onedrive_96x3.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_16x3.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_48x3.png); }
  .ms-BrandIcon--onenote.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/onenote_96x3.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_16x3.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_48x3.png); }
  .ms-BrandIcon--outlook.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/outlook_96x3.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_16x3.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_48x3.png); }
  .ms-BrandIcon--powerpoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/powerpoint_96x3.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_16x3.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_48x3.png); }
  .ms-BrandIcon--project.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/project_96x3.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_16x3.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_48x3.png); }
  .ms-BrandIcon--sharepoint.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/sharepoint_96x3.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_16x3.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_48x3.png); }
  .ms-BrandIcon--visio.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_96x3.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon16 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_16x3.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon48 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_48x3.png); }
  .ms-BrandIcon--word.ms-BrandIcon--icon96 {
    background-image: url(https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/word_96x3.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/accdb.png); }
  .ms-BrandIcon--accdb.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/accdb.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/archive.png); }
  .ms-BrandIcon--archive.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/archive.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/audio.png); }
  .ms-BrandIcon--audio.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/audio.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/code.png); }
  .ms-BrandIcon--code.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/code.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/csv.png); }
  .ms-BrandIcon--csv.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/csv.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/docset.png); }
  .ms-BrandIcon--docset.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/docset.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/docx.png); }
  .ms-BrandIcon--docx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/docx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/dotx.png); }
  .ms-BrandIcon--dotx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/dotx.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/email.png); }
  .ms-BrandIcon--email.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/email.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/exe.png); }
  .ms-BrandIcon--exe.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/exe.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/folder.png); }
  .ms-BrandIcon--folder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/folder.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/font.png); }
  .ms-BrandIcon--font.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/font.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/genericfile.png); }
  .ms-BrandIcon--genericfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/genericfile.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/html.png); }
  .ms-BrandIcon--html.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/html.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/link.png); }
  .ms-BrandIcon--link.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/link.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/listitem.png); }
  .ms-BrandIcon--listitem.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/listitem.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/model.png); }
  .ms-BrandIcon--model.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/model.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/mpp.png); }
  .ms-BrandIcon--mpp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/mpp.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/mpt.png); }
  .ms-BrandIcon--mpt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/mpt.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/odp.png); }
  .ms-BrandIcon--odp.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/odp.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/ods.png); }
  .ms-BrandIcon--ods.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/ods.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/odt.png); }
  .ms-BrandIcon--odt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/odt.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/one.png); }
  .ms-BrandIcon--one.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/one.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/onetoc.png); }
  .ms-BrandIcon--onetoc.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/onetoc.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/pdf.png); }
  .ms-BrandIcon--pdf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/pdf.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/photo.png); }
  .ms-BrandIcon--photo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/photo.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/potx.png); }
  .ms-BrandIcon--potx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/potx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/ppsx.png); }
  .ms-BrandIcon--ppsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/ppsx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/pptx.png); }
  .ms-BrandIcon--pptx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/pptx.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/pub.png); }
  .ms-BrandIcon--pub.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/pub.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/rtf.png); }
  .ms-BrandIcon--rtf.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/rtf.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/sharedfolder.png); }
  .ms-BrandIcon--sharedfolder.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/sharedfolder.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/spo.png); }
  .ms-BrandIcon--spo.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/spo.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/sysfile.png); }
  .ms-BrandIcon--sysfile.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/sysfile.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/txt.png); }
  .ms-BrandIcon--txt.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/txt.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/vector.png); }
  .ms-BrandIcon--vector.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/vector.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/video.png); }
  .ms-BrandIcon--video.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/video.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/vsdx.png); }
  .ms-BrandIcon--vsdx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/vsdx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/vssx.png); }
  .ms-BrandIcon--vssx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/vssx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/vstx.png); }
  .ms-BrandIcon--vstx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/vstx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/xlsx.png); }
  .ms-BrandIcon--xlsx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/xlsx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/xltx.png); }
  .ms-BrandIcon--xltx.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/xltx.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/xml.png); }
  .ms-BrandIcon--xml.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/xml.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/xsn.png); }
  .ms-BrandIcon--xsn.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/xsn.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/zip.png); }
  .ms-BrandIcon--zip.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/zip.png); } }

.ms-BrandIcon--onepkg.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/one.png); }

.ms-BrandIcon--onepkg.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/one.png); }

.ms-BrandIcon--onepkg.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/one.png); }

.ms-BrandIcon--xls.ms-BrandIcon--icon16 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16/xlsx.png); }

.ms-BrandIcon--xls.ms-BrandIcon--icon48 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48/xlsx.png); }

.ms-BrandIcon--xls.ms-BrandIcon--icon96 {
  background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96/xlsx.png); }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/one.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_1.5x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_1.5x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_1.5x/xlsx.png); } }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/one.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_2x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_2x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_2x/xlsx.png); } }

@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min-resolution: 288dpi) {
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/one.png); }
  .ms-BrandIcon--onepkg.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/one.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon16 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/16_3x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon48 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/48_3x/xlsx.png); }
  .ms-BrandIcon--xls.ms-BrandIcon--icon96 {
    background-image: url(https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/96_3x/xlsx.png); } }

.ms-bgColor-themeDark,
.ms-bgColor-themeDark--hover:hover {
  background-color: #005a9e; }

.ms-bgColor-themeDarkAlt,
.ms-bgColor-themeDarkAlt--hover:hover {
  background-color: #106ebe; }

.ms-bgColor-themeDarker,
.ms-bgColor-themeDarker--hover:hover {
  background-color: #004578; }

.ms-bgColor-themePrimary,
.ms-bgColor-themePrimary--hover:hover {
  background-color: #0078d4; }

.ms-bgColor-themeSecondary,
.ms-bgColor-themeSecondary--hover:hover {
  background-color: #2b88d8; }

.ms-bgColor-themeTertiary,
.ms-bgColor-themeTertiary--hover:hover {
  background-color: #71afe5; }

.ms-bgColor-themeLight,
.ms-bgColor-themeLight--hover:hover {
  background-color: #c7e0f4; }

.ms-bgColor-themeLighter,
.ms-bgColor-themeLighter--hover:hover {
  background-color: #deecf9; }

.ms-bgColor-themeLighterAlt,
.ms-bgColor-themeLighterAlt--hover:hover {
  background-color: #eff6fc; }

.ms-bgColor-black,
.ms-bgColor-black--hover:hover {
  background-color: #000000; }

.ms-bgColor-neutralDark,
.ms-bgColor-neutralDark--hover:hover {
  background-color: #212121; }

.ms-bgColor-neutralPrimary,
.ms-bgColor-neutralPrimary--hover:hover {
  background-color: #333333; }

.ms-bgColor-neutralPrimaryAlt,
.ms-bgColor-neutralPrimaryAlt--hover:hover {
  background-color: #3C3C3C; }

.ms-bgColor-neutralSecondary,
.ms-bgColor-neutralSecondary--hover:hover {
  background-color: #666666; }

.ms-bgColor-neutralSecondaryAlt,
.ms-bgColor-neutralSecondaryAlt--hover:hover {
  background-color: #767676; }

.ms-bgColor-neutralTertiary,
.ms-bgColor-neutralTertiary--hover:hover {
  background-color: #a6a6a6; }

.ms-bgColor-neutralTertiaryAlt,
.ms-bgColor-neutralTertiaryAlt--hover:hover {
  background-color: #c8c8c8; }

.ms-bgColor-neutralQuaternary,
.ms-bgColor-neutralQuaternary--hover:hover {
  background-color: #d0d0d0; }

.ms-bgColor-neutralQuaternaryAlt,
.ms-bgColor-neutralQuaternaryAlt--hover:hover {
  background-color: #dadada; }

.ms-bgColor-neutralLight,
.ms-bgColor-neutralLight--hover:hover {
  background-color: #eaeaea; }

.ms-bgColor-neutralLighter,
.ms-bgColor-neutralLighter--hover:hover {
  background-color: #f4f4f4; }

.ms-bgColor-neutralLighterAlt,
.ms-bgColor-neutralLighterAlt--hover:hover {
  background-color: #f8f8f8; }

.ms-bgColor-white,
.ms-bgColor-white--hover:hover {
  background-color: #ffffff; }

.ms-bgColor-yellow,
.ms-bgColor-yellow--hover:hover {
  background-color: #ffb900; }

.ms-bgColor-yellowLight,
.ms-bgColor-yellowLight--hover:hover {
  background-color: #fff100; }

.ms-bgColor-orange,
.ms-bgColor-orange--hover:hover {
  background-color: #d83b01; }

.ms-bgColor-orangeLight,
.ms-bgColor-orangeLight--hover:hover {
  background-color: #ea4300; }

.ms-bgColor-orangeLighter,
.ms-bgColor-orangeLighter--hover:hover {
  background-color: #ff8c00; }

.ms-bgColor-redDark,
.ms-bgColor-redDark--hover:hover {
  background-color: #a80000; }

.ms-bgColor-red,
.ms-bgColor-red--hover:hover {
  background-color: #e81123; }

.ms-bgColor-magentaDark,
.ms-bgColor-magentaDark--hover:hover {
  background-color: #5c005c; }

.ms-bgColor-magenta,
.ms-bgColor-magenta--hover:hover {
  background-color: #b4009e; }

.ms-bgColor-magentaLight,
.ms-bgColor-magentaLight--hover:hover {
  background-color: #e3008c; }

.ms-bgColor-purpleDark,
.ms-bgColor-purpleDark--hover:hover {
  background-color: #32145a; }

.ms-bgColor-purple,
.ms-bgColor-purple--hover:hover {
  background-color: #5c2d91; }

.ms-bgColor-purpleLight,
.ms-bgColor-purpleLight--hover:hover {
  background-color: #b4a0ff; }

.ms-bgColor-blueDark,
.ms-bgColor-blueDark--hover:hover {
  background-color: #002050; }

.ms-bgColor-blueMid,
.ms-bgColor-blueMid--hover:hover {
  background-color: #00188f; }

.ms-bgColor-blue,
.ms-bgColor-blue--hover:hover {
  background-color: #0078d7; }

.ms-bgColor-blueLight,
.ms-bgColor-blueLight--hover:hover {
  background-color: #00bcf2; }

.ms-bgColor-tealDark,
.ms-bgColor-tealDark--hover:hover {
  background-color: #004b50; }

.ms-bgColor-teal,
.ms-bgColor-teal--hover:hover {
  background-color: #008272; }

.ms-bgColor-tealLight,
.ms-bgColor-tealLight--hover:hover {
  background-color: #00b294; }

.ms-bgColor-greenDark,
.ms-bgColor-greenDark--hover:hover {
  background-color: #004b1c; }

.ms-bgColor-green,
.ms-bgColor-green--hover:hover {
  background-color: #107c10; }

.ms-bgColor-greenLight,
.ms-bgColor-greenLight--hover:hover {
  background-color: #bad80a; }

.ms-bgColor-info,
.ms-bgColor-info--hover:hover {
  background-color: #f4f4f4; }

.ms-bgColor-success,
.ms-bgColor-success--hover:hover {
  background-color: #dff6dd; }

.ms-bgColor-severeWarning,
.ms-bgColor-severeWarning--hover:hover {
  background-color: #fed9cc; }

.ms-bgColor-warning,
.ms-bgColor-warning--hover:hover {
  background-color: #fff4ce; }

.ms-bgColor-error,
.ms-bgColor-error--hover:hover {
  background-color: #fde7e9; }

.ms-borderColor-themeDark,
.ms-borderColor-themeDark--hover:hover {
  border-color: #005a9e; }

.ms-borderColor-themeDarkAlt,
.ms-borderColor-themeDarkAlt--hover:hover {
  border-color: #106ebe; }

.ms-borderColor-themeDarker,
.ms-borderColor-themeDarker--hover:hover {
  border-color: #004578; }

.ms-borderColor-themePrimary,
.ms-borderColor-themePrimary--hover:hover {
  border-color: #0078d4; }

.ms-borderColor-themeSecondary,
.ms-borderColor-themeSecondary--hover:hover {
  border-color: #2b88d8; }

.ms-borderColor-themeTertiary,
.ms-borderColor-themeTertiary--hover:hover {
  border-color: #71afe5; }

.ms-borderColor-themeLight,
.ms-borderColor-themeLight--hover:hover {
  border-color: #c7e0f4; }

.ms-borderColor-themeLighter,
.ms-borderColor-themeLighter--hover:hover {
  border-color: #deecf9; }

.ms-borderColor-themeLighterAlt,
.ms-borderColor-themeLighterAlt--hover:hover {
  border-color: #eff6fc; }

.ms-borderColor-black,
.ms-borderColor-black--hover:hover {
  border-color: #000000; }

.ms-borderColor-neutralDark,
.ms-borderColor-neutralDark--hover:hover {
  border-color: #212121; }

.ms-borderColor-neutralPrimary,
.ms-borderColor-neutralPrimary--hover:hover {
  border-color: #333333; }

.ms-borderColor-neutralPrimaryAlt,
.ms-borderColor-neutralPrimaryAlt--hover:hover {
  border-color: #3C3C3C; }

.ms-borderColor-neutralSecondary,
.ms-borderColor-neutralSecondary--hover:hover {
  border-color: #666666; }

.ms-borderColor-neutralSecondaryAlt,
.ms-borderColor-neutralSecondaryAlt--hover:hover {
  border-color: #767676; }

.ms-borderColor-neutralTertiary,
.ms-borderColor-neutralTertiary--hover:hover {
  border-color: #a6a6a6; }

.ms-borderColor-neutralTertiaryAlt,
.ms-borderColor-neutralTertiaryAlt--hover:hover {
  border-color: #c8c8c8; }

.ms-borderColor-neutralQuaternary,
.ms-borderColor-neutralQuaternary--hover:hover {
  border-color: #d0d0d0; }

.ms-borderColor-neutralQuaternaryAlt,
.ms-borderColor-neutralQuaternaryAlt--hover:hover {
  border-color: #dadada; }

.ms-borderColor-neutralLight,
.ms-borderColor-neutralLight--hover:hover {
  border-color: #eaeaea; }

.ms-borderColor-neutralLighter,
.ms-borderColor-neutralLighter--hover:hover {
  border-color: #f4f4f4; }

.ms-borderColor-neutralLighterAlt,
.ms-borderColor-neutralLighterAlt--hover:hover {
  border-color: #f8f8f8; }

.ms-borderColor-white,
.ms-borderColor-white--hover:hover {
  border-color: #ffffff; }

.ms-borderColor-yellow,
.ms-borderColor-yellow--hover:hover {
  border-color: #ffb900; }

.ms-borderColor-yellowLight,
.ms-borderColor-yellowLight--hover:hover {
  border-color: #fff100; }

.ms-borderColor-orange,
.ms-borderColor-orange--hover:hover {
  border-color: #d83b01; }

.ms-borderColor-orangeLight,
.ms-borderColor-orangeLight--hover:hover {
  border-color: #ea4300; }

.ms-borderColor-orangeLighter,
.ms-borderColor-orangeLighter--hover:hover {
  border-color: #ff8c00; }

.ms-borderColor-redDark,
.ms-borderColor-redDark--hover:hover {
  border-color: #a80000; }

.ms-borderColor-red,
.ms-borderColor-red--hover:hover {
  border-color: #e81123; }

.ms-borderColor-magentaDark,
.ms-borderColor-magentaDark--hover:hover {
  border-color: #5c005c; }

.ms-borderColor-magenta,
.ms-borderColor-magenta--hover:hover {
  border-color: #b4009e; }

.ms-borderColor-magentaLight,
.ms-borderColor-magentaLight--hover:hover {
  border-color: #e3008c; }

.ms-borderColor-purpleDark,
.ms-borderColor-purpleDark--hover:hover {
  border-color: #32145a; }

.ms-borderColor-purple,
.ms-borderColor-purple--hover:hover {
  border-color: #5c2d91; }

.ms-borderColor-purpleLight,
.ms-borderColor-purpleLight--hover:hover {
  border-color: #b4a0ff; }

.ms-borderColor-blueDark,
.ms-borderColor-blueDark--hover:hover {
  border-color: #002050; }

.ms-borderColor-blueMid,
.ms-borderColor-blueMid--hover:hover {
  border-color: #00188f; }

.ms-borderColor-blue,
.ms-borderColor-blue--hover:hover {
  border-color: #0078d7; }

.ms-borderColor-blueLight,
.ms-borderColor-blueLight--hover:hover {
  border-color: #00bcf2; }

.ms-borderColor-tealDark,
.ms-borderColor-tealDark--hover:hover {
  border-color: #004b50; }

.ms-borderColor-teal,
.ms-borderColor-teal--hover:hover {
  border-color: #008272; }

.ms-borderColor-tealLight,
.ms-borderColor-tealLight--hover:hover {
  border-color: #00b294; }

.ms-borderColor-greenDark,
.ms-borderColor-greenDark--hover:hover {
  border-color: #004b1c; }

.ms-borderColor-green,
.ms-borderColor-green--hover:hover {
  border-color: #107c10; }

.ms-borderColor-greenLight,
.ms-borderColor-greenLight--hover:hover {
  border-color: #bad80a; }

@font-face {
  font-family: "Leelawadee UI Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Leelawadee UI Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Leelawadee UI Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Leelawadee UI Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Leelawadee UI Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Arabic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Arabic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Arabic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Arabic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Arabic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-arabic/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Cyrillic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Cyrillic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Cyrillic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Cyrillic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Cyrillic)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-cyrillic/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (East European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (East European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (East European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (East European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (East European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-easteuropean/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Greek)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Greek)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Greek)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Greek)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Greek)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-greek/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Hebrew)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Hebrew)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Hebrew)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Hebrew)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Hebrew)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-hebrew/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Vietnamese)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Vietnamese)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Vietnamese)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Vietnamese)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (Vietnamese)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-vietnamese/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (West European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (West European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (West European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (West European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI Web (West European)";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Selawik Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-light.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Selawik Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Selawik Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-regular.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Selawik Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-semibold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Selawik Web";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/selawik/selawik-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Leelawadee UI Web';
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semilight.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-semilight.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Leelawadee UI Web';
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-bold.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/fonts/leelawadeeui-thai/leelawadeeui-bold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

.ms-font-su {
  font-size: 42px;
  font-weight: 100; }

.ms-font-xxl {
  font-size: 28px;
  font-weight: 100; }

.ms-font-xl-plus {
  font-size: 24px;
  font-weight: 100; }

.ms-font-xl {
  font-size: 21px;
  font-weight: 100; }

.ms-font-l {
  font-size: 17px;
  font-weight: 300; }

.ms-font-m-plus {
  font-size: 15px;
  font-weight: 400; }

.ms-font-m {
  font-size: 14px;
  font-weight: 400; }

.ms-font-s-plus {
  font-size: 13px;
  font-weight: 400; }

.ms-font-s {
  font-size: 12px;
  font-weight: 400; }

.ms-font-xs {
  font-size: 11px;
  font-weight: 400; }

.ms-font-mi {
  font-size: 10px;
  font-weight: 600; }

.ms-fontWeight-light {
  font-weight: 100; }

.ms-fontWeight-semilight {
  font-weight: 300; }

.ms-fontWeight-regular {
  font-weight: 400; }

.ms-fontWeight-semibold {
  font-weight: 600; }

.ms-fontWeight-bold {
  font-weight: 700; }

.ms-fontSize-su {
  font-size: 42px; }

.ms-fontSize-xxl {
  font-size: 28px; }

.ms-fontSize-xlPlus {
  font-size: 24px; }

.ms-fontSize-xl {
  font-size: 21px; }

.ms-fontSize-l {
  font-size: 17px; }

.ms-fontSize-mPlus {
  font-size: 15px; }

.ms-fontSize-m {
  font-size: 14px; }

.ms-fontSize-sPlus {
  font-size: 13px; }

.ms-fontSize-s {
  font-size: 12px; }

.ms-fontSize-xs {
  font-size: 11px; }

.ms-fontSize-mi {
  font-size: 10px; }

.ms-fontColor-themeDarker,
.ms-fontColor-themeDarker--hover:hover {
  color: #004578; }

.ms-fontColor-themeDark,
.ms-fontColor-themeDark--hover:hover {
  color: #005a9e; }

.ms-fontColor-themeDarkAlt,
.ms-fontColor-themeDarkAlt--hover:hover {
  color: #106ebe; }

.ms-fontColor-themePrimary,
.ms-fontColor-themePrimary--hover:hover {
  color: #0078d4; }

.ms-fontColor-themeSecondary,
.ms-fontColor-themeSecondary--hover:hover {
  color: #2b88d8; }

.ms-fontColor-themeTertiary,
.ms-fontColor-themeTertiary--hover:hover {
  color: #71afe5; }

.ms-fontColor-themeLight,
.ms-fontColor-themeLight--hover:hover {
  color: #c7e0f4; }

.ms-fontColor-themeLighter,
.ms-fontColor-themeLighter--hover:hover {
  color: #deecf9; }

.ms-fontColor-themeLighterAlt,
.ms-fontColor-themeLighterAlt--hover:hover {
  color: #eff6fc; }

.ms-fontColor-black,
.ms-fontColor-black--hover:hover {
  color: #000000; }

.ms-fontColor-neutralDark,
.ms-fontColor-neutralDark--hover:hover {
  color: #212121; }

.ms-fontColor-neutralPrimary,
.ms-fontColor-neutralPrimary--hover:hover {
  color: #333333; }

.ms-fontColor-neutralPrimaryAlt,
.ms-fontColor-neutralPrimaryAlt--hover:hover {
  color: #3C3C3C; }

.ms-fontColor-neutralSecondary,
.ms-fontColor-neutralSecondary--hover:hover {
  color: #666666; }

.ms-fontColor-neutralSecondaryAlt,
.ms-fontColor-neutralSecondaryAlt--hover:hover {
  color: #767676; }

.ms-fontColor-neutralTertiary,
.ms-fontColor-neutralTertiary--hover:hover {
  color: #a6a6a6; }

.ms-fontColor-neutralTertiaryAlt,
.ms-fontColor-neutralTertiaryAlt--hover:hover {
  color: #c8c8c8; }

.ms-fontColor-neutralQuaternary,
.ms-fontColor-neutralQuaternary--hover:hover {
  color: #d0d0d0; }

.ms-fontColor-neutralQuaternaryAlt,
.ms-fontColor-neutralQuaternaryAlt--hover:hover {
  color: #dadada; }

.ms-fontColor-neutralLight,
.ms-fontColor-neutralLight--hover:hover {
  color: #eaeaea; }

.ms-fontColor-neutralLighter,
.ms-fontColor-neutralLighter--hover:hover {
  color: #f4f4f4; }

.ms-fontColor-neutralLighterAlt,
.ms-fontColor-neutralLighterAlt--hover:hover {
  color: #f8f8f8; }

.ms-fontColor-white,
.ms-fontColor-white--hover:hover {
  color: #ffffff; }

.ms-fontColor-yellow,
.ms-fontColor-yellow--hover:hover {
  color: #ffb900; }

.ms-fontColor-yellowLight,
.ms-fontColor-yellowLight--hover:hover {
  color: #fff100; }

.ms-fontColor-orange,
.ms-fontColor-orange--hover:hover {
  color: #d83b01; }

.ms-fontColor-orangeLight,
.ms-fontColor-orangeLight--hover:hover {
  color: #ea4300; }

.ms-fontColor-orangeLighter,
.ms-fontColor-orangeLighter--hover:hover {
  color: #ff8c00; }

.ms-fontColor-redDark,
.ms-fontColor-redDark--hover:hover {
  color: #a80000; }

.ms-fontColor-red,
.ms-fontColor-red--hover:hover {
  color: #e81123; }

.ms-fontColor-magentaDark,
.ms-fontColor-magentaDark--hover:hover {
  color: #5c005c; }

.ms-fontColor-magenta,
.ms-fontColor-magenta--hover:hover {
  color: #b4009e; }

.ms-fontColor-magentaLight,
.ms-fontColor-magentaLight--hover:hover {
  color: #e3008c; }

.ms-fontColor-purpleDark,
.ms-fontColor-purpleDark--hover:hover {
  color: #32145a; }

.ms-fontColor-purple,
.ms-fontColor-purple--hover:hover {
  color: #5c2d91; }

.ms-fontColor-purpleLight,
.ms-fontColor-purpleLight--hover:hover {
  color: #b4a0ff; }

.ms-fontColor-blueDark,
.ms-fontColor-blueDark--hover:hover {
  color: #002050; }

.ms-fontColor-blueMid,
.ms-fontColor-blueMid--hover:hover {
  color: #00188f; }

.ms-fontColor-blue,
.ms-fontColor-blue--hover:hover {
  color: #0078d7; }

.ms-fontColor-blueLight,
.ms-fontColor-blueLight--hover:hover {
  color: #00bcf2; }

.ms-fontColor-tealDark,
.ms-fontColor-tealDark--hover:hover {
  color: #004b50; }

.ms-fontColor-teal,
.ms-fontColor-teal--hover:hover {
  color: #008272; }

.ms-fontColor-tealLight,
.ms-fontColor-tealLight--hover:hover {
  color: #00b294; }

.ms-fontColor-greenDark,
.ms-fontColor-greenDark--hover:hover {
  color: #004b1c; }

.ms-fontColor-green,
.ms-fontColor-green--hover:hover {
  color: #107c10; }

.ms-fontColor-greenLight,
.ms-fontColor-greenLight--hover:hover {
  color: #bad80a; }

.ms-fontColor-info,
.ms-fontColor-info--hover:hover {
  color: #767676; }

.ms-fontColor-success,
.ms-fontColor-success--hover:hover {
  color: #107c10; }

.ms-fontColor-alert,
.ms-fontColor-alert--hover:hover {
  color: #d83b01; }

.ms-fontColor-warning,
.ms-fontColor-warning--hover:hover {
  color: #767676; }

.ms-fontColor-severeWarning,
.ms-fontColor-severeWarning--hover:hover {
  color: #d83b01; }

.ms-fontColor-error,
.ms-fontColor-error--hover:hover {
  color: #a80000; }

.ms-Grid {
  box-sizing: border-box;
  *zoom: 1;
  padding: 0 8px; }
  .ms-Grid::before, .ms-Grid::after {
    display: table;
    content: '';
    line-height: 0; }
  .ms-Grid::after {
    clear: both; }

.ms-Grid-row {
  margin: 0 -8px;
  box-sizing: border-box;
  *zoom: 1; }
  .ms-Grid-row::before, .ms-Grid-row::after {
    display: table;
    content: '';
    line-height: 0; }
  .ms-Grid-row::after {
    clear: both; }

.ms-Grid-col {
  position: relative;
  min-height: 1px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box; }
  [dir='ltr'] .ms-Grid-col {
    float: left; }
  [dir='rtl'] .ms-Grid-col {
    float: right; }
  .ms-Grid-col .ms-Grid {
    padding: 0; }

/*
  Your use of the content in the files referenced here are subject to the terms of the license at http://aka.ms/fabric-font-license
*/
@font-face {
  font-family: "FabricMDL2Icons";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/icons/fabricmdl2icons-2.68.woff2") format("woff2"), url("https://static2.sharepointonline.com/files/fabric/assets/icons/fabricmdl2icons-2.68.woff") format("woff"), url("https://static2.sharepointonline.com/files/fabric/assets/icons/fabricmdl2icons-2.68.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.ms-Icon {
  display: inline-block;
  font-family: "FabricMDL2Icons";
  font-style: normal;
  font-weight: normal;
  speak: none; }

.ms-Icon--circle {
  margin: 0 0.5em 0 0;
  position: relative;
  display: inline-block;
  font-size: 1rem;
  width: 1em;
  height: 1em;
  padding: 0;
  text-align: left; }
  [dir='rtl'] .ms-Icon--circle {
    margin: 0 0 0 0.5em; }
  .ms-Icon--circle::before, .ms-Icon--circle::after {
    line-height: 1;
    font-size: inherit; }
  .ms-Icon--circle::before {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    vertical-align: top;
    position: absolute; }
  .ms-Icon--circle::after {
    content: '\E000';
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(2);
    transform-origin: 50% 50%;
    z-index: 0; }

.ms-Icon--xs {
  font-size: 10px; }

.ms-Icon--s {
  font-size: 12px; }

.ms-Icon--m {
  font-size: 16px; }

.ms-Icon--l {
  font-size: 20px; }

.ms-Icon--12PointStar::before {
  content: "\F505"; }

.ms-Icon--6PointStar::before {
  content: "\F504"; }

.ms-Icon--AADLogo::before {
  content: "\ED68"; }

.ms-Icon--Accept::before {
  content: "\E8FB"; }

.ms-Icon--AccessLogo::before {
  content: "\ED69"; }

.ms-Icon--AccessLogoFill::before {
  content: "\F1DB"; }

.ms-Icon--AccountManagement::before {
  content: "\F55C"; }

.ms-Icon--Accounts::before {
  content: "\E910"; }

.ms-Icon--ActivateOrders::before {
  content: "\EFE0"; }

.ms-Icon--ActivityFeed::before {
  content: "\F056"; }

.ms-Icon--Add::before {
  content: "\E710"; }

.ms-Icon--AddBookmark::before {
  content: "\F5B7"; }

.ms-Icon--AddEvent::before {
  content: "\EEB5"; }

.ms-Icon--AddFavorite::before {
  content: "\F0C8"; }

.ms-Icon--AddFavoriteFill::before {
  content: "\F0C9"; }

.ms-Icon--AddFriend::before {
  content: "\E8FA"; }

.ms-Icon--AddGroup::before {
  content: "\EE3D"; }

.ms-Icon--AddNotes::before {
  content: "\EAE3"; }

.ms-Icon--AddOnlineMeeting::before {
  content: "\ED8E"; }

.ms-Icon--AddPhone::before {
  content: "\ED96"; }

.ms-Icon--AddTo::before {
  content: "\ECC8"; }

.ms-Icon--Admin::before {
  content: "\E7EF"; }

.ms-Icon--AdminALogo32::before {
  content: "\F4BA"; }

.ms-Icon--AdminALogoFill32::before {
  content: "\F4BB"; }

.ms-Icon--AdminALogoInverse32::before {
  content: "\ED6A"; }

.ms-Icon--AdminCLogoInverse32::before {
  content: "\ED6B"; }

.ms-Icon--AdminDLogoInverse32::before {
  content: "\ED6C"; }

.ms-Icon--AdminELogoInverse32::before {
  content: "\ED6D"; }

.ms-Icon--AdminLLogoInverse32::before {
  content: "\ED6E"; }

.ms-Icon--AdminMLogoInverse32::before {
  content: "\ED6F"; }

.ms-Icon--AdminOLogoInverse32::before {
  content: "\ED70"; }

.ms-Icon--AdminPLogoInverse32::before {
  content: "\ED71"; }

.ms-Icon--AdminSLogoInverse32::before {
  content: "\ED72"; }

.ms-Icon--AdminYLogoInverse32::before {
  content: "\ED73"; }

.ms-Icon--Airplane::before {
  content: "\E709"; }

.ms-Icon--AirplaneSolid::before {
  content: "\EB4C"; }

.ms-Icon--AirTickets::before {
  content: "\EF7A"; }

.ms-Icon--AlarmClock::before {
  content: "\E919"; }

.ms-Icon--Album::before {
  content: "\E7AB"; }

.ms-Icon--AlbumRemove::before {
  content: "\EC62"; }

.ms-Icon--AlertSolid::before {
  content: "\F331"; }

.ms-Icon--AlignCenter::before {
  content: "\E8E3"; }

.ms-Icon--AlignHorizontalCenter::before {
  content: "\F4F4"; }

.ms-Icon--AlignHorizontalLeft::before {
  content: "\F4F3"; }

.ms-Icon--AlignHorizontalRight::before {
  content: "\F4F5"; }

.ms-Icon--AlignJustify::before {
  content: "\F51E"; }

.ms-Icon--AlignLeft::before {
  content: "\E8E4"; }

.ms-Icon--AlignRight::before {
  content: "\E8E2"; }

.ms-Icon--AlignVerticalBottom::before {
  content: "\F4F8"; }

.ms-Icon--AlignVerticalCenter::before {
  content: "\F4F7"; }

.ms-Icon--AlignVerticalTop::before {
  content: "\F4F6"; }

[dir='ltr'] .ms-Icon--AllApps::before {
  content: "\E71D"; }

[dir='rtl'] .ms-Icon--AllApps::before {
  content: "\EA40"; }

[dir='ltr'] .ms-Icon--AllAppsMirrored::before {
  content: "\EA40"; }

[dir='rtl'] .ms-Icon--AllAppsMirrored::before {
  content: "\E71D"; }

.ms-Icon--AnalyticsLogo::before {
  content: "\F1DE"; }

.ms-Icon--AnalyticsQuery::before {
  content: "\F1DF"; }

.ms-Icon--AnalyticsReport::before {
  content: "\F1E1"; }

.ms-Icon--AnalyticsView::before {
  content: "\F5F1"; }

.ms-Icon--AnchorLock::before {
  content: "\F511"; }

.ms-Icon--Annotation::before {
  content: "\E924"; }

.ms-Icon--AppIconDefault::before {
  content: "\ECAA"; }

.ms-Icon--Archive::before {
  content: "\F03F"; }

.ms-Icon--AreaChart::before {
  content: "\E9D2"; }

.ms-Icon--ArrangeBringForward::before {
  content: "\F509"; }

.ms-Icon--ArrangeBringToFront::before {
  content: "\F506"; }

.ms-Icon--ArrangeByFrom::before {
  content: "\F678"; }

.ms-Icon--ArrangeSendBackward::before {
  content: "\F508"; }

.ms-Icon--ArrangeSendToBack::before {
  content: "\F507"; }

.ms-Icon--Arrivals::before {
  content: "\EB34"; }

[dir='ltr'] .ms-Icon--ArrowDownRight8::before {
  content: "\EED5"; }

[dir='rtl'] .ms-Icon--ArrowDownRight8::before {
  content: "\EEF0"; }

[dir='ltr'] .ms-Icon--ArrowDownRightMirrored8::before {
  content: "\EEF0"; }

[dir='rtl'] .ms-Icon--ArrowDownRightMirrored8::before {
  content: "\EED5"; }

.ms-Icon--ArrowTallDownLeft::before {
  content: "\F2BF"; }

.ms-Icon--ArrowTallDownRight::before {
  content: "\F2C0"; }

.ms-Icon--ArrowTallUpLeft::before {
  content: "\F2BD"; }

.ms-Icon--ArrowTallUpRight::before {
  content: "\F2BE"; }

.ms-Icon--ArrowUpRight::before {
  content: "\F069"; }

[dir='ltr'] .ms-Icon--ArrowUpRight8::before {
  content: "\EED4"; }

[dir='rtl'] .ms-Icon--ArrowUpRight8::before {
  content: "\EEEF"; }

[dir='ltr'] .ms-Icon--ArrowUpRightMirrored8::before {
  content: "\EEEF"; }

[dir='rtl'] .ms-Icon--ArrowUpRightMirrored8::before {
  content: "\EED4"; }

.ms-Icon--Articles::before {
  content: "\EAC1"; }

.ms-Icon--Ascending::before {
  content: "\EDC0"; }

.ms-Icon--AspectRatio::before {
  content: "\E799"; }

.ms-Icon--AssessmentGroup::before {
  content: "\F31A"; }

.ms-Icon--AssessmentGroupTemplate::before {
  content: "\F2B1"; }

.ms-Icon--AssetLibrary::before {
  content: "\EEB6"; }

.ms-Icon--Assign::before {
  content: "\E9D3"; }

.ms-Icon--Asterisk::before {
  content: "\EA38"; }

.ms-Icon--AsteriskSolid::before {
  content: "\F34D"; }

.ms-Icon--ATPLogo::before {
  content: "\EF85"; }

.ms-Icon--Attach::before {
  content: "\E723"; }

.ms-Icon--AustralianRules::before {
  content: "\EE70"; }

.ms-Icon--AutoEnhanceOff::before {
  content: "\E78E"; }

.ms-Icon--AutoEnhanceOn::before {
  content: "\E78D"; }

.ms-Icon--AutoFillTemplate::before {
  content: "\F313"; }

.ms-Icon--AutoHeight::before {
  content: "\F512"; }

.ms-Icon--AutoRacing::before {
  content: "\EB24"; }

.ms-Icon--AwayStatus::before {
  content: "\EE6A"; }

.ms-Icon--AzureAPIManagement::before {
  content: "\F37F"; }

.ms-Icon--AzureKeyVault::before {
  content: "\F3B4"; }

.ms-Icon--AzureLogo::before {
  content: "\EB6A"; }

.ms-Icon--AzureServiceEndpoint::before {
  content: "\F380"; }

.ms-Icon--Back::before {
  content: "\E72B"; }

.ms-Icon--BackgroundColor::before {
  content: "\F42B"; }

.ms-Icon--Backlog::before {
  content: "\F2AC"; }

.ms-Icon--BacklogBoard::before {
  content: "\F444"; }

.ms-Icon--BackToWindow::before {
  content: "\E73F"; }

.ms-Icon--Badge::before {
  content: "\EC1B"; }

.ms-Icon--Balloons::before {
  content: "\ED7E"; }

.ms-Icon--BankSolid::before {
  content: "\F34F"; }

.ms-Icon--BarChart4::before {
  content: "\EAE7"; }

.ms-Icon--BarChartHorizontal::before {
  content: "\E9EB"; }

.ms-Icon--BarChartVertical::before {
  content: "\E9EC"; }

.ms-Icon--Baseball::before {
  content: "\EB20"; }

.ms-Icon--BeerMug::before {
  content: "\F49E"; }

.ms-Icon--BIDashboard::before {
  content: "\F543"; }

.ms-Icon--BidiLtr::before {
  content: "\E9AA"; }

.ms-Icon--BidiRtl::before {
  content: "\E9AB"; }

.ms-Icon--BingLogo::before {
  content: "\EB6B"; }

.ms-Icon--BirthdayCake::before {
  content: "\EF8D"; }

.ms-Icon--BlockContact::before {
  content: "\E8F8"; }

.ms-Icon--Blocked::before {
  content: "\E733"; }

.ms-Icon--Blocked12::before {
  content: "\F62E"; }

.ms-Icon--Blocked2::before {
  content: "\ECE4"; }

.ms-Icon--BlockedSite::before {
  content: "\E72F"; }

.ms-Icon--BlockedSolid::before {
  content: "\F531"; }

.ms-Icon--BlowingSnow::before {
  content: "\E9C9"; }

.ms-Icon--Blur::before {
  content: "\F28E"; }

.ms-Icon--Boards::before {
  content: "\EF68"; }

.ms-Icon--Bold::before {
  content: "\E8DD"; }

.ms-Icon--BookingsLogo::before {
  content: "\EDC7"; }

[dir='ltr'] .ms-Icon--Bookmarks::before {
  content: "\E8A4"; }

[dir='rtl'] .ms-Icon--Bookmarks::before {
  content: "\EA41"; }

[dir='ltr'] .ms-Icon--BookmarksMirrored::before {
  content: "\EA41"; }

[dir='rtl'] .ms-Icon--BookmarksMirrored::before {
  content: "\E8A4"; }

.ms-Icon--BorderDash::before {
  content: "\F50A"; }

.ms-Icon--BorderDot::before {
  content: "\F50B"; }

.ms-Icon--BoxAdditionSolid::before {
  content: "\F2D4"; }

.ms-Icon--BoxCheckmarkSolid::before {
  content: "\F2D7"; }

.ms-Icon--BoxMultiplySolid::before {
  content: "\F2D5"; }

.ms-Icon--BoxPlaySolid::before {
  content: "\F2D6"; }

.ms-Icon--BoxSubtractSolid::before {
  content: "\F2D3"; }

.ms-Icon--BranchCommit::before {
  content: "\F293"; }

.ms-Icon--BranchCompare::before {
  content: "\F294"; }

.ms-Icon--BranchFork::before {
  content: "\F173"; }

.ms-Icon--BranchFork2::before {
  content: "\F291"; }

.ms-Icon--BranchLocked::before {
  content: "\F292"; }

.ms-Icon--BranchMerge::before {
  content: "\F295"; }

.ms-Icon--BranchPullRequest::before {
  content: "\F296"; }

.ms-Icon--BranchSearch::before {
  content: "\F297"; }

.ms-Icon--BranchShelveset::before {
  content: "\F298"; }

.ms-Icon--Breadcrumb::before {
  content: "\EF8C"; }

.ms-Icon--Breakfast::before {
  content: "\F49C"; }

.ms-Icon--Brightness::before {
  content: "\E706"; }

.ms-Icon--Broom::before {
  content: "\EA99"; }

.ms-Icon--BrowserScreenShot::before {
  content: "\EBED"; }

.ms-Icon--BrowserTab::before {
  content: "\F5D7"; }

.ms-Icon--BrowserTabScreenshot::before {
  content: "\F5D8"; }

.ms-Icon--Brunch::before {
  content: "\F49D"; }

.ms-Icon--BucketColor::before {
  content: "\F1B6"; }

.ms-Icon--BucketColorFill::before {
  content: "\F1B7"; }

.ms-Icon--BufferTimeAfter::before {
  content: "\F0D0"; }

.ms-Icon--BufferTimeBefore::before {
  content: "\F0CF"; }

.ms-Icon--BufferTimeBoth::before {
  content: "\F0D1"; }

.ms-Icon--Bug::before {
  content: "\EBE8"; }

.ms-Icon--BugSolid::before {
  content: "\F335"; }

.ms-Icon--Build::before {
  content: "\F28F"; }

.ms-Icon--BuildIssue::before {
  content: "\F319"; }

.ms-Icon--BuildQueue::before {
  content: "\F24F"; }

.ms-Icon--BuildQueueNew::before {
  content: "\F250"; }

.ms-Icon--BulkUpload::before {
  content: "\F548"; }

[dir='ltr'] .ms-Icon--BulletedList2::before {
  content: "\F2C7"; }

[dir='rtl'] .ms-Icon--BulletedList2::before {
  content: "\F2C8"; }

[dir='ltr'] .ms-Icon--BulletedList2Mirrored::before {
  content: "\F2C8"; }

[dir='rtl'] .ms-Icon--BulletedList2Mirrored::before {
  content: "\F2C7"; }

[dir='ltr'] .ms-Icon--BulletedList::before {
  content: "\E8FD"; }

[dir='rtl'] .ms-Icon--BulletedList::before {
  content: "\EA42"; }

[dir='ltr'] .ms-Icon--BulletedListMirrored::before {
  content: "\EA42"; }

[dir='rtl'] .ms-Icon--BulletedListMirrored::before {
  content: "\E8FD"; }

.ms-Icon--Bullseye::before {
  content: "\F272"; }

.ms-Icon--Bus::before {
  content: "\E806"; }

.ms-Icon--BusinessCenterLogo::before {
  content: "\F4B2"; }

.ms-Icon--BusinessHoursSign::before {
  content: "\F310"; }

.ms-Icon--BusSolid::before {
  content: "\EB47"; }

.ms-Icon--Cafe::before {
  content: "\EC32"; }

.ms-Icon--Cake::before {
  content: "\ECA4"; }

.ms-Icon--Calculator::before {
  content: "\E8EF"; }

.ms-Icon--CalculatorAddition::before {
  content: "\E948"; }

.ms-Icon--CalculatorEqualTo::before {
  content: "\E94E"; }

.ms-Icon--CalculatorMultiply::before {
  content: "\E947"; }

.ms-Icon--CalculatorNotEqualTo::before {
  content: "\F2D2"; }

.ms-Icon--CalculatorSubtract::before {
  content: "\E949"; }

.ms-Icon--CalendarAgenda::before {
  content: "\EE9A"; }

.ms-Icon--CalendarDay::before {
  content: "\E8BF"; }

[dir='ltr'] .ms-Icon--Calendar::before {
  content: "\E787"; }

[dir='rtl'] .ms-Icon--Calendar::before {
  content: "\ED28"; }

[dir='ltr'] .ms-Icon--CalendarMirrored::before {
  content: "\ED28"; }

[dir='rtl'] .ms-Icon--CalendarMirrored::before {
  content: "\E787"; }

.ms-Icon--CalendarReply::before {
  content: "\E8F5"; }

[dir='ltr'] .ms-Icon--CalendarSettings::before {
  content: "\F558"; }

[dir='rtl'] .ms-Icon--CalendarSettings::before {
  content: "\F559"; }

[dir='ltr'] .ms-Icon--CalendarSettingsMirrored::before {
  content: "\F559"; }

[dir='rtl'] .ms-Icon--CalendarSettingsMirrored::before {
  content: "\F558"; }

.ms-Icon--CalendarWeek::before {
  content: "\E8C0"; }

.ms-Icon--CalendarWorkWeek::before {
  content: "\EF51"; }

.ms-Icon--CaloriesAdd::before {
  content: "\F172"; }

.ms-Icon--Camera::before {
  content: "\E722"; }

.ms-Icon--Cancel::before {
  content: "\E711"; }

.ms-Icon--CannedChat::before {
  content: "\F0F2"; }

.ms-Icon--Car::before {
  content: "\E804"; }

.ms-Icon--CaretBottomLeftCenter8::before {
  content: "\F365"; }

.ms-Icon--CaretBottomLeftSolid8::before {
  content: "\F121"; }

.ms-Icon--CaretBottomRightCenter8::before {
  content: "\F364"; }

.ms-Icon--CaretBottomRightSolid8::before {
  content: "\F122"; }

.ms-Icon--CaretDown8::before {
  content: "\EDD8"; }

.ms-Icon--CaretDownSolid8::before {
  content: "\EDDC"; }

[dir='ltr'] .ms-Icon--CaretHollow::before {
  content: "\E817"; }

[dir='rtl'] .ms-Icon--CaretHollow::before {
  content: "\EA45"; }

[dir='ltr'] .ms-Icon--CaretHollowMirrored::before {
  content: "\EA45"; }

[dir='rtl'] .ms-Icon--CaretHollowMirrored::before {
  content: "\E817"; }

.ms-Icon--CaretLeft8::before {
  content: "\EDD5"; }

.ms-Icon--CaretLeftSolid8::before {
  content: "\EDD9"; }

.ms-Icon--CaretRight::before {
  content: "\F06B"; }

.ms-Icon--CaretRight8::before {
  content: "\EDD6"; }

.ms-Icon--CaretRightSolid8::before {
  content: "\EDDA"; }

.ms-Icon--CaretSolid16::before {
  content: "\EE62"; }

.ms-Icon--CaretSolidDown::before {
  content: "\F08E"; }

.ms-Icon--CaretSolidLeft::before {
  content: "\F08D"; }

[dir='ltr'] .ms-Icon--CaretSolid::before {
  content: "\E818"; }

[dir='rtl'] .ms-Icon--CaretSolid::before {
  content: "\EA46"; }

[dir='ltr'] .ms-Icon--CaretSolidMirrored::before {
  content: "\EA46"; }

[dir='rtl'] .ms-Icon--CaretSolidMirrored::before {
  content: "\E818"; }

.ms-Icon--CaretSolidRight::before {
  content: "\F08F"; }

.ms-Icon--CaretSolidUp::before {
  content: "\F090"; }

.ms-Icon--CaretTopLeftCenter8::before {
  content: "\F367"; }

.ms-Icon--CaretTopLeftSolid8::before {
  content: "\EF54"; }

.ms-Icon--CaretTopRightCenter8::before {
  content: "\F366"; }

.ms-Icon--CaretTopRightSolid8::before {
  content: "\EF55"; }

.ms-Icon--CaretUp8::before {
  content: "\EDD7"; }

.ms-Icon--CaretUpSolid8::before {
  content: "\EDDB"; }

.ms-Icon--Cat::before {
  content: "\ED7F"; }

.ms-Icon--CellPhone::before {
  content: "\E8EA"; }

.ms-Icon--Certificate::before {
  content: "\EB95"; }

.ms-Icon--CertifiedDatabase::before {
  content: "\F5BB"; }

.ms-Icon--Chart::before {
  content: "\E999"; }

.ms-Icon--ChartSeries::before {
  content: "\F513"; }

.ms-Icon--ChartXAngle::before {
  content: "\F514"; }

.ms-Icon--ChartYAngle::before {
  content: "\F515"; }

.ms-Icon--Chat::before {
  content: "\E901"; }

.ms-Icon--ChatInviteFriend::before {
  content: "\ECFE"; }

.ms-Icon--ChatSolid::before {
  content: "\F344"; }

.ms-Icon--Checkbox::before {
  content: "\E739"; }

.ms-Icon--CheckboxComposite::before {
  content: "\E73A"; }

.ms-Icon--CheckboxCompositeReversed::before {
  content: "\E73D"; }

.ms-Icon--CheckboxIndeterminate::before {
  content: "\E73C"; }

.ms-Icon--CheckedOutByOther12::before {
  content: "\F630"; }

.ms-Icon--CheckedOutByYou12::before {
  content: "\F631"; }

.ms-Icon--CheckList::before {
  content: "\E9D5"; }

.ms-Icon--CheckMark::before {
  content: "\E73E"; }

.ms-Icon--ChevronDown::before {
  content: "\E70D"; }

.ms-Icon--ChevronDownEnd6::before {
  content: "\F36F"; }

.ms-Icon--ChevronDownMed::before {
  content: "\E972"; }

.ms-Icon--ChevronDownSmall::before {
  content: "\E96E"; }

.ms-Icon--ChevronFold10::before {
  content: "\F36A"; }

.ms-Icon--ChevronLeft::before {
  content: "\E76B"; }

.ms-Icon--ChevronLeftEnd6::before {
  content: "\F371"; }

.ms-Icon--ChevronLeftMed::before {
  content: "\E973"; }

.ms-Icon--ChevronLeftSmall::before {
  content: "\E96F"; }

.ms-Icon--ChevronRight::before {
  content: "\E76C"; }

.ms-Icon--ChevronRightEnd6::before {
  content: "\F372"; }

.ms-Icon--ChevronRightMed::before {
  content: "\E974"; }

.ms-Icon--ChevronRightSmall::before {
  content: "\E970"; }

.ms-Icon--ChevronUnfold10::before {
  content: "\F369"; }

.ms-Icon--ChevronUp::before {
  content: "\E70E"; }

.ms-Icon--ChevronUpEnd6::before {
  content: "\F370"; }

.ms-Icon--ChevronUpMed::before {
  content: "\E971"; }

.ms-Icon--ChevronUpSmall::before {
  content: "\E96D"; }

.ms-Icon--Chopsticks::before {
  content: "\F4A2"; }

[dir='ltr'] .ms-Icon--ChromeBack::before {
  content: "\E830"; }

[dir='rtl'] .ms-Icon--ChromeBack::before {
  content: "\EA47"; }

[dir='ltr'] .ms-Icon--ChromeBackMirrored::before {
  content: "\EA47"; }

[dir='rtl'] .ms-Icon--ChromeBackMirrored::before {
  content: "\E830"; }

.ms-Icon--ChromeClose::before {
  content: "\E8BB"; }

.ms-Icon--ChromeMinimize::before {
  content: "\E921"; }

.ms-Icon--CircleAddition::before {
  content: "\F2E3"; }

.ms-Icon--CircleAdditionSolid::before {
  content: "\F2E4"; }

.ms-Icon--CircleFill::before {
  content: "\EA3B"; }

.ms-Icon--CircleHalfFull::before {
  content: "\ED9E"; }

.ms-Icon--CirclePause::before {
  content: "\F2D9"; }

.ms-Icon--CirclePauseSolid::before {
  content: "\F2D8"; }

.ms-Icon--CirclePlus::before {
  content: "\EAEE"; }

.ms-Icon--CircleRing::before {
  content: "\EA3A"; }

.ms-Icon--CircleShapeSolid::before {
  content: "\F63C"; }

.ms-Icon--CircleStop::before {
  content: "\F2DC"; }

.ms-Icon--CircleStopSolid::before {
  content: "\F2DB"; }

.ms-Icon--CityNext::before {
  content: "\EC06"; }

.ms-Icon--ClassNotebookLogo16::before {
  content: "\F488"; }

.ms-Icon--ClassNotebookLogo32::before {
  content: "\F486"; }

.ms-Icon--ClassNotebookLogoFill16::before {
  content: "\F489"; }

.ms-Icon--ClassNotebookLogoFill32::before {
  content: "\F487"; }

.ms-Icon--ClassNotebookLogoInverse::before {
  content: "\EDC8"; }

.ms-Icon--ClassNotebookLogoInverse16::before {
  content: "\F48B"; }

.ms-Icon--ClassNotebookLogoInverse32::before {
  content: "\F48A"; }

.ms-Icon--ClassroomLogo::before {
  content: "\EF75"; }

.ms-Icon--Clear::before {
  content: "\E894"; }

.ms-Icon--ClearFilter::before {
  content: "\EF8F"; }

.ms-Icon--ClearFormatting::before {
  content: "\EDDD"; }

.ms-Icon--ClearNight::before {
  content: "\E9C2"; }

.ms-Icon--ClipboardSolid::before {
  content: "\F5DC"; }

.ms-Icon--Clock::before {
  content: "\E917"; }

.ms-Icon--CloneToDesktop::before {
  content: "\F28C"; }

.ms-Icon--ClosedCaption::before {
  content: "\EF84"; }

[dir='ltr'] .ms-Icon--ClosePane::before {
  content: "\E89F"; }

[dir='rtl'] .ms-Icon--ClosePane::before {
  content: "\EA49"; }

[dir='ltr'] .ms-Icon--ClosePaneMirrored::before {
  content: "\EA49"; }

[dir='rtl'] .ms-Icon--ClosePaneMirrored::before {
  content: "\E89F"; }

.ms-Icon--Cloud::before {
  content: "\E753"; }

.ms-Icon--CloudAdd::before {
  content: "\ECA9"; }

.ms-Icon--CloudDownload::before {
  content: "\EBD3"; }

.ms-Icon--CloudUpload::before {
  content: "\EC8E"; }

.ms-Icon--CloudWeather::before {
  content: "\E9BE"; }

.ms-Icon--Cloudy::before {
  content: "\E9BF"; }

.ms-Icon--Cocktails::before {
  content: "\EA9D"; }

.ms-Icon--Code::before {
  content: "\E943"; }

.ms-Icon--CodeEdit::before {
  content: "\F544"; }

.ms-Icon--Coffee::before {
  content: "\EAEF"; }

.ms-Icon--CoffeeScript::before {
  content: "\F2FA"; }

.ms-Icon--CollapseContent::before {
  content: "\F165"; }

.ms-Icon--CollapseContentSingle::before {
  content: "\F166"; }

.ms-Icon--CollapseMenu::before {
  content: "\EF66"; }

.ms-Icon--CollegeFootball::before {
  content: "\EB26"; }

.ms-Icon--CollegeHoops::before {
  content: "\EB25"; }

.ms-Icon--Color::before {
  content: "\E790"; }

.ms-Icon--ColorSolid::before {
  content: "\F354"; }

.ms-Icon--ColumnLeftTwoThirds::before {
  content: "\F1D6"; }

.ms-Icon--ColumnLeftTwoThirdsEdit::before {
  content: "\F324"; }

.ms-Icon--ColumnOptions::before {
  content: "\F317"; }

.ms-Icon--ColumnRightTwoThirds::before {
  content: "\F1D7"; }

.ms-Icon--ColumnRightTwoThirdsEdit::before {
  content: "\F325"; }

.ms-Icon--Combine::before {
  content: "\EDBB"; }

.ms-Icon--Combobox::before {
  content: "\F516"; }

.ms-Icon--CommandPrompt::before {
  content: "\E756"; }

.ms-Icon--Comment::before {
  content: "\E90A"; }

.ms-Icon--CommentAdd::before {
  content: "\F2B3"; }

.ms-Icon--CommentNext::before {
  content: "\F2B4"; }

.ms-Icon--CommentPrevious::before {
  content: "\F2B5"; }

.ms-Icon--CommentUrgent::before {
  content: "\F307"; }

.ms-Icon--Commitments::before {
  content: "\EC4D"; }

.ms-Icon--Communications::before {
  content: "\E95A"; }

[dir='ltr'] .ms-Icon--CompanyDirectory::before {
  content: "\EF0D"; }

[dir='rtl'] .ms-Icon--CompanyDirectory::before {
  content: "\EF2B"; }

[dir='ltr'] .ms-Icon--CompanyDirectoryMirrored::before {
  content: "\EF2B"; }

[dir='rtl'] .ms-Icon--CompanyDirectoryMirrored::before {
  content: "\EF0D"; }

.ms-Icon--CompassNW::before {
  content: "\E942"; }

.ms-Icon--Completed::before {
  content: "\E930"; }

.ms-Icon--CompletedSolid::before {
  content: "\EC61"; }

.ms-Icon--ConfigurationSolid::before {
  content: "\F334"; }

.ms-Icon--ConnectContacts::before {
  content: "\EFD4"; }

.ms-Icon--ConstructionCone::before {
  content: "\E98F"; }

.ms-Icon--ConstructionConeSolid::before {
  content: "\F339"; }

.ms-Icon--Contact::before {
  content: "\E77B"; }

.ms-Icon--ContactCard::before {
  content: "\EEBD"; }

[dir='ltr'] .ms-Icon--ContactCardSettings::before {
  content: "\F556"; }

[dir='rtl'] .ms-Icon--ContactCardSettings::before {
  content: "\F557"; }

[dir='ltr'] .ms-Icon--ContactCardSettingsMirrored::before {
  content: "\F557"; }

[dir='rtl'] .ms-Icon--ContactCardSettingsMirrored::before {
  content: "\F556"; }

.ms-Icon--ContactInfo::before {
  content: "\E779"; }

.ms-Icon--ContactLink::before {
  content: "\F25F"; }

.ms-Icon--ContextMenu::before {
  content: "\F37C"; }

.ms-Icon--Contrast::before {
  content: "\E7A1"; }

.ms-Icon--Copy::before {
  content: "\E8C8"; }

.ms-Icon--Cotton::before {
  content: "\EAF3"; }

.ms-Icon--CPlusPlus::before {
  content: "\F2F4"; }

.ms-Icon--CPlusPlusLanguage::before {
  content: "\F2F3"; }

.ms-Icon--CreateMailRule::before {
  content: "\F67A"; }

.ms-Icon--Cricket::before {
  content: "\EB1E"; }

.ms-Icon--CRMReport::before {
  content: "\EFFE"; }

.ms-Icon--Crop::before {
  content: "\E7A8"; }

.ms-Icon--Crown::before {
  content: "\ED01"; }

.ms-Icon--CrownSolid::before {
  content: "\F336"; }

.ms-Icon--CSharp::before {
  content: "\F2F0"; }

.ms-Icon--CSharpLanguage::before {
  content: "\F2EF"; }

.ms-Icon--CSS::before {
  content: "\EBEF"; }

[dir='ltr'] .ms-Icon--CustomList::before {
  content: "\EEBE"; }

[dir='rtl'] .ms-Icon--CustomList::before {
  content: "\EEBF"; }

[dir='ltr'] .ms-Icon--CustomListMirrored::before {
  content: "\EEBF"; }

[dir='rtl'] .ms-Icon--CustomListMirrored::before {
  content: "\EEBE"; }

.ms-Icon--Cut::before {
  content: "\E8C6"; }

.ms-Icon--Cycling::before {
  content: "\EAC7"; }

.ms-Icon--DashboardAdd::before {
  content: "\F52D"; }

.ms-Icon--Database::before {
  content: "\EFC7"; }

.ms-Icon--DataConnectionLibrary::before {
  content: "\EEB7"; }

.ms-Icon--DateTime2::before {
  content: "\EA17"; }

[dir='ltr'] .ms-Icon--DateTime::before {
  content: "\EC92"; }

[dir='rtl'] .ms-Icon--DateTime::before {
  content: "\EE93"; }

[dir='ltr'] .ms-Icon--DateTimeMirrored::before {
  content: "\EE93"; }

[dir='rtl'] .ms-Icon--DateTimeMirrored::before {
  content: "\EC92"; }

.ms-Icon--DeactivateOrders::before {
  content: "\EFE1"; }

.ms-Icon--DecisionSolid::before {
  content: "\F350"; }

.ms-Icon--DeclineCall::before {
  content: "\F405"; }

.ms-Icon--DecreaseIndentLegacy::before {
  content: "\E290"; }

.ms-Icon--DefaultRatio::before {
  content: "\F529"; }

.ms-Icon--DefectSolid::before {
  content: "\F449"; }

.ms-Icon--Delete::before {
  content: "\E74D"; }

.ms-Icon--DeleteColumns::before {
  content: "\F64E"; }

[dir='ltr'] .ms-Icon--DeleteRows::before {
  content: "\F64F"; }

[dir='rtl'] .ms-Icon--DeleteRows::before {
  content: "\F650"; }

[dir='ltr'] .ms-Icon--DeleteRowsMirrored::before {
  content: "\F650"; }

[dir='rtl'] .ms-Icon--DeleteRowsMirrored::before {
  content: "\F64F"; }

.ms-Icon--DeleteTable::before {
  content: "\F651"; }

.ms-Icon--DeliveryTruck::before {
  content: "\EBF4"; }

.ms-Icon--DelveAnalytics::before {
  content: "\EEEE"; }

.ms-Icon--DelveAnalyticsLogo::before {
  content: "\EDCA"; }

.ms-Icon--DelveLogo::before {
  content: "\F280"; }

.ms-Icon--DelveLogoFill::before {
  content: "\F281"; }

.ms-Icon--DelveLogoInverse::before {
  content: "\ED76"; }

.ms-Icon--Deploy::before {
  content: "\F29D"; }

.ms-Icon--Descending::before {
  content: "\EDC1"; }

.ms-Icon--Design::before {
  content: "\EB3C"; }

.ms-Icon--DesktopScreenshot::before {
  content: "\F5D9"; }

.ms-Icon--DeveloperTools::before {
  content: "\EC7A"; }

.ms-Icon--Devices3::before {
  content: "\EA6C"; }

.ms-Icon--Devices4::before {
  content: "\EB66"; }

.ms-Icon--Diagnostic::before {
  content: "\E9D9"; }

.ms-Icon--Dialpad::before {
  content: "\E75F"; }

.ms-Icon--DiamondSolid::before {
  content: "\F34C"; }

.ms-Icon--Dictionary::before {
  content: "\E82D"; }

.ms-Icon--DictionaryRemove::before {
  content: "\F69A"; }

.ms-Icon--DietPlanNotebook::before {
  content: "\EAC8"; }

.ms-Icon--DiffInline::before {
  content: "\F309"; }

.ms-Icon--DiffSideBySide::before {
  content: "\F30A"; }

.ms-Icon--DisableUpdates::before {
  content: "\E8D8"; }

.ms-Icon--Dislike::before {
  content: "\E8E0"; }

.ms-Icon--DislikeSolid::before {
  content: "\F3C0"; }

[dir='ltr'] .ms-Icon--DockLeft::before {
  content: "\E90C"; }

[dir='rtl'] .ms-Icon--DockLeft::before {
  content: "\EA4C"; }

[dir='ltr'] .ms-Icon--DockLeftMirrored::before {
  content: "\EA4C"; }

[dir='rtl'] .ms-Icon--DockLeftMirrored::before {
  content: "\E90C"; }

.ms-Icon--DockRight::before {
  content: "\E90D"; }

.ms-Icon--DocLibrary::before {
  content: "\EEB8"; }

.ms-Icon--DocsLogoInverse::before {
  content: "\EDCB"; }

.ms-Icon--Document::before {
  content: "\E8A5"; }

.ms-Icon--DocumentApproval::before {
  content: "\F28B"; }

.ms-Icon--Documentation::before {
  content: "\EC17"; }

.ms-Icon--DocumentManagement::before {
  content: "\EFFC"; }

.ms-Icon--DocumentReply::before {
  content: "\EF57"; }

.ms-Icon--DocumentSearch::before {
  content: "\EF6C"; }

.ms-Icon--DocumentSet::before {
  content: "\EED6"; }

.ms-Icon--DOM::before {
  content: "\EC8D"; }

.ms-Icon--DonutChart::before {
  content: "\F368"; }

.ms-Icon--Door::before {
  content: "\EB75"; }

.ms-Icon--DoubleBookmark::before {
  content: "\EB8F"; }

.ms-Icon--DoubleChevronDown::before {
  content: "\EE04"; }

.ms-Icon--DoubleChevronDown12::before {
  content: "\EE97"; }

.ms-Icon--DoubleChevronDown8::before {
  content: "\F36B"; }

.ms-Icon--DoubleChevronLeft::before {
  content: "\EDBE"; }

.ms-Icon--DoubleChevronLeft12::before {
  content: "\EE98"; }

.ms-Icon--DoubleChevronLeft8::before {
  content: "\F36D"; }

[dir='ltr'] .ms-Icon--DoubleChevronLeftMed::before {
  content: "\E991"; }

[dir='rtl'] .ms-Icon--DoubleChevronLeftMed::before {
  content: "\EA4D"; }

[dir='ltr'] .ms-Icon--DoubleChevronLeftMedMirrored::before {
  content: "\EA4D"; }

[dir='rtl'] .ms-Icon--DoubleChevronLeftMedMirrored::before {
  content: "\E991"; }

.ms-Icon--DoubleChevronRight::before {
  content: "\EDBF"; }

.ms-Icon--DoubleChevronRight12::before {
  content: "\EE99"; }

.ms-Icon--DoubleChevronRight8::before {
  content: "\F36E"; }

.ms-Icon--DoubleChevronUp::before {
  content: "\EDBD"; }

.ms-Icon--DoubleChevronUp12::before {
  content: "\EE96"; }

.ms-Icon--DoubleChevronUp8::before {
  content: "\F36C"; }

.ms-Icon--DoubleColumn::before {
  content: "\F1D4"; }

.ms-Icon--DoubleColumnEdit::before {
  content: "\F322"; }

.ms-Icon--Down::before {
  content: "\E74B"; }

.ms-Icon--Download::before {
  content: "\E896"; }

.ms-Icon--DownloadDocument::before {
  content: "\F549"; }

.ms-Icon--DragObject::before {
  content: "\F553"; }

.ms-Icon--DrillDown::before {
  content: "\F532"; }

.ms-Icon--DrillDownSolid::before {
  content: "\F533"; }

.ms-Icon--DrillExpand::before {
  content: "\F534"; }

.ms-Icon--DrillShow::before {
  content: "\F535"; }

.ms-Icon--DrillThrough::before {
  content: "\F5B9"; }

.ms-Icon--DRM::before {
  content: "\ECA8"; }

.ms-Icon--Drop::before {
  content: "\EB42"; }

.ms-Icon--Dropdown::before {
  content: "\EDC5"; }

.ms-Icon--DropShapeSolid::before {
  content: "\F63F"; }

.ms-Icon--Duststorm::before {
  content: "\E9CD"; }

.ms-Icon--Dynamics365Logo::before {
  content: "\EDCC"; }

.ms-Icon--DynamicSMBLogo::before {
  content: "\EDCD"; }

.ms-Icon--EatDrink::before {
  content: "\E807"; }

.ms-Icon--EdgeLogo::before {
  content: "\EC60"; }

.ms-Icon--EditContact::before {
  content: "\EFD3"; }

.ms-Icon--EditMail::before {
  content: "\EF61"; }

[dir='ltr'] .ms-Icon--Edit::before {
  content: "\E70F"; }

[dir='rtl'] .ms-Icon--Edit::before {
  content: "\EB7E"; }

[dir='ltr'] .ms-Icon--EditMirrored::before {
  content: "\EB7E"; }

[dir='rtl'] .ms-Icon--EditMirrored::before {
  content: "\E70F"; }

.ms-Icon--EditNote::before {
  content: "\ED9D"; }

.ms-Icon--EditPhoto::before {
  content: "\EF77"; }

[dir='ltr'] .ms-Icon--EditSolid12::before {
  content: "\F4B5"; }

[dir='rtl'] .ms-Icon--EditSolid12::before {
  content: "\F4B6"; }

[dir='ltr'] .ms-Icon--EditSolidMirrored12::before {
  content: "\F4B6"; }

[dir='rtl'] .ms-Icon--EditSolidMirrored12::before {
  content: "\F4B5"; }

.ms-Icon--EditStyle::before {
  content: "\EF60"; }

.ms-Icon--Education::before {
  content: "\E7BE"; }

.ms-Icon--Ellipse::before {
  content: "\F4FB"; }

.ms-Icon--Embed::before {
  content: "\ECCE"; }

.ms-Icon--EMI::before {
  content: "\E731"; }

.ms-Icon--Emoji::before {
  content: "\E899"; }

.ms-Icon--Emoji2::before {
  content: "\E76E"; }

.ms-Icon--EmojiDisappointed::before {
  content: "\EA88"; }

.ms-Icon--EmojiNeutral::before {
  content: "\EA87"; }

.ms-Icon--EmojiTabSymbols::before {
  content: "\ED58"; }

.ms-Icon--EmptyRecycleBin::before {
  content: "\EF88"; }

.ms-Icon--Encryption::before {
  content: "\F69D"; }

.ms-Icon--EngineeringGroup::before {
  content: "\F362"; }

.ms-Icon--EntryDecline::before {
  content: "\F555"; }

.ms-Icon--EntryView::before {
  content: "\F554"; }

.ms-Icon--Equalizer::before {
  content: "\E9E9"; }

.ms-Icon--EraseTool::before {
  content: "\E75C"; }

.ms-Icon--Error::before {
  content: "\E783"; }

.ms-Icon--ErrorBadge::before {
  content: "\EA39"; }

.ms-Icon--Event::before {
  content: "\ECA3"; }

.ms-Icon--EventAccepted::before {
  content: "\F422"; }

.ms-Icon--EventDate::before {
  content: "\F059"; }

.ms-Icon--EventDeclined::before {
  content: "\F425"; }

.ms-Icon--EventInfo::before {
  content: "\ED8B"; }

[dir='ltr'] .ms-Icon--EventTentative::before {
  content: "\F423"; }

[dir='rtl'] .ms-Icon--EventTentative::before {
  content: "\F424"; }

[dir='ltr'] .ms-Icon--EventTentativeMirrored::before {
  content: "\F424"; }

[dir='rtl'] .ms-Icon--EventTentativeMirrored::before {
  content: "\F423"; }

.ms-Icon--ExcelDocument::before {
  content: "\EF73"; }

.ms-Icon--ExcelLogo::before {
  content: "\F1E5"; }

.ms-Icon--ExcelLogo16::before {
  content: "\F397"; }

.ms-Icon--ExcelLogoFill::before {
  content: "\F1E6"; }

.ms-Icon--ExcelLogoFill16::before {
  content: "\F398"; }

.ms-Icon--ExcelLogoInverse::before {
  content: "\EC28"; }

.ms-Icon--ExcelLogoInverse16::before {
  content: "\F396"; }

.ms-Icon--ExchangeLogo::before {
  content: "\F284"; }

.ms-Icon--ExchangeLogoFill::before {
  content: "\F285"; }

.ms-Icon--ExchangeLogoInverse::before {
  content: "\ED78"; }

.ms-Icon--ExerciseTracker::before {
  content: "\EACC"; }

.ms-Icon--ExpandMenu::before {
  content: "\EF67"; }

.ms-Icon--ExploreContent::before {
  content: "\ECCD"; }

.ms-Icon--ExploreContentSingle::before {
  content: "\F164"; }

.ms-Icon--ExploreData::before {
  content: "\F5B6"; }

[dir='ltr'] .ms-Icon--Export::before {
  content: "\EDE1"; }

[dir='rtl'] .ms-Icon--Export::before {
  content: "\EDE2"; }

[dir='ltr'] .ms-Icon--ExportMirrored::before {
  content: "\EDE2"; }

[dir='rtl'] .ms-Icon--ExportMirrored::before {
  content: "\EDE1"; }

.ms-Icon--ExternalBuild::before {
  content: "\F445"; }

.ms-Icon--ExternalGit::before {
  content: "\F665"; }

.ms-Icon--ExternalTFVC::before {
  content: "\F446"; }

.ms-Icon--ExternalXAML::before {
  content: "\F447"; }

.ms-Icon--F12DevTools::before {
  content: "\EBEE"; }

.ms-Icon--FabricAssetLibrary::before {
  content: "\F09C"; }

.ms-Icon--FabricDataConnectionLibrary::before {
  content: "\F09D"; }

.ms-Icon--FabricDocLibrary::before {
  content: "\F09E"; }

.ms-Icon--FabricFolder::before {
  content: "\F0A9"; }

.ms-Icon--FabricFolderFill::before {
  content: "\F0AA"; }

.ms-Icon--FabricFolderSearch::before {
  content: "\F0A4"; }

[dir='ltr'] .ms-Icon--FabricFormLibrary::before {
  content: "\F09F"; }

[dir='rtl'] .ms-Icon--FabricFormLibrary::before {
  content: "\F0A0"; }

[dir='ltr'] .ms-Icon--FabricFormLibraryMirrored::before {
  content: "\F0A0"; }

[dir='rtl'] .ms-Icon--FabricFormLibraryMirrored::before {
  content: "\F09F"; }

.ms-Icon--FabricMovetoFolder::before {
  content: "\F0A5"; }

.ms-Icon--FabricNewFolder::before {
  content: "\F0AB"; }

.ms-Icon--FabricOpenFolderHorizontal::before {
  content: "\F0A8"; }

.ms-Icon--FabricPictureLibrary::before {
  content: "\F0AC"; }

.ms-Icon--FabricPublicFolder::before {
  content: "\F0A3"; }

[dir='ltr'] .ms-Icon--FabricReportLibrary::before {
  content: "\F0A1"; }

[dir='rtl'] .ms-Icon--FabricReportLibrary::before {
  content: "\F0A2"; }

[dir='ltr'] .ms-Icon--FabricReportLibraryMirrored::before {
  content: "\F0A2"; }

[dir='rtl'] .ms-Icon--FabricReportLibraryMirrored::before {
  content: "\F0A1"; }

.ms-Icon--FabricSyncFolder::before {
  content: "\F0A7"; }

.ms-Icon--FabricUnsyncFolder::before {
  content: "\F0A6"; }

.ms-Icon--Family::before {
  content: "\EBDA"; }

.ms-Icon--FangBody::before {
  content: "\ECEB"; }

.ms-Icon--FastForward::before {
  content: "\EB9D"; }

.ms-Icon--FastMode::before {
  content: "\F19A"; }

.ms-Icon--Favicon::before {
  content: "\E737"; }

.ms-Icon--FavoriteList::before {
  content: "\E728"; }

.ms-Icon--FavoriteStar::before {
  content: "\E734"; }

.ms-Icon--FavoriteStarFill::before {
  content: "\E735"; }

.ms-Icon--Fax::before {
  content: "\EF5C"; }

.ms-Icon--Feedback::before {
  content: "\ED15"; }

[dir='ltr'] .ms-Icon--FeedbackRequestSolid::before {
  content: "\F359"; }

[dir='rtl'] .ms-Icon--FeedbackRequestSolid::before {
  content: "\F35A"; }

[dir='ltr'] .ms-Icon--FeedbackRequestMirroredSolid::before {
  content: "\F35A"; }

[dir='rtl'] .ms-Icon--FeedbackRequestMirroredSolid::before {
  content: "\F359"; }

.ms-Icon--FeedbackResponseSolid::before {
  content: "\F35B"; }

.ms-Icon--Ferry::before {
  content: "\E7E3"; }

.ms-Icon--FerrySolid::before {
  content: "\EB48"; }

.ms-Icon--FieldChanged::before {
  content: "\F2C3"; }

.ms-Icon--FieldEmpty::before {
  content: "\F2C1"; }

.ms-Icon--FieldFilled::before {
  content: "\F2C2"; }

.ms-Icon--FieldNotChanged::before {
  content: "\F2C4"; }

.ms-Icon--FieldReadOnly::before {
  content: "\F442"; }

.ms-Icon--FieldRequired::before {
  content: "\F443"; }

.ms-Icon--FileASPX::before {
  content: "\F2E9"; }

.ms-Icon--FileBug::before {
  content: "\F30D"; }

.ms-Icon--FileCode::before {
  content: "\F30E"; }

.ms-Icon--FileComment::before {
  content: "\F30F"; }

.ms-Icon--FileCSS::before {
  content: "\F2EA"; }

.ms-Icon--FileHTML::before {
  content: "\F2ED"; }

.ms-Icon--FileImage::before {
  content: "\F311"; }

.ms-Icon--FileJAVA::before {
  content: "\F2E8"; }

.ms-Icon--FileLess::before {
  content: "\F2EC"; }

.ms-Icon--FilePDB::before {
  content: "\F2E5"; }

.ms-Icon--FileSass::before {
  content: "\F2EB"; }

.ms-Icon--FileSQL::before {
  content: "\F2E7"; }

.ms-Icon--FileSymlink::before {
  content: "\F312"; }

.ms-Icon--FileTemplate::before {
  content: "\F2E6"; }

.ms-Icon--FileTypeSolution::before {
  content: "\F387"; }

.ms-Icon--FileYML::before {
  content: "\F5DA"; }

.ms-Icon--Filter::before {
  content: "\E71C"; }

.ms-Icon--Filters::before {
  content: "\E795"; }

.ms-Icon--FilterSolid::before {
  content: "\F412"; }

.ms-Icon--FiltersSolid::before {
  content: "\F353"; }

.ms-Icon--Financial::before {
  content: "\E7BB"; }

[dir='ltr'] .ms-Icon--FinancialSolid::before {
  content: "\F346"; }

[dir='rtl'] .ms-Icon--FinancialSolid::before {
  content: "\F347"; }

[dir='ltr'] .ms-Icon--FinancialMirroredSolid::before {
  content: "\F347"; }

[dir='rtl'] .ms-Icon--FinancialMirroredSolid::before {
  content: "\F346"; }

.ms-Icon--Fingerprint::before {
  content: "\E928"; }

.ms-Icon--FiveTileGrid::before {
  content: "\F274"; }

.ms-Icon--Flag::before {
  content: "\E7C1"; }

.ms-Icon--FlameSolid::before {
  content: "\F1F3"; }

.ms-Icon--FlickDown::before {
  content: "\E935"; }

.ms-Icon--FlickLeft::before {
  content: "\E937"; }

.ms-Icon--FlickRight::before {
  content: "\E938"; }

.ms-Icon--FlickUp::before {
  content: "\E936"; }

.ms-Icon--Flow::before {
  content: "\EF90"; }

.ms-Icon--FocalPoint::before {
  content: "\F277"; }

.ms-Icon--Fog::before {
  content: "\E9CB"; }

.ms-Icon--Folder::before {
  content: "\E8B7"; }

.ms-Icon--FolderFill::before {
  content: "\E8D5"; }

.ms-Icon--FolderHorizontal::before {
  content: "\F12B"; }

[dir='ltr'] .ms-Icon--FolderList::before {
  content: "\F2CE"; }

[dir='rtl'] .ms-Icon--FolderList::before {
  content: "\F2CF"; }

[dir='ltr'] .ms-Icon--FolderListMirrored::before {
  content: "\F2CF"; }

[dir='rtl'] .ms-Icon--FolderListMirrored::before {
  content: "\F2CE"; }

.ms-Icon--FolderOpen::before {
  content: "\E838"; }

.ms-Icon--FolderQuery::before {
  content: "\F2CD"; }

.ms-Icon--FolderSearch::before {
  content: "\EF65"; }

.ms-Icon--FollowUser::before {
  content: "\EE05"; }

.ms-Icon--Font::before {
  content: "\E8D2"; }

.ms-Icon--FontColor::before {
  content: "\E8D3"; }

.ms-Icon--FontColorA::before {
  content: "\F4EC"; }

.ms-Icon--FontColorSwatch::before {
  content: "\F4ED"; }

.ms-Icon--FontDecrease::before {
  content: "\E8E7"; }

.ms-Icon--FontIncrease::before {
  content: "\E8E8"; }

.ms-Icon--FontSize::before {
  content: "\E8E9"; }

[dir='ltr'] .ms-Icon--FormLibrary::before {
  content: "\EEB9"; }

[dir='rtl'] .ms-Icon--FormLibrary::before {
  content: "\EEBA"; }

[dir='ltr'] .ms-Icon--FormLibraryMirrored::before {
  content: "\EEBA"; }

[dir='rtl'] .ms-Icon--FormLibraryMirrored::before {
  content: "\EEB9"; }

.ms-Icon--Forward::before {
  content: "\E72A"; }

.ms-Icon--ForwardEvent::before {
  content: "\ED8C"; }

.ms-Icon--Freezing::before {
  content: "\E9EF"; }

.ms-Icon--Frigid::before {
  content: "\E9CA"; }

.ms-Icon--FSharp::before {
  content: "\F2F6"; }

.ms-Icon--FSharpLanguage::before {
  content: "\F2F5"; }

.ms-Icon--FullCircleMask::before {
  content: "\E91F"; }

.ms-Icon--FullHistory::before {
  content: "\F31C"; }

.ms-Icon--FullScreen::before {
  content: "\E740"; }

.ms-Icon--FullWidth::before {
  content: "\F2FE"; }

.ms-Icon--FullWidthEdit::before {
  content: "\F2FF"; }

.ms-Icon--FunctionalManagerDashboard::before {
  content: "\F542"; }

.ms-Icon--GallatinLogo::before {
  content: "\F496"; }

.ms-Icon--Generate::before {
  content: "\E9DA"; }

.ms-Icon--GenericScan::before {
  content: "\EE6F"; }

.ms-Icon--Giftbox::before {
  content: "\EC1F"; }

.ms-Icon--GiftboxOpen::before {
  content: "\F133"; }

.ms-Icon--GiftBoxSolid::before {
  content: "\F341"; }

.ms-Icon--GiftCard::before {
  content: "\EB8E"; }

.ms-Icon--GitFork::before {
  content: "\F661"; }

.ms-Icon--GitGraph::before {
  content: "\F2CA"; }

.ms-Icon--Glasses::before {
  content: "\EA16"; }

.ms-Icon--Glimmer::before {
  content: "\ECF4"; }

.ms-Icon--GlobalNavButton::before {
  content: "\E700"; }

.ms-Icon--Globe::before {
  content: "\E774"; }

.ms-Icon--Globe2::before {
  content: "\F49A"; }

.ms-Icon--GlobeFavorite::before {
  content: "\EF53"; }

.ms-Icon--Golf::before {
  content: "\EB1F"; }

.ms-Icon--GotoToday::before {
  content: "\E8D1"; }

.ms-Icon--GridViewLarge::before {
  content: "\F234"; }

.ms-Icon--GridViewMedium::before {
  content: "\F233"; }

.ms-Icon--GridViewSmall::before {
  content: "\F232"; }

.ms-Icon--GripperBarHorizontal::before {
  content: "\E76F"; }

.ms-Icon--GripperBarVertical::before {
  content: "\E784"; }

.ms-Icon--GripperTool::before {
  content: "\E75E"; }

.ms-Icon--Group::before {
  content: "\E902"; }

.ms-Icon--GroupedAscending::before {
  content: "\EE67"; }

.ms-Icon--GroupedDescending::before {
  content: "\EE66"; }

.ms-Icon--GroupedList::before {
  content: "\EF74"; }

.ms-Icon--GroupObject::before {
  content: "\F4F1"; }

.ms-Icon--GUID::before {
  content: "\F52B"; }

.ms-Icon--Guitar::before {
  content: "\F49B"; }

.ms-Icon--HailDay::before {
  content: "\EA00"; }

.ms-Icon--HailNight::before {
  content: "\EA13"; }

.ms-Icon--HalfAlpha::before {
  content: "\E97E"; }

.ms-Icon--HalfCircle::before {
  content: "\F501"; }

.ms-Icon--HandsFree::before {
  content: "\EAD0"; }

.ms-Icon--Handwriting::before {
  content: "\E929"; }

.ms-Icon--HardDrive::before {
  content: "\EDA2"; }

.ms-Icon--HardDriveGroup::before {
  content: "\F18F"; }

.ms-Icon--HardDriveLock::before {
  content: "\F55A"; }

.ms-Icon--HardDriveUnlock::before {
  content: "\F55B"; }

.ms-Icon--Header1::before {
  content: "\EA19"; }

.ms-Icon--Header2::before {
  content: "\EF36"; }

.ms-Icon--Header3::before {
  content: "\EF37"; }

.ms-Icon--Header4::before {
  content: "\EF38"; }

.ms-Icon--Headset::before {
  content: "\E95B"; }

.ms-Icon--HeadsetSolid::before {
  content: "\F348"; }

.ms-Icon--Health::before {
  content: "\E95E"; }

.ms-Icon--HealthSolid::before {
  content: "\F33F"; }

.ms-Icon--Heart::before {
  content: "\EB51"; }

.ms-Icon--HeartBroken::before {
  content: "\EA92"; }

.ms-Icon--HeartFill::before {
  content: "\EB52"; }

[dir='ltr'] .ms-Icon--Help::before {
  content: "\E897"; }

[dir='rtl'] .ms-Icon--Help::before {
  content: "\EA51"; }

[dir='ltr'] .ms-Icon--HelpMirrored::before {
  content: "\EA51"; }

[dir='rtl'] .ms-Icon--HelpMirrored::before {
  content: "\E897"; }

.ms-Icon--Hexagon::before {
  content: "\F4FE"; }

.ms-Icon--Hide::before {
  content: "\ED1A"; }

.ms-Icon--Hide2::before {
  content: "\EF89"; }

.ms-Icon--Highlight::before {
  content: "\E7E6"; }

.ms-Icon--HighlightMappedShapes::before {
  content: "\F2A1"; }

.ms-Icon--HintText::before {
  content: "\F50F"; }

.ms-Icon--History::before {
  content: "\E81C"; }

.ms-Icon--Home::before {
  content: "\E80F"; }

.ms-Icon--HomeSolid::before {
  content: "\EA8A"; }

.ms-Icon--HorizontalDistributeCenter::before {
  content: "\F4F9"; }

.ms-Icon--Hospital::before {
  content: "\E91D"; }

.ms-Icon--Hotel::before {
  content: "\E824"; }

.ms-Icon--HourGlass::before {
  content: "\EA03"; }

.ms-Icon--IconSetsFlag::before {
  content: "\F2A4"; }

.ms-Icon--IDBadge::before {
  content: "\F427"; }

.ms-Icon--ImageCrosshair::before {
  content: "\F2C9"; }

.ms-Icon--ImageDiff::before {
  content: "\F30B"; }

.ms-Icon--ImagePixel::before {
  content: "\F30C"; }

.ms-Icon--ImageSearch::before {
  content: "\F4E8"; }

.ms-Icon--Important::before {
  content: "\E8C9"; }

[dir='ltr'] .ms-Icon--Import::before {
  content: "\E8B5"; }

[dir='rtl'] .ms-Icon--Import::before {
  content: "\EA52"; }

[dir='ltr'] .ms-Icon--ImportMirrored::before {
  content: "\EA52"; }

[dir='rtl'] .ms-Icon--ImportMirrored::before {
  content: "\E8B5"; }

.ms-Icon--Inbox::before {
  content: "\F41C"; }

.ms-Icon--InboxCheck::before {
  content: "\EF64"; }

.ms-Icon--IncidentTriangle::before {
  content: "\E814"; }

.ms-Icon--IncreaseIndentLegacy::before {
  content: "\E291"; }

.ms-Icon--Info::before {
  content: "\E946"; }

.ms-Icon--Info2::before {
  content: "\EA1F"; }

.ms-Icon--InfoSolid::before {
  content: "\F167"; }

.ms-Icon--InsertColumnsLeft::before {
  content: "\F64A"; }

.ms-Icon--InsertColumnsRight::before {
  content: "\F64B"; }

.ms-Icon--InsertRowsAbove::before {
  content: "\F64C"; }

.ms-Icon--InsertRowsBelow::before {
  content: "\F64D"; }

.ms-Icon--InsertSignatureLine::before {
  content: "\F677"; }

.ms-Icon--InsertTextBox::before {
  content: "\EC7D"; }

.ms-Icon--InstallToDrive::before {
  content: "\F28D"; }

.ms-Icon--InternetSharing::before {
  content: "\E704"; }

[dir='ltr'] .ms-Icon--IRMForward::before {
  content: "\F41F"; }

[dir='rtl'] .ms-Icon--IRMForward::before {
  content: "\F420"; }

[dir='ltr'] .ms-Icon--IRMForwardMirrored::before {
  content: "\F420"; }

[dir='rtl'] .ms-Icon--IRMForwardMirrored::before {
  content: "\F41F"; }

[dir='ltr'] .ms-Icon--IRMReply::before {
  content: "\F41D"; }

[dir='rtl'] .ms-Icon--IRMReply::before {
  content: "\F41E"; }

[dir='ltr'] .ms-Icon--IRMReplyMirrored::before {
  content: "\F41E"; }

[dir='rtl'] .ms-Icon--IRMReplyMirrored::before {
  content: "\F41D"; }

.ms-Icon--IssueSolid::before {
  content: "\F448"; }

[dir='ltr'] .ms-Icon--IssueTracking::before {
  content: "\EEC0"; }

[dir='rtl'] .ms-Icon--IssueTracking::before {
  content: "\EEC1"; }

[dir='ltr'] .ms-Icon--IssueTrackingMirrored::before {
  content: "\EEC1"; }

[dir='rtl'] .ms-Icon--IssueTrackingMirrored::before {
  content: "\EEC0"; }

.ms-Icon--Italic::before {
  content: "\E8DB"; }

.ms-Icon--JavaScriptLanguage::before {
  content: "\F2EE"; }

.ms-Icon--JoinOnlineMeeting::before {
  content: "\ED8F"; }

.ms-Icon--JS::before {
  content: "\EBF0"; }

.ms-Icon--KaizalaLogo::before {
  content: "\F492"; }

.ms-Icon--Label::before {
  content: "\E932"; }

.ms-Icon--LadybugSolid::before {
  content: "\F44A"; }

.ms-Icon--Lamp::before {
  content: "\EB19"; }

.ms-Icon--LandscapeOrientation::before {
  content: "\EF6B"; }

.ms-Icon--LaptopSecure::before {
  content: "\F552"; }

.ms-Icon--LaptopSelected::before {
  content: "\EC76"; }

.ms-Icon--LargeGrid::before {
  content: "\EECB"; }

.ms-Icon--Leave::before {
  content: "\F627"; }

.ms-Icon--Library::before {
  content: "\E8F1"; }

.ms-Icon--Lifesaver::before {
  content: "\EF62"; }

.ms-Icon--LifesaverLock::before {
  content: "\EF63"; }

.ms-Icon--Light::before {
  content: "\E793"; }

.ms-Icon--Lightbulb::before {
  content: "\EA80"; }

.ms-Icon--LightningBolt::before {
  content: "\E945"; }

.ms-Icon--LightWeight::before {
  content: "\F4EE"; }

.ms-Icon--Like::before {
  content: "\E8E1"; }

.ms-Icon--LikeSolid::before {
  content: "\F3BF"; }

.ms-Icon--Line::before {
  content: "\F4FC"; }

.ms-Icon--LineChart::before {
  content: "\E9E6"; }

.ms-Icon--LineSpacing::before {
  content: "\F517"; }

.ms-Icon--LineStyle::before {
  content: "\F50C"; }

.ms-Icon--LineThickness::before {
  content: "\F50D"; }

.ms-Icon--Link::before {
  content: "\E71B"; }

.ms-Icon--LinkedInLogo::before {
  content: "\F20A"; }

[dir='ltr'] .ms-Icon--List::before {
  content: "\EA37"; }

[dir='rtl'] .ms-Icon--List::before {
  content: "\EA55"; }

[dir='ltr'] .ms-Icon--ListMirrored::before {
  content: "\EA55"; }

[dir='rtl'] .ms-Icon--ListMirrored::before {
  content: "\EA37"; }

.ms-Icon--LocaleLanguage::before {
  content: "\F2B7"; }

.ms-Icon--Location::before {
  content: "\E81D"; }

.ms-Icon--LocationCircle::before {
  content: "\E80E"; }

.ms-Icon--LocationDot::before {
  content: "\E827"; }

.ms-Icon--LocationFill::before {
  content: "\E920"; }

.ms-Icon--LocationOutline::before {
  content: "\F2D0"; }

.ms-Icon--Lock::before {
  content: "\E72E"; }

.ms-Icon--LockSolid::before {
  content: "\E9A2"; }

.ms-Icon--LogRemove::before {
  content: "\F316"; }

.ms-Icon--LookupEntities::before {
  content: "\F5B5"; }

.ms-Icon--LowerBrightness::before {
  content: "\EC8A"; }

.ms-Icon--LyncLogo::before {
  content: "\ED79"; }

.ms-Icon--Mail::before {
  content: "\E715"; }

.ms-Icon--MailAlert::before {
  content: "\ED80"; }

.ms-Icon--MailCheck::before {
  content: "\ED81"; }

.ms-Icon--MailFill::before {
  content: "\E8A8"; }

[dir='ltr'] .ms-Icon--MailForward::before {
  content: "\E89C"; }

[dir='rtl'] .ms-Icon--MailForward::before {
  content: "\EA56"; }

[dir='ltr'] .ms-Icon--MailForwardMirrored::before {
  content: "\EA56"; }

[dir='rtl'] .ms-Icon--MailForwardMirrored::before {
  content: "\E89C"; }

.ms-Icon--MailLowImportance::before {
  content: "\ED82"; }

.ms-Icon--MailPause::before {
  content: "\ED83"; }

.ms-Icon--MailReminder::before {
  content: "\F418"; }

.ms-Icon--MailRepeat::before {
  content: "\ED84"; }

[dir='ltr'] .ms-Icon--MailReplyAll::before {
  content: "\E8C2"; }

[dir='rtl'] .ms-Icon--MailReplyAll::before {
  content: "\EA58"; }

[dir='ltr'] .ms-Icon--MailReplyAllMirrored::before {
  content: "\EA58"; }

[dir='rtl'] .ms-Icon--MailReplyAllMirrored::before {
  content: "\E8C2"; }

[dir='ltr'] .ms-Icon--MailReply::before {
  content: "\E8CA"; }

[dir='rtl'] .ms-Icon--MailReply::before {
  content: "\EA57"; }

[dir='ltr'] .ms-Icon--MailReplyMirrored::before {
  content: "\EA57"; }

[dir='rtl'] .ms-Icon--MailReplyMirrored::before {
  content: "\E8CA"; }

.ms-Icon--MailSolid::before {
  content: "\F343"; }

[dir='ltr'] .ms-Icon--MailTentative::before {
  content: "\F416"; }

[dir='rtl'] .ms-Icon--MailTentative::before {
  content: "\F417"; }

[dir='ltr'] .ms-Icon--MailTentativeMirrored::before {
  content: "\F417"; }

[dir='rtl'] .ms-Icon--MailTentativeMirrored::before {
  content: "\F416"; }

.ms-Icon--MailUndelivered::before {
  content: "\F415"; }

.ms-Icon--ManagerSelfService::before {
  content: "\EE23"; }

.ms-Icon--MapDirections::before {
  content: "\E816"; }

.ms-Icon--MapPin::before {
  content: "\E707"; }

.ms-Icon--MapPinSolid::before {
  content: "\F52E"; }

.ms-Icon--MarkDownLanguage::before {
  content: "\F2FB"; }

.ms-Icon--Market::before {
  content: "\EAFC"; }

.ms-Icon--MarketDown::before {
  content: "\EF42"; }

.ms-Icon--MasterDatabase::before {
  content: "\F5BA"; }

.ms-Icon--MaximumValue::before {
  content: "\F5BC"; }

.ms-Icon--Medal::before {
  content: "\EE38"; }

.ms-Icon--MediaAdd::before {
  content: "\F510"; }

.ms-Icon--Medical::before {
  content: "\EAD4"; }

.ms-Icon--Megaphone::before {
  content: "\E789"; }

.ms-Icon--MegaphoneSolid::before {
  content: "\F332"; }

.ms-Icon--Memo::before {
  content: "\E77C"; }

.ms-Icon--Merge::before {
  content: "\E7D5"; }

.ms-Icon--MergeDuplicate::before {
  content: "\F29A"; }

.ms-Icon--Message::before {
  content: "\E8BD"; }

.ms-Icon--MessageFill::before {
  content: "\EC70"; }

.ms-Icon--MicOff::before {
  content: "\EC54"; }

.ms-Icon--Microphone::before {
  content: "\E720"; }

.ms-Icon--MicrosoftFlowLogo::before {
  content: "\F4B1"; }

.ms-Icon--MicrosoftStaffhubLogo::before {
  content: "\F130"; }

.ms-Icon--MiniContract::before {
  content: "\E93B"; }

.ms-Icon--MiniExpand::before {
  content: "\E93A"; }

.ms-Icon--MiniLink::before {
  content: "\E732"; }

.ms-Icon--MinimumValue::before {
  content: "\F5BD"; }

.ms-Icon--MobileReport::before {
  content: "\F18A"; }

.ms-Icon--MobileSelected::before {
  content: "\EC75"; }

.ms-Icon--Money::before {
  content: "\EAFD"; }

.ms-Icon--More::before {
  content: "\E712"; }

.ms-Icon--MoreSports::before {
  content: "\EB22"; }

.ms-Icon--MoreVertical::before {
  content: "\F2BC"; }

.ms-Icon--Move::before {
  content: "\E7C2"; }

.ms-Icon--Movers::before {
  content: "\EBCD"; }

.ms-Icon--MoveToFolder::before {
  content: "\E8DE"; }

.ms-Icon--MSNLogo::before {
  content: "\EB6C"; }

.ms-Icon--MSNVideos::before {
  content: "\EB1C"; }

.ms-Icon--MSNVideosSolid::before {
  content: "\F2DA"; }

.ms-Icon--MTMLogo::before {
  content: "\F44B"; }

[dir='ltr'] .ms-Icon--MultiSelect::before {
  content: "\E762"; }

[dir='rtl'] .ms-Icon--MultiSelect::before {
  content: "\EA98"; }

[dir='ltr'] .ms-Icon--MultiSelectMirrored::before {
  content: "\EA98"; }

[dir='rtl'] .ms-Icon--MultiSelectMirrored::before {
  content: "\E762"; }

.ms-Icon--MusicInCollection::before {
  content: "\E940"; }

.ms-Icon--MusicInCollectionFill::before {
  content: "\EA36"; }

.ms-Icon--MusicNote::before {
  content: "\EC4F"; }

.ms-Icon--MyMoviesTV::before {
  content: "\EE6C"; }

.ms-Icon--Nav2DMapView::before {
  content: "\E800"; }

[dir='ltr'] .ms-Icon--NavigateBack::before {
  content: "\F2DD"; }

[dir='rtl'] .ms-Icon--NavigateBack::before {
  content: "\F2DE"; }

[dir='ltr'] .ms-Icon--NavigateBackMirrored::before {
  content: "\F2DE"; }

[dir='rtl'] .ms-Icon--NavigateBackMirrored::before {
  content: "\F2DD"; }

.ms-Icon--NavigateExternalInline::before {
  content: "\F35F"; }

[dir='ltr'] .ms-Icon--NavigateForward::before {
  content: "\F2DF"; }

[dir='rtl'] .ms-Icon--NavigateForward::before {
  content: "\F2E0"; }

[dir='ltr'] .ms-Icon--NavigateForwardMirrored::before {
  content: "\F2E0"; }

[dir='rtl'] .ms-Icon--NavigateForwardMirrored::before {
  content: "\F2DF"; }

.ms-Icon--NavigationFlipper::before {
  content: "\F51D"; }

.ms-Icon--NetworkTower::before {
  content: "\EC05"; }

.ms-Icon--NewAnalyticsQuery::before {
  content: "\F1E0"; }

.ms-Icon--NewFolder::before {
  content: "\E8F4"; }

.ms-Icon--News::before {
  content: "\E900"; }

.ms-Icon--NewsSearch::before {
  content: "\F4E9"; }

.ms-Icon--NewTeamProject::before {
  content: "\F2B2"; }

.ms-Icon--Next::before {
  content: "\E893"; }

.ms-Icon--NonprofitLogo32::before {
  content: "\F495"; }

.ms-Icon--NormalWeight::before {
  content: "\F4EF"; }

.ms-Icon--NoteForward::before {
  content: "\ED99"; }

.ms-Icon--NotePinned::before {
  content: "\ED9A"; }

.ms-Icon--NoteReply::before {
  content: "\ED98"; }

.ms-Icon--NotExecuted::before {
  content: "\F440"; }

.ms-Icon--NotImpactedSolid::before {
  content: "\F441"; }

.ms-Icon--NugetLogo::before {
  content: "\F44C"; }

.ms-Icon--NumberedList::before {
  content: "\EA1C"; }

.ms-Icon--NumberField::before {
  content: "\EDC4"; }

.ms-Icon--NumberSequence::before {
  content: "\F52A"; }

.ms-Icon--Octagon::before {
  content: "\F4FD"; }

.ms-Icon--OEM::before {
  content: "\E74C"; }

.ms-Icon--OfficeAddinsLogo::before {
  content: "\EEC7"; }

.ms-Icon--OfficeAssistantLogo::before {
  content: "\EDCE"; }

.ms-Icon--OfficeFormsLogo::before {
  content: "\F434"; }

.ms-Icon--OfficeFormsLogo16::before {
  content: "\F436"; }

.ms-Icon--OfficeFormsLogo24::before {
  content: "\F43B"; }

.ms-Icon--OfficeFormsLogoFill::before {
  content: "\F435"; }

.ms-Icon--OfficeFormsLogoFill16::before {
  content: "\F437"; }

.ms-Icon--OfficeFormsLogoFill24::before {
  content: "\F43C"; }

.ms-Icon--OfficeFormsLogoInverse::before {
  content: "\EF86"; }

.ms-Icon--OfficeFormsLogoInverse16::before {
  content: "\F433"; }

.ms-Icon--OfficeFormsLogoInverse24::before {
  content: "\F43A"; }

.ms-Icon--OfficeLogo::before {
  content: "\EB6E"; }

.ms-Icon--OfficeStoreLogo::before {
  content: "\EDCF"; }

.ms-Icon--OfficeVideoLogo::before {
  content: "\F282"; }

.ms-Icon--OfficeVideoLogoFill::before {
  content: "\F283"; }

.ms-Icon--OfficeVideoLogoInverse::before {
  content: "\ED7A"; }

.ms-Icon--OfflineOneDriveParachute::before {
  content: "\EEC8"; }

.ms-Icon--OfflineOneDriveParachuteDisabled::before {
  content: "\EEC9"; }

.ms-Icon--OfflineStorageSolid::before {
  content: "\F34E"; }

.ms-Icon--OneDrive::before {
  content: "\E941"; }

.ms-Icon--OneDriveAdd::before {
  content: "\EF32"; }

.ms-Icon--OneDriveFolder16::before {
  content: "\F53B"; }

.ms-Icon--OneNoteEduLogoInverse::before {
  content: "\EDD0"; }

.ms-Icon--OneNoteLogo::before {
  content: "\F1E7"; }

.ms-Icon--OneNoteLogo16::before {
  content: "\F39A"; }

.ms-Icon--OneNoteLogoFill::before {
  content: "\F1E8"; }

.ms-Icon--OneNoteLogoFill16::before {
  content: "\F39B"; }

.ms-Icon--OneNoteLogoInverse::before {
  content: "\EC0D"; }

.ms-Icon--OneNoteLogoInverse16::before {
  content: "\F399"; }

.ms-Icon--OpenFile::before {
  content: "\E8E5"; }

.ms-Icon--OpenFolderHorizontal::before {
  content: "\ED25"; }

.ms-Icon--OpenInNewWindow::before {
  content: "\E8A7"; }

[dir='ltr'] .ms-Icon--OpenPane::before {
  content: "\E8A0"; }

[dir='rtl'] .ms-Icon--OpenPane::before {
  content: "\EA5B"; }

[dir='ltr'] .ms-Icon--OpenPaneMirrored::before {
  content: "\EA5B"; }

[dir='rtl'] .ms-Icon--OpenPaneMirrored::before {
  content: "\E8A0"; }

.ms-Icon--OpenSource::before {
  content: "\EBC2"; }

.ms-Icon--Org::before {
  content: "\ECA6"; }

.ms-Icon--Orientation::before {
  content: "\E8B4"; }

.ms-Icon--OutlookLogo::before {
  content: "\F1E9"; }

.ms-Icon--OutlookLogo16::before {
  content: "\F39D"; }

.ms-Icon--OutlookLogoFill::before {
  content: "\F1EA"; }

.ms-Icon--OutlookLogoFill16::before {
  content: "\F39E"; }

.ms-Icon--OutlookLogoInverse::before {
  content: "\EB6D"; }

.ms-Icon--OutlookLogoInverse16::before {
  content: "\F39C"; }

.ms-Icon--OutOfOffice::before {
  content: "\ED34"; }

.ms-Icon--Package::before {
  content: "\E7B8"; }

.ms-Icon--Packages::before {
  content: "\F318"; }

.ms-Icon--Padding::before {
  content: "\F518"; }

.ms-Icon--PaddingBottom::before {
  content: "\F51A"; }

.ms-Icon--PaddingLeft::before {
  content: "\F51B"; }

.ms-Icon--PaddingRight::before {
  content: "\F51C"; }

.ms-Icon--PaddingTop::before {
  content: "\F519"; }

.ms-Icon--Page::before {
  content: "\E7C3"; }

.ms-Icon--PageAdd::before {
  content: "\EA1A"; }

.ms-Icon--PageCheckedin::before {
  content: "\F104"; }

.ms-Icon--PageCheckedOut::before {
  content: "\F02C"; }

.ms-Icon--PageEdit::before {
  content: "\EFB6"; }

.ms-Icon--PageLeft::before {
  content: "\E760"; }

[dir='ltr'] .ms-Icon--PageListSolid::before {
  content: "\F33A"; }

[dir='rtl'] .ms-Icon--PageListSolid::before {
  content: "\F33B"; }

[dir='ltr'] .ms-Icon--PageListMirroredSolid::before {
  content: "\F33B"; }

[dir='rtl'] .ms-Icon--PageListMirroredSolid::before {
  content: "\F33A"; }

.ms-Icon--PageLock::before {
  content: "\F43F"; }

.ms-Icon--PageRemove::before {
  content: "\EFBA"; }

.ms-Icon--PageRight::before {
  content: "\E761"; }

.ms-Icon--PageSolid::before {
  content: "\E729"; }

.ms-Icon--PanoIndicator::before {
  content: "\E7B0"; }

.ms-Icon--Parachute::before {
  content: "\F351"; }

.ms-Icon--ParachuteSolid::before {
  content: "\F352"; }

.ms-Icon--Parameter::before {
  content: "\F306"; }

[dir='ltr'] .ms-Icon--ParkingLocation::before {
  content: "\E811"; }

[dir='rtl'] .ms-Icon--ParkingLocation::before {
  content: "\EA5E"; }

[dir='ltr'] .ms-Icon--ParkingLocationMirrored::before {
  content: "\EA5E"; }

[dir='rtl'] .ms-Icon--ParkingLocationMirrored::before {
  content: "\E811"; }

[dir='ltr'] .ms-Icon--ParkingSolid::before {
  content: "\F34A"; }

[dir='rtl'] .ms-Icon--ParkingSolid::before {
  content: "\F34B"; }

[dir='ltr'] .ms-Icon--ParkingMirroredSolid::before {
  content: "\F34B"; }

[dir='rtl'] .ms-Icon--ParkingMirroredSolid::before {
  content: "\F34A"; }

.ms-Icon--PartlyCloudyDay::before {
  content: "\E9C0"; }

.ms-Icon--PartlyCloudyNight::before {
  content: "\E9C1"; }

.ms-Icon--PartyLeader::before {
  content: "\ECA7"; }

.ms-Icon--Paste::before {
  content: "\E77F"; }

.ms-Icon--PasteAsCode::before {
  content: "\F5D6"; }

.ms-Icon--PasteAsText::before {
  content: "\F5D5"; }

.ms-Icon--Pause::before {
  content: "\E769"; }

.ms-Icon--PaymentCard::before {
  content: "\E8C7"; }

.ms-Icon--PC1::before {
  content: "\E977"; }

.ms-Icon--PDF::before {
  content: "\EA90"; }

.ms-Icon--PencilReply::before {
  content: "\EF7B"; }

.ms-Icon--Pentagon::before {
  content: "\F4FF"; }

.ms-Icon--People::before {
  content: "\E716"; }

.ms-Icon--PeopleAdd::before {
  content: "\EA15"; }

.ms-Icon--PeopleAlert::before {
  content: "\ED93"; }

.ms-Icon--PeopleBlock::before {
  content: "\ED91"; }

.ms-Icon--PeoplePause::before {
  content: "\ED94"; }

.ms-Icon--PeopleRepeat::before {
  content: "\ED92"; }

.ms-Icon--Permissions::before {
  content: "\E8D7"; }

.ms-Icon--PermissionsSolid::before {
  content: "\F349"; }

.ms-Icon--Personalize::before {
  content: "\E771"; }

.ms-Icon--Phishing::before {
  content: "\F679"; }

.ms-Icon--Phone::before {
  content: "\E717"; }

.ms-Icon--Photo2::before {
  content: "\EB9F"; }

.ms-Icon--Photo2Add::before {
  content: "\ECAB"; }

.ms-Icon--Photo2Remove::before {
  content: "\ECAC"; }

.ms-Icon--PhotoCollection::before {
  content: "\E7AA"; }

.ms-Icon--Picture::before {
  content: "\E8B9"; }

.ms-Icon--PictureCenter::before {
  content: "\F522"; }

.ms-Icon--PictureFill::before {
  content: "\F523"; }

.ms-Icon--PictureLibrary::before {
  content: "\EEC2"; }

.ms-Icon--PicturePosition::before {
  content: "\F524"; }

.ms-Icon--PictureStretch::before {
  content: "\F525"; }

.ms-Icon--PictureTile::before {
  content: "\F526"; }

.ms-Icon--PieDouble::before {
  content: "\EB04"; }

.ms-Icon--PieSingle::before {
  content: "\EB05"; }

.ms-Icon--PieSingleSolid::before {
  content: "\F530"; }

.ms-Icon--Pill::before {
  content: "\EACB"; }

.ms-Icon--Pin::before {
  content: "\E718"; }

.ms-Icon--Pinned::before {
  content: "\E840"; }

.ms-Icon--PinnedFill::before {
  content: "\E842"; }

.ms-Icon--PivotChart::before {
  content: "\F24C"; }

.ms-Icon--PlannerLogo::before {
  content: "\EDD1"; }

.ms-Icon--PlanView::before {
  content: "\F360"; }

.ms-Icon--Play::before {
  content: "\E768"; }

.ms-Icon--PlayerSettings::before {
  content: "\EF58"; }

.ms-Icon--PlayResume::before {
  content: "\F2C6"; }

.ms-Icon--Plug::before {
  content: "\F300"; }

.ms-Icon--PlugConnected::before {
  content: "\F302"; }

.ms-Icon--PlugDisconnected::before {
  content: "\F303"; }

.ms-Icon--PlugSolid::before {
  content: "\F301"; }

.ms-Icon--POI::before {
  content: "\ECAF"; }

.ms-Icon--POISolid::before {
  content: "\F2D1"; }

.ms-Icon--PostUpdate::before {
  content: "\E8F3"; }

.ms-Icon--PowerApps::before {
  content: "\EDD2"; }

.ms-Icon--PowerApps2Logo::before {
  content: "\F092"; }

.ms-Icon--PowerAppsLogo::before {
  content: "\F091"; }

.ms-Icon--PowerBILogo::before {
  content: "\EA1E"; }

.ms-Icon--PowerButton::before {
  content: "\E7E8"; }

.ms-Icon--PowerPointDocument::before {
  content: "\EF72"; }

.ms-Icon--PowerPointLogo::before {
  content: "\F1EB"; }

.ms-Icon--PowerPointLogo16::before {
  content: "\F394"; }

.ms-Icon--PowerPointLogoFill::before {
  content: "\F1EC"; }

.ms-Icon--PowerPointLogoFill16::before {
  content: "\F395"; }

.ms-Icon--PowerPointLogoInverse::before {
  content: "\EC2A"; }

.ms-Icon--PowerPointLogoInverse16::before {
  content: "\F393"; }

.ms-Icon--Precipitation::before {
  content: "\E9CF"; }

.ms-Icon--PresenceChickletVideo::before {
  content: "\E979"; }

.ms-Icon--Preview::before {
  content: "\E8FF"; }

.ms-Icon--PreviewLink::before {
  content: "\E8A1"; }

.ms-Icon--Previous::before {
  content: "\E892"; }

.ms-Icon--PrimaryCalendar::before {
  content: "\F4AE"; }

.ms-Icon--Print::before {
  content: "\E749"; }

.ms-Icon--PrintfaxPrinterFile::before {
  content: "\E956"; }

.ms-Icon--Processing::before {
  content: "\E9F5"; }

.ms-Icon--ProcessMetaTask::before {
  content: "\F290"; }

.ms-Icon--Product::before {
  content: "\ECDC"; }

.ms-Icon--ProfileSearch::before {
  content: "\EF35"; }

.ms-Icon--ProFootball::before {
  content: "\EB27"; }

.ms-Icon--ProgressLoopInner::before {
  content: "\ECDE"; }

.ms-Icon--ProgressLoopOuter::before {
  content: "\ECDF"; }

.ms-Icon--ProgressRingDots::before {
  content: "\F16A"; }

.ms-Icon--ProHockey::before {
  content: "\EB28"; }

.ms-Icon--ProjectCollection::before {
  content: "\F363"; }

.ms-Icon--ProjectLogo16::before {
  content: "\F480"; }

.ms-Icon--ProjectLogo32::before {
  content: "\F47E"; }

.ms-Icon--ProjectLogoFill16::before {
  content: "\F481"; }

.ms-Icon--ProjectLogoFill32::before {
  content: "\F47F"; }

.ms-Icon--ProjectLogoInverse::before {
  content: "\EDD4"; }

.ms-Icon--ProtectedDocument::before {
  content: "\E8A6"; }

.ms-Icon--ProtectionCenterLogo32::before {
  content: "\F494"; }

.ms-Icon--ProtectRestrict::before {
  content: "\F22A"; }

.ms-Icon--PublicCalendar::before {
  content: "\EF6D"; }

[dir='ltr'] .ms-Icon--PublicContactCard::before {
  content: "\EF6E"; }

[dir='rtl'] .ms-Icon--PublicContactCard::before {
  content: "\F230"; }

[dir='ltr'] .ms-Icon--PublicContactCardMirrored::before {
  content: "\F230"; }

[dir='rtl'] .ms-Icon--PublicContactCardMirrored::before {
  content: "\EF6E"; }

.ms-Icon--PublicEmail::before {
  content: "\EF6F"; }

.ms-Icon--PublicFolder::before {
  content: "\EF70"; }

.ms-Icon--PublishCourse::before {
  content: "\F699"; }

.ms-Icon--PublisherLogo::before {
  content: "\F1ED"; }

.ms-Icon--PublisherLogo16::before {
  content: "\F3A0"; }

.ms-Icon--PublisherLogoFill::before {
  content: "\F1EE"; }

.ms-Icon--PublisherLogoFill16::before {
  content: "\F3A1"; }

.ms-Icon--PublisherLogoInverse16::before {
  content: "\F39F"; }

.ms-Icon--Puzzle::before {
  content: "\EA86"; }

.ms-Icon--PY::before {
  content: "\F2F9"; }

.ms-Icon--PythonLanguage::before {
  content: "\F2F8"; }

.ms-Icon--QuarterCircle::before {
  content: "\F502"; }

.ms-Icon--QueryList::before {
  content: "\F2B8"; }

[dir='ltr'] .ms-Icon--Questionnaire::before {
  content: "\EE19"; }

[dir='rtl'] .ms-Icon--Questionnaire::before {
  content: "\EE4B"; }

[dir='ltr'] .ms-Icon--QuestionnaireMirrored::before {
  content: "\EE4B"; }

[dir='rtl'] .ms-Icon--QuestionnaireMirrored::before {
  content: "\EE19"; }

.ms-Icon--QuickNote::before {
  content: "\E70B"; }

.ms-Icon--QuickNoteSolid::before {
  content: "\F338"; }

.ms-Icon--R::before {
  content: "\F4EB"; }

.ms-Icon--RadioBtnOff::before {
  content: "\ECCA"; }

.ms-Icon--RadioBtnOn::before {
  content: "\ECCB"; }

.ms-Icon--RadioBullet::before {
  content: "\E915"; }

.ms-Icon--Rain::before {
  content: "\E9C4"; }

.ms-Icon--RainShowersDay::before {
  content: "\E9C3"; }

.ms-Icon--RainShowersNight::before {
  content: "\EA0F"; }

.ms-Icon--RainSnow::before {
  content: "\E9C7"; }

.ms-Icon--RawSource::before {
  content: "\F299"; }

.ms-Icon--Read::before {
  content: "\E8C3"; }

.ms-Icon--ReadingMode::before {
  content: "\E736"; }

.ms-Icon--ReadingModeSolid::before {
  content: "\F33D"; }

.ms-Icon--ReadOutLoud::before {
  content: "\F112"; }

.ms-Icon--ReceiptCheck::before {
  content: "\EF5B"; }

.ms-Icon--ReceiptForward::before {
  content: "\EF59"; }

.ms-Icon--ReceiptReply::before {
  content: "\EF5A"; }

[dir='ltr'] .ms-Icon--ReceiptTentative::before {
  content: "\F41A"; }

[dir='rtl'] .ms-Icon--ReceiptTentative::before {
  content: "\F41B"; }

[dir='ltr'] .ms-Icon--ReceiptTentativeMirrored::before {
  content: "\F41B"; }

[dir='rtl'] .ms-Icon--ReceiptTentativeMirrored::before {
  content: "\F41A"; }

.ms-Icon--ReceiptUndelivered::before {
  content: "\F419"; }

.ms-Icon--Recent::before {
  content: "\E823"; }

.ms-Icon--Record2::before {
  content: "\EA3F"; }

.ms-Icon--RectangleShapeSolid::before {
  content: "\F640"; }

.ms-Icon--RectangularClipping::before {
  content: "\F407"; }

.ms-Icon--RecurringEvent::before {
  content: "\EF5D"; }

.ms-Icon--RecurringTask::before {
  content: "\EDB2"; }

.ms-Icon--RecycleBin::before {
  content: "\EF87"; }

.ms-Icon--Redeploy::before {
  content: "\F29E"; }

.ms-Icon--RedEye::before {
  content: "\E7B3"; }

.ms-Icon--Redo::before {
  content: "\E7A6"; }

.ms-Icon--Refresh::before {
  content: "\E72C"; }

.ms-Icon--ReminderGroup::before {
  content: "\EBF8"; }

.ms-Icon--ReminderPerson::before {
  content: "\EBF7"; }

.ms-Icon--Remote::before {
  content: "\E8AF"; }

.ms-Icon--Remove::before {
  content: "\E738"; }

.ms-Icon--RemoveEvent::before {
  content: "\ED8A"; }

.ms-Icon--RemoveFilter::before {
  content: "\EB08"; }

.ms-Icon--RemoveLink::before {
  content: "\ED90"; }

.ms-Icon--RemoveOccurrence::before {
  content: "\ED9B"; }

.ms-Icon--Rename::before {
  content: "\E8AC"; }

.ms-Icon--RenewalCurrent::before {
  content: "\F545"; }

.ms-Icon--RenewalFuture::before {
  content: "\F546"; }

.ms-Icon--ReopenPages::before {
  content: "\ED50"; }

.ms-Icon--Repair::before {
  content: "\E90F"; }

.ms-Icon--ReplyAllAlt::before {
  content: "\EF5F"; }

[dir='ltr'] .ms-Icon--ReplyAll::before {
  content: "\EE0A"; }

[dir='rtl'] .ms-Icon--ReplyAll::before {
  content: "\EE36"; }

[dir='ltr'] .ms-Icon--ReplyAllMirrored::before {
  content: "\EE36"; }

[dir='rtl'] .ms-Icon--ReplyAllMirrored::before {
  content: "\EE0A"; }

.ms-Icon--ReplyAlt::before {
  content: "\EF5E"; }

[dir='ltr'] .ms-Icon--Reply::before {
  content: "\E97A"; }

[dir='rtl'] .ms-Icon--Reply::before {
  content: "\EE35"; }

[dir='ltr'] .ms-Icon--ReplyMirrored::before {
  content: "\EE35"; }

[dir='rtl'] .ms-Icon--ReplyMirrored::before {
  content: "\E97A"; }

.ms-Icon--Repo::before {
  content: "\F2CB"; }

.ms-Icon--ReportAdd::before {
  content: "\F52C"; }

.ms-Icon--ReportHacked::before {
  content: "\E730"; }

[dir='ltr'] .ms-Icon--ReportLibrary::before {
  content: "\EEBB"; }

[dir='rtl'] .ms-Icon--ReportLibrary::before {
  content: "\EEBC"; }

[dir='ltr'] .ms-Icon--ReportLibraryMirrored::before {
  content: "\EEBC"; }

[dir='rtl'] .ms-Icon--ReportLibraryMirrored::before {
  content: "\EEBB"; }

.ms-Icon--RepoSolid::before {
  content: "\F2CC"; }

.ms-Icon--ReturnToSession::before {
  content: "\ED24"; }

[dir='ltr'] .ms-Icon--ReviewRequestSolid::before {
  content: "\F356"; }

[dir='rtl'] .ms-Icon--ReviewRequestSolid::before {
  content: "\F357"; }

[dir='ltr'] .ms-Icon--ReviewRequestMirroredSolid::before {
  content: "\F357"; }

[dir='rtl'] .ms-Icon--ReviewRequestMirroredSolid::before {
  content: "\F356"; }

.ms-Icon--ReviewResponseSolid::before {
  content: "\F358"; }

.ms-Icon--ReviewSolid::before {
  content: "\F355"; }

.ms-Icon--RevToggleKey::before {
  content: "\E845"; }

.ms-Icon--Rewind::before {
  content: "\EB9E"; }

.ms-Icon--Ribbon::before {
  content: "\E9D1"; }

.ms-Icon--RibbonSolid::before {
  content: "\F345"; }

.ms-Icon--RightDoubleQuote::before {
  content: "\E9B1"; }

.ms-Icon--RightTriangle::before {
  content: "\F500"; }

.ms-Icon--Ringer::before {
  content: "\EA8F"; }

.ms-Icon--RingerOff::before {
  content: "\F2C5"; }

.ms-Icon--RingerRemove::before {
  content: "\F279"; }

.ms-Icon--Robot::before {
  content: "\E99A"; }

.ms-Icon--Rocket::before {
  content: "\F3B3"; }

.ms-Icon--Room::before {
  content: "\ED9F"; }

.ms-Icon--Rotate::before {
  content: "\E7AD"; }

.ms-Icon--RowsChild::before {
  content: "\F29C"; }

.ms-Icon--RowsGroup::before {
  content: "\F29B"; }

.ms-Icon--Rugby::before {
  content: "\EB2D"; }

.ms-Icon--Running::before {
  content: "\EADA"; }

.ms-Icon--Sad::before {
  content: "\E757"; }

.ms-Icon--SadSolid::before {
  content: "\F33E"; }

.ms-Icon--Save::before {
  content: "\E74E"; }

.ms-Icon--SaveAll::before {
  content: "\F203"; }

.ms-Icon--SaveAndClose::before {
  content: "\F038"; }

.ms-Icon--SaveAs::before {
  content: "\E792"; }

.ms-Icon--Savings::before {
  content: "\EB0B"; }

.ms-Icon--ScaleUp::before {
  content: "\ED09"; }

.ms-Icon--ScheduleEventAction::before {
  content: "\F1EF"; }

.ms-Icon--ScopeTemplate::before {
  content: "\F2B0"; }

.ms-Icon--Script::before {
  content: "\F03A"; }

.ms-Icon--ScrollUpDown::before {
  content: "\EC8F"; }

.ms-Icon--Search::before {
  content: "\E721"; }

.ms-Icon--SearchAndApps::before {
  content: "\E773"; }

.ms-Icon--SearchBookmark::before {
  content: "\F5B8"; }

.ms-Icon--SearchCalendar::before {
  content: "\F4AF"; }

[dir='ltr'] .ms-Icon--SearchIssue::before {
  content: "\F09A"; }

[dir='rtl'] .ms-Icon--SearchIssue::before {
  content: "\F09B"; }

[dir='ltr'] .ms-Icon--SearchIssueMirrored::before {
  content: "\F09B"; }

[dir='rtl'] .ms-Icon--SearchIssueMirrored::before {
  content: "\F09A"; }

.ms-Icon--Section::before {
  content: "\EC0C"; }

.ms-Icon--Sections::before {
  content: "\EF76"; }

.ms-Icon--SecurityGroup::before {
  content: "\ED85"; }

.ms-Icon--SelectAll::before {
  content: "\E8B3"; }

.ms-Icon--Sell::before {
  content: "\EB0C"; }

.ms-Icon--SemiboldWeight::before {
  content: "\F4F0"; }

[dir='ltr'] .ms-Icon--Send::before {
  content: "\E724"; }

[dir='rtl'] .ms-Icon--Send::before {
  content: "\EA63"; }

[dir='ltr'] .ms-Icon--SendMirrored::before {
  content: "\EA63"; }

[dir='rtl'] .ms-Icon--SendMirrored::before {
  content: "\E724"; }

.ms-Icon--Separator::before {
  content: "\F35E"; }

.ms-Icon--Server::before {
  content: "\F201"; }

.ms-Icon--ServerEnviroment::before {
  content: "\F29F"; }

.ms-Icon--ServerProcesses::before {
  content: "\F1FE"; }

.ms-Icon--SetAction::before {
  content: "\F071"; }

.ms-Icon--Settings::before {
  content: "\E713"; }

.ms-Icon--Share::before {
  content: "\E72D"; }

.ms-Icon--ShareiOS::before {
  content: "\EF79"; }

.ms-Icon--SharepointLogo::before {
  content: "\F27E"; }

.ms-Icon--SharepointLogoFill::before {
  content: "\F27F"; }

.ms-Icon--SharepointLogoInverse::before {
  content: "\ED18"; }

.ms-Icon--Shield::before {
  content: "\EA18"; }

.ms-Icon--ShieldSolid::before {
  content: "\F340"; }

.ms-Icon--Shop::before {
  content: "\E719"; }

.ms-Icon--ShoppingCart::before {
  content: "\E7BF"; }

.ms-Icon--ShoppingCartSolid::before {
  content: "\F342"; }

.ms-Icon--ShopServer::before {
  content: "\F2B6"; }

[dir='ltr'] .ms-Icon--ShowResults::before {
  content: "\E8BC"; }

[dir='rtl'] .ms-Icon--ShowResults::before {
  content: "\EA65"; }

[dir='ltr'] .ms-Icon--ShowResultsMirrored::before {
  content: "\EA65"; }

[dir='rtl'] .ms-Icon--ShowResultsMirrored::before {
  content: "\E8BC"; }

[dir='ltr'] .ms-Icon--SidePanel::before {
  content: "\EF52"; }

[dir='rtl'] .ms-Icon--SidePanel::before {
  content: "\F221"; }

[dir='ltr'] .ms-Icon--SidePanelMirrored::before {
  content: "\F221"; }

[dir='rtl'] .ms-Icon--SidePanelMirrored::before {
  content: "\EF52"; }

.ms-Icon--SignOut::before {
  content: "\F3B1"; }

.ms-Icon--SingleBookmark::before {
  content: "\EDFF"; }

.ms-Icon--SingleBookmarkSolid::before {
  content: "\EE00"; }

.ms-Icon--SingleColumn::before {
  content: "\F1D3"; }

.ms-Icon--SingleColumnEdit::before {
  content: "\F321"; }

.ms-Icon--SIPMove::before {
  content: "\E759"; }

.ms-Icon--SiteScan::before {
  content: "\EBEC"; }

.ms-Icon--SizeLegacy::before {
  content: "\E2B2"; }

.ms-Icon--SkiResorts::before {
  content: "\EB45"; }

.ms-Icon--SkypeCheck::before {
  content: "\EF80"; }

.ms-Icon--SkypeCircleCheck::before {
  content: "\EF7D"; }

.ms-Icon--SkypeCircleClock::before {
  content: "\EF7E"; }

.ms-Icon--SkypeCircleMinus::before {
  content: "\EF7F"; }

.ms-Icon--SkypeClock::before {
  content: "\EF81"; }

.ms-Icon--SkypeForBusinessLogo::before {
  content: "\F0FC"; }

.ms-Icon--SkypeForBusinessLogo16::before {
  content: "\F40F"; }

.ms-Icon--SkypeForBusinessLogoFill::before {
  content: "\F27D"; }

.ms-Icon--SkypeForBusinessLogoFill16::before {
  content: "\F410"; }

.ms-Icon--SkypeLogo::before {
  content: "\EB6F"; }

.ms-Icon--SkypeLogo16::before {
  content: "\F40E"; }

.ms-Icon--SkypeMessage::before {
  content: "\EF83"; }

.ms-Icon--SkypeMinus::before {
  content: "\EF82"; }

.ms-Icon--Slider::before {
  content: "\F527"; }

.ms-Icon--SliderHandleSize::before {
  content: "\F528"; }

.ms-Icon--SliderThumb::before {
  content: "\EC13"; }

.ms-Icon--Snooze::before {
  content: "\F4BD"; }

.ms-Icon--Snow::before {
  content: "\E9C8"; }

.ms-Icon--Snowflake::before {
  content: "\EB46"; }

.ms-Icon--SnowShowerDay::before {
  content: "\E9FD"; }

.ms-Icon--SnowShowerNight::before {
  content: "\EA11"; }

.ms-Icon--Soccer::before {
  content: "\EB21"; }

.ms-Icon--SocialListeningLogo::before {
  content: "\ED7C"; }

.ms-Icon--Sort::before {
  content: "\E8CB"; }

.ms-Icon--SortDown::before {
  content: "\EE69"; }

.ms-Icon--SortLines::before {
  content: "\E9D0"; }

.ms-Icon--SortUp::before {
  content: "\EE68"; }

.ms-Icon--Source::before {
  content: "\EB1B"; }

.ms-Icon--Spacer::before {
  content: "\F40D"; }

.ms-Icon--Speakers::before {
  content: "\E7F5"; }

.ms-Icon--SpeedHigh::before {
  content: "\EC4A"; }

.ms-Icon--Split::before {
  content: "\EDBC"; }

.ms-Icon--SplitObject::before {
  content: "\F547"; }

.ms-Icon--Sprint::before {
  content: "\F3B0"; }

.ms-Icon--Squalls::before {
  content: "\E9CC"; }

.ms-Icon--SquareShapeSolid::before {
  content: "\F63D"; }

.ms-Icon--Stack::before {
  content: "\F26F"; }

.ms-Icon--StackedBarChart::before {
  content: "\F24D"; }

.ms-Icon--StackedLineChart::before {
  content: "\F24E"; }

.ms-Icon--StackIndicator::before {
  content: "\E7FF"; }

.ms-Icon--StaffNotebookLogo16::before {
  content: "\F48E"; }

.ms-Icon--StaffNotebookLogo32::before {
  content: "\F48C"; }

.ms-Icon--StaffNotebookLogoFill16::before {
  content: "\F48F"; }

.ms-Icon--StaffNotebookLogoFill32::before {
  content: "\F48D"; }

.ms-Icon--StaffNotebookLogoInverted16::before {
  content: "\F491"; }

.ms-Icon--StaffNotebookLogoInverted32::before {
  content: "\F490"; }

.ms-Icon--Starburst::before {
  content: "\EF78"; }

.ms-Icon--StarburstSolid::before {
  content: "\F33C"; }

.ms-Icon--StatusCircleBlock2::before {
  content: "\F141"; }

.ms-Icon--StatusCircleCheckmark::before {
  content: "\F13E"; }

.ms-Icon--StatusCircleErrorX::before {
  content: "\F13D"; }

.ms-Icon--StatusCircleExclamation::before {
  content: "\F13C"; }

.ms-Icon--StatusCircleInfo::before {
  content: "\F13F"; }

.ms-Icon--StatusCircleInner::before {
  content: "\F137"; }

.ms-Icon--StatusCircleOuter::before {
  content: "\F136"; }

.ms-Icon--StatusCircleQuestionMark::before {
  content: "\F142"; }

.ms-Icon--StatusCircleRing::before {
  content: "\F138"; }

.ms-Icon--StatusErrorFull::before {
  content: "\EB90"; }

.ms-Icon--StatusTriangle::before {
  content: "\EA82"; }

.ms-Icon--StatusTriangleExclamation::before {
  content: "\F13B"; }

.ms-Icon--StatusTriangleInner::before {
  content: "\F13A"; }

.ms-Icon--StatusTriangleOuter::before {
  content: "\F139"; }

.ms-Icon--Step::before {
  content: "\F241"; }

.ms-Icon--StepInsert::before {
  content: "\F242"; }

.ms-Icon--StepShared::before {
  content: "\F243"; }

.ms-Icon--StepSharedAdd::before {
  content: "\F244"; }

.ms-Icon--StepSharedInsert::before {
  content: "\F245"; }

.ms-Icon--StockDown::before {
  content: "\EB0F"; }

.ms-Icon--StockUp::before {
  content: "\EB11"; }

.ms-Icon--Stop::before {
  content: "\E71A"; }

.ms-Icon--StopSolid::before {
  content: "\EE95"; }

.ms-Icon--Stopwatch::before {
  content: "\E916"; }

.ms-Icon--StoreLogo16::before {
  content: "\EA96"; }

.ms-Icon--StoreLogoMed20::before {
  content: "\EA04"; }

.ms-Icon--Storyboard::before {
  content: "\F308"; }

.ms-Icon--Streaming::before {
  content: "\E93E"; }

.ms-Icon--StreamingOff::before {
  content: "\F2BB"; }

.ms-Icon--StreamLogo::before {
  content: "\F329"; }

.ms-Icon--Strikethrough::before {
  content: "\EDE0"; }

.ms-Icon--Subscribe::before {
  content: "\EDA1"; }

.ms-Icon--Subscript::before {
  content: "\EDDF"; }

.ms-Icon--SubstitutionsIn::before {
  content: "\EB31"; }

.ms-Icon--Suitcase::before {
  content: "\EDD3"; }

.ms-Icon--SunAdd::before {
  content: "\EF69"; }

.ms-Icon--Sunny::before {
  content: "\E9BD"; }

.ms-Icon--SunQuestionMark::before {
  content: "\EF6A"; }

.ms-Icon--Superscript::before {
  content: "\EDDE"; }

.ms-Icon--SurveyQuestions::before {
  content: "\F01B"; }

.ms-Icon--SwayLogo16::before {
  content: "\F484"; }

.ms-Icon--SwayLogo32::before {
  content: "\F482"; }

.ms-Icon--SwayLogoFill16::before {
  content: "\F485"; }

.ms-Icon--SwayLogoFill32::before {
  content: "\F483"; }

.ms-Icon--SwayLogoInverse::before {
  content: "\ED29"; }

.ms-Icon--Switch::before {
  content: "\E8AB"; }

.ms-Icon--SwitcherStartEnd::before {
  content: "\E810"; }

.ms-Icon--Sync::before {
  content: "\E895"; }

.ms-Icon--SyncFolder::before {
  content: "\E8F7"; }

.ms-Icon--SyncOccurence::before {
  content: "\F4A3"; }

.ms-Icon--SyncToPC::before {
  content: "\EE6E"; }

.ms-Icon--System::before {
  content: "\E770"; }

.ms-Icon--Tab::before {
  content: "\E7E9"; }

.ms-Icon--Table::before {
  content: "\ED86"; }

.ms-Icon--Tablet::before {
  content: "\E70A"; }

.ms-Icon--TabletMode::before {
  content: "\EBFC"; }

.ms-Icon--TabletSelected::before {
  content: "\EC74"; }

.ms-Icon--Tag::before {
  content: "\E8EC"; }

.ms-Icon--Taskboard::before {
  content: "\F1C2"; }

[dir='ltr'] .ms-Icon--TaskGroup::before {
  content: "\F2AE"; }

[dir='rtl'] .ms-Icon--TaskGroup::before {
  content: "\F2AF"; }

[dir='ltr'] .ms-Icon--TaskGroupMirrored::before {
  content: "\F2AF"; }

[dir='rtl'] .ms-Icon--TaskGroupMirrored::before {
  content: "\F2AE"; }

.ms-Icon--TaskLogo::before {
  content: "\F493"; }

[dir='ltr'] .ms-Icon--TaskManager::before {
  content: "\EDB7"; }

[dir='rtl'] .ms-Icon--TaskManager::before {
  content: "\EDB8"; }

[dir='ltr'] .ms-Icon--TaskManagerMirrored::before {
  content: "\EDB8"; }

[dir='rtl'] .ms-Icon--TaskManagerMirrored::before {
  content: "\EDB7"; }

.ms-Icon--TaskSolid::before {
  content: "\F333"; }

.ms-Icon--Taxi::before {
  content: "\F4A1"; }

.ms-Icon--TeamFavorite::before {
  content: "\F2AD"; }

.ms-Icon--TeamsLogo::before {
  content: "\F27B"; }

.ms-Icon--TeamsLogoFill::before {
  content: "\F27C"; }

.ms-Icon--TeamsLogoInverse::before {
  content: "\F27A"; }

.ms-Icon--Teamwork::before {
  content: "\EA12"; }

.ms-Icon--Teeth::before {
  content: "\F4A0"; }

.ms-Icon--TemporaryUser::before {
  content: "\EE58"; }

.ms-Icon--Tennis::before {
  content: "\EB33"; }

.ms-Icon--TestAutoSolid::before {
  content: "\F3A8"; }

.ms-Icon--TestBeaker::before {
  content: "\F3A5"; }

.ms-Icon--TestBeakerSolid::before {
  content: "\F3A6"; }

.ms-Icon--TestCase::before {
  content: "\F3AF"; }

.ms-Icon--TestExploreSolid::before {
  content: "\F3A7"; }

.ms-Icon--TestImpactSolid::before {
  content: "\F3AA"; }

.ms-Icon--TestParameter::before {
  content: "\F3AD"; }

.ms-Icon--TestPlan::before {
  content: "\F3AB"; }

.ms-Icon--TestStep::before {
  content: "\F3AC"; }

.ms-Icon--TestSuite::before {
  content: "\F3AE"; }

.ms-Icon--TestUserSolid::before {
  content: "\F3A9"; }

.ms-Icon--TextBox::before {
  content: "\EDC2"; }

.ms-Icon--TextCallout::before {
  content: "\F2A2"; }

.ms-Icon--TextDocument::before {
  content: "\F029"; }

.ms-Icon--TextDocumentShared::before {
  content: "\F02B"; }

.ms-Icon--TextField::before {
  content: "\EDC3"; }

.ms-Icon--TextOverflow::before {
  content: "\F51F"; }

.ms-Icon--TFVCLogo::before {
  content: "\F44D"; }

.ms-Icon--ThisPC::before {
  content: "\EC4E"; }

.ms-Icon--ThreeQuarterCircle::before {
  content: "\F503"; }

[dir='ltr'] .ms-Icon--ThumbnailView::before {
  content: "\E7B6"; }

[dir='rtl'] .ms-Icon--ThumbnailView::before {
  content: "\EA67"; }

[dir='ltr'] .ms-Icon--ThumbnailViewMirrored::before {
  content: "\EA67"; }

[dir='rtl'] .ms-Icon--ThumbnailViewMirrored::before {
  content: "\E7B6"; }

.ms-Icon--Thunderstorms::before {
  content: "\E9C6"; }

.ms-Icon--Ticket::before {
  content: "\EB54"; }

.ms-Icon--Tiles::before {
  content: "\ECA5"; }

.ms-Icon--Tiles2::before {
  content: "\EF7C"; }

.ms-Icon--TimeEntry::before {
  content: "\EF95"; }

.ms-Icon--Timeline::before {
  content: "\ED9C"; }

.ms-Icon--TimelineDelivery::before {
  content: "\F2AB"; }

.ms-Icon--TimelineMatrixView::before {
  content: "\F361"; }

.ms-Icon--TimelineProgress::before {
  content: "\F2AA"; }

.ms-Icon--Timer::before {
  content: "\E91E"; }

.ms-Icon--TimeSheet::before {
  content: "\EA05"; }

.ms-Icon--ToDoLogoBottom::before {
  content: "\F4B3"; }

.ms-Icon--ToDoLogoInverse::before {
  content: "\F4BC"; }

.ms-Icon--ToDoLogoTop::before {
  content: "\F4B4"; }

.ms-Icon--ToggleBorder::before {
  content: "\EC12"; }

.ms-Icon--ToggleFilled::before {
  content: "\EC11"; }

.ms-Icon--ToggleOff::before {
  content: "\F19F"; }

.ms-Icon--ToggleThumb::before {
  content: "\EC14"; }

.ms-Icon--Toll::before {
  content: "\F160"; }

.ms-Icon--Touch::before {
  content: "\E815"; }

.ms-Icon--TouchPointer::before {
  content: "\E7C9"; }

[dir='ltr'] .ms-Icon--Trackers::before {
  content: "\EADF"; }

[dir='rtl'] .ms-Icon--Trackers::before {
  content: "\EE92"; }

[dir='ltr'] .ms-Icon--TrackersMirrored::before {
  content: "\EE92"; }

[dir='rtl'] .ms-Icon--TrackersMirrored::before {
  content: "\EADF"; }

.ms-Icon--Train::before {
  content: "\E7C0"; }

.ms-Icon--TrainSolid::before {
  content: "\EB4D"; }

.ms-Icon--TransferCall::before {
  content: "\ED95"; }

.ms-Icon--Transition::before {
  content: "\F3BC"; }

.ms-Icon--TransitionEffect::before {
  content: "\F5B4"; }

.ms-Icon--TransitionPop::before {
  content: "\F5B2"; }

.ms-Icon--TransitionPush::before {
  content: "\F5B3"; }

.ms-Icon--Trending12::before {
  content: "\F62D"; }

.ms-Icon--TriangleDown12::before {
  content: "\EED1"; }

.ms-Icon--TriangleLeft12::before {
  content: "\EED2"; }

.ms-Icon--TriangleRight12::before {
  content: "\EED3"; }

.ms-Icon--TriangleShapeSolid::before {
  content: "\F63E"; }

.ms-Icon--TriangleSolid::before {
  content: "\EA08"; }

.ms-Icon--TriangleSolidDown12::before {
  content: "\EECD"; }

.ms-Icon--TriangleSolidLeft12::before {
  content: "\EECE"; }

.ms-Icon--TriangleSolidRight12::before {
  content: "\EECF"; }

.ms-Icon--TriangleSolidUp12::before {
  content: "\EECC"; }

.ms-Icon--TriangleUp12::before {
  content: "\EED0"; }

.ms-Icon--TriggerApproval::before {
  content: "\F3B2"; }

.ms-Icon--TriggerAuto::before {
  content: "\F24A"; }

.ms-Icon--TriggerUser::before {
  content: "\F24B"; }

.ms-Icon--TripleColumn::before {
  content: "\F1D5"; }

.ms-Icon--TripleColumnEdit::before {
  content: "\F323"; }

.ms-Icon--Trophy::before {
  content: "\ED3F"; }

.ms-Icon--Trophy2::before {
  content: "\F1AE"; }

.ms-Icon--Trophy2Solid::before {
  content: "\F337"; }

.ms-Icon--TurnRight::before {
  content: "\E7DB"; }

.ms-Icon--TVMonitor::before {
  content: "\E7F4"; }

.ms-Icon--TVMonitorSelected::before {
  content: "\EC77"; }

.ms-Icon--TypeScriptLanguage::before {
  content: "\F2F7"; }

.ms-Icon--Umbrella::before {
  content: "\EC04"; }

.ms-Icon--Underline::before {
  content: "\E8DC"; }

.ms-Icon--Undo::before {
  content: "\E7A7"; }

[dir='ltr'] .ms-Icon--Uneditable::before {
  content: "\ED1D"; }

[dir='rtl'] .ms-Icon--Uneditable::before {
  content: "\F4B9"; }

[dir='ltr'] .ms-Icon--UneditableMirrored::before {
  content: "\F4B9"; }

[dir='rtl'] .ms-Icon--UneditableMirrored::before {
  content: "\ED1D"; }

[dir='ltr'] .ms-Icon--UneditableSolid12::before {
  content: "\F4B7"; }

[dir='rtl'] .ms-Icon--UneditableSolid12::before {
  content: "\F4B8"; }

[dir='ltr'] .ms-Icon--UneditableSolidMirrored12::before {
  content: "\F4B8"; }

[dir='rtl'] .ms-Icon--UneditableSolidMirrored12::before {
  content: "\F4B7"; }

.ms-Icon--Unfavorite::before {
  content: "\E8D9"; }

.ms-Icon--UngroupObject::before {
  content: "\F4F2"; }

.ms-Icon--UnknownCall::before {
  content: "\ED97"; }

[dir='ltr'] .ms-Icon--Unknown::before {
  content: "\E9CE"; }

[dir='rtl'] .ms-Icon--Unknown::before {
  content: "\F22E"; }

[dir='ltr'] .ms-Icon--UnknownMirrored::before {
  content: "\F22E"; }

[dir='rtl'] .ms-Icon--UnknownMirrored::before {
  content: "\E9CE"; }

[dir='ltr'] .ms-Icon--UnknownSolid::before {
  content: "\F2E1"; }

[dir='rtl'] .ms-Icon--UnknownSolid::before {
  content: "\F2E2"; }

[dir='ltr'] .ms-Icon--UnknownMirroredSolid::before {
  content: "\F2E2"; }

[dir='rtl'] .ms-Icon--UnknownMirroredSolid::before {
  content: "\F2E1"; }

.ms-Icon--Unlock::before {
  content: "\E785"; }

.ms-Icon--UnlockSolid::before {
  content: "\F304"; }

.ms-Icon--Unpin::before {
  content: "\E77A"; }

.ms-Icon--Unsubscribe::before {
  content: "\EDA0"; }

.ms-Icon--UnsyncFolder::before {
  content: "\E8F6"; }

.ms-Icon--UnsyncOccurence::before {
  content: "\F4A4"; }

.ms-Icon--Up::before {
  content: "\E74A"; }

.ms-Icon--Upload::before {
  content: "\E898"; }

.ms-Icon--UserEvent::before {
  content: "\F69C"; }

.ms-Icon--UserFollowed::before {
  content: "\F25C"; }

.ms-Icon--UserPause::before {
  content: "\F2BA"; }

.ms-Icon--UserRemove::before {
  content: "\F69B"; }

.ms-Icon--UserSync::before {
  content: "\F2B9"; }

.ms-Icon--Vacation::before {
  content: "\F49F"; }

.ms-Icon--Variable::before {
  content: "\F305"; }

.ms-Icon--VariableGroup::before {
  content: "\F31B"; }

.ms-Icon--VB::before {
  content: "\F2F2"; }

.ms-Icon--VennDiagram::before {
  content: "\F273"; }

.ms-Icon--VersionControlPush::before {
  content: "\F664"; }

.ms-Icon--VerticalDistributeCenter::before {
  content: "\F4FA"; }

.ms-Icon--Video::before {
  content: "\E714"; }

.ms-Icon--VideoOff::before {
  content: "\F4B0"; }

.ms-Icon--VideoSearch::before {
  content: "\F4EA"; }

.ms-Icon--VideoSolid::before {
  content: "\EA0C"; }

.ms-Icon--View::before {
  content: "\E890"; }

.ms-Icon--ViewAll::before {
  content: "\E8A9"; }

.ms-Icon--ViewAll2::before {
  content: "\EF56"; }

.ms-Icon--ViewDashboard::before {
  content: "\F246"; }

.ms-Icon--ViewList::before {
  content: "\F247"; }

.ms-Icon--ViewListGroup::before {
  content: "\F248"; }

.ms-Icon--ViewListTree::before {
  content: "\F249"; }

.ms-Icon--VisioDiagram::before {
  content: "\F2A0"; }

.ms-Icon--VisioDocument::before {
  content: "\F2A9"; }

.ms-Icon--VisioLogo::before {
  content: "\F2A7"; }

.ms-Icon--VisioLogo16::before {
  content: "\F3A3"; }

.ms-Icon--VisioLogoFill::before {
  content: "\F2A8"; }

.ms-Icon--VisioLogoFill16::before {
  content: "\F3A4"; }

.ms-Icon--VisioLogoInverse::before {
  content: "\ED7D"; }

.ms-Icon--VisioLogoInverse16::before {
  content: "\F3A2"; }

.ms-Icon--VisualBasicLanguage::before {
  content: "\F2F1"; }

.ms-Icon--VisualsFolder::before {
  content: "\F520"; }

.ms-Icon--VisualsStore::before {
  content: "\F521"; }

.ms-Icon--VisualStudioIDELogo32::before {
  content: "\F5D0"; }

.ms-Icon--VisualStudioLogo::before {
  content: "\EC22"; }

.ms-Icon--VoicemailForward::before {
  content: "\ED87"; }

.ms-Icon--VoicemailIRM::before {
  content: "\F421"; }

.ms-Icon--VoicemailReply::before {
  content: "\ED88"; }

.ms-Icon--Volume0::before {
  content: "\E992"; }

.ms-Icon--Volume1::before {
  content: "\E993"; }

.ms-Icon--Volume2::before {
  content: "\E994"; }

.ms-Icon--Volume3::before {
  content: "\E995"; }

.ms-Icon--VolumeDisabled::before {
  content: "\EA85"; }

.ms-Icon--VSTSAltLogo1::before {
  content: "\F382"; }

.ms-Icon--VSTSAltLogo2::before {
  content: "\F383"; }

.ms-Icon--VSTSLogo::before {
  content: "\F381"; }

.ms-Icon--Waffle::before {
  content: "\ED89"; }

.ms-Icon--WaffleOffice365::before {
  content: "\F4E0"; }

[dir='ltr'] .ms-Icon--WaitlistConfirm::before {
  content: "\F550"; }

[dir='rtl'] .ms-Icon--WaitlistConfirm::before {
  content: "\F551"; }

[dir='ltr'] .ms-Icon--WaitlistConfirmMirrored::before {
  content: "\F551"; }

[dir='rtl'] .ms-Icon--WaitlistConfirmMirrored::before {
  content: "\F550"; }

.ms-Icon--Warning::before {
  content: "\E7BA"; }

.ms-Icon--Warning12::before {
  content: "\F62F"; }

.ms-Icon--WebPublish::before {
  content: "\F52F"; }

.ms-Icon--Website::before {
  content: "\EB41"; }

.ms-Icon--Weights::before {
  content: "\EADB"; }

.ms-Icon--WhiteBoardApp16::before {
  content: "\F673"; }

.ms-Icon--WhiteBoardApp32::before {
  content: "\F674"; }

.ms-Icon--WifiEthernet::before {
  content: "\EE77"; }

.ms-Icon--WindDirection::before {
  content: "\EBE6"; }

.ms-Icon--WindowEdit::before {
  content: "\F50E"; }

.ms-Icon--WindowsLogo::before {
  content: "\E782"; }

.ms-Icon--Wines::before {
  content: "\EABF"; }

.ms-Icon--WipePhone::before {
  content: "\ED8D"; }

.ms-Icon--WordDocument::before {
  content: "\EF71"; }

.ms-Icon--WordLogo::before {
  content: "\F1E3"; }

.ms-Icon--WordLogo16::before {
  content: "\F391"; }

.ms-Icon--WordLogoFill::before {
  content: "\F1E4"; }

.ms-Icon--WordLogoFill16::before {
  content: "\F392"; }

.ms-Icon--WordLogoInverse::before {
  content: "\EC29"; }

.ms-Icon--WordLogoInverse16::before {
  content: "\F390"; }

.ms-Icon--Work::before {
  content: "\E821"; }

.ms-Icon--WorkFlow::before {
  content: "\EA01"; }

.ms-Icon--WorkforceManagement::before {
  content: "\EE0F"; }

.ms-Icon--WorkItem::before {
  content: "\F314"; }

.ms-Icon--WorkItemBar::before {
  content: "\F35C"; }

.ms-Icon--WorkItemBarSolid::before {
  content: "\F35D"; }

.ms-Icon--WorkItemBug::before {
  content: "\F315"; }

.ms-Icon--World::before {
  content: "\E909"; }

.ms-Icon--WorldClock::before {
  content: "\E918"; }

.ms-Icon--YammerLogo::before {
  content: "\ED19"; }

.ms-Icon--ZipFolder::before {
  content: "\F012"; }

.ms-Icon--Zoom::before {
  content: "\E71E"; }

.ms-Icon--ZoomIn::before {
  content: "\E8A3"; }

.ms-Icon--ZoomOut::before {
  content: "\E71F"; }

.ms-Icon--HumanResources::before {
  content: "\F240"; }

.ms-Icon--StoreLogo::before {
  content: "\EA96"; }

.ms-Icon--StoreLogoMed::before {
  content: "\EA04"; }

@media (max-width: 479.99999px) {
  .ms-hiddenSm,
  .ms-hiddenMdDown,
  .ms-hiddenLgDown,
  .ms-hiddenXlDown,
  .ms-hiddenXxlDown {
    display: none !important; } }

@media (min-width: 480px) and (max-width: 639.99999px) {
  .ms-hiddenMd,
  .ms-hiddenMdUp,
  .ms-hiddenMdDown,
  .ms-hiddenLgDown,
  .ms-hiddenXlDown,
  .ms-hiddenXxlDown {
    display: none !important; } }

@media (min-width: 640px) and (max-width: 1023.99999px) {
  .ms-hiddenMdUp,
  .ms-hiddenLg,
  .ms-hiddenLgUp,
  .ms-hiddenLgDown,
  .ms-hiddenXlDown,
  .ms-hiddenXxlDown {
    display: none !important; } }

@media (min-width: 1024px) and (max-width: 1365.99999px) {
  .ms-hiddenMdUp,
  .ms-hiddenLgUp,
  .ms-hiddenXl,
  .ms-hiddenXlUp,
  .ms-hiddenXlDown,
  .ms-hiddenXxlDown {
    display: none !important; } }

@media (min-width: 1366px) and (max-width: 1919.99999px) {
  .ms-hiddenMdUp,
  .ms-hiddenLgUp,
  .ms-hiddenXlUp,
  .ms-hiddenXxl,
  .ms-hiddenXxlUp,
  .ms-hiddenXxlDown {
    display: none !important; } }

@media (min-width: 1920px) {
  .ms-hiddenMdUp,
  .ms-hiddenLgUp,
  .ms-hiddenXlUp,
  .ms-hiddenXxlUp,
  .ms-hiddenXxxl {
    display: none !important; } }

.ms-sm12 {
  width: 100%; }

.ms-sm11 {
  width: 91.66666666666666%; }

.ms-sm10 {
  width: 83.33333333333334%; }

.ms-sm9 {
  width: 75%; }

.ms-sm8 {
  width: 66.66666666666666%; }

.ms-sm7 {
  width: 58.333333333333336%; }

.ms-sm6 {
  width: 50%; }

.ms-sm5 {
  width: 41.66666666666667%; }

.ms-sm4 {
  width: 33.33333333333333%; }

.ms-sm3 {
  width: 25%; }

.ms-sm2 {
  width: 16.666666666666664%; }

.ms-sm1 {
  width: 8.333333333333332%; }

[dir='ltr'] .ms-smPull12 {
  right: 100%; }

[dir='rtl'] .ms-smPull12 {
  left: 100%; }

[dir='ltr'] .ms-smPull11 {
  right: 91.66667%; }

[dir='rtl'] .ms-smPull11 {
  left: 91.66667%; }

[dir='ltr'] .ms-smPull10 {
  right: 83.33333%; }

[dir='rtl'] .ms-smPull10 {
  left: 83.33333%; }

[dir='ltr'] .ms-smPull9 {
  right: 75%; }

[dir='rtl'] .ms-smPull9 {
  left: 75%; }

[dir='ltr'] .ms-smPull8 {
  right: 66.66667%; }

[dir='rtl'] .ms-smPull8 {
  left: 66.66667%; }

[dir='ltr'] .ms-smPull7 {
  right: 58.33333%; }

[dir='rtl'] .ms-smPull7 {
  left: 58.33333%; }

[dir='ltr'] .ms-smPull6 {
  right: 50%; }

[dir='rtl'] .ms-smPull6 {
  left: 50%; }

[dir='ltr'] .ms-smPull5 {
  right: 41.66667%; }

[dir='rtl'] .ms-smPull5 {
  left: 41.66667%; }

[dir='ltr'] .ms-smPull4 {
  right: 33.33333%; }

[dir='rtl'] .ms-smPull4 {
  left: 33.33333%; }

[dir='ltr'] .ms-smPull3 {
  right: 25%; }

[dir='rtl'] .ms-smPull3 {
  left: 25%; }

[dir='ltr'] .ms-smPull2 {
  right: 16.66667%; }

[dir='rtl'] .ms-smPull2 {
  left: 16.66667%; }

[dir='ltr'] .ms-smPull1 {
  right: 8.33333%; }

[dir='rtl'] .ms-smPull1 {
  left: 8.33333%; }

[dir='ltr'] .ms-smPull0 {
  right: auto; }

[dir='rtl'] .ms-smPull0 {
  left: auto; }

[dir='ltr'] .ms-smPush12 {
  left: 100%; }

[dir='rtl'] .ms-smPush12 {
  right: 100%; }

[dir='ltr'] .ms-smPush11 {
  left: 91.66667%; }

[dir='rtl'] .ms-smPush11 {
  right: 91.66667%; }

[dir='ltr'] .ms-smPush10 {
  left: 83.33333%; }

[dir='rtl'] .ms-smPush10 {
  right: 83.33333%; }

[dir='ltr'] .ms-smPush9 {
  left: 75%; }

[dir='rtl'] .ms-smPush9 {
  right: 75%; }

[dir='ltr'] .ms-smPush8 {
  left: 66.66667%; }

[dir='rtl'] .ms-smPush8 {
  right: 66.66667%; }

[dir='ltr'] .ms-smPush7 {
  left: 58.33333%; }

[dir='rtl'] .ms-smPush7 {
  right: 58.33333%; }

[dir='ltr'] .ms-smPush6 {
  left: 50%; }

[dir='rtl'] .ms-smPush6 {
  right: 50%; }

[dir='ltr'] .ms-smPush5 {
  left: 41.66667%; }

[dir='rtl'] .ms-smPush5 {
  right: 41.66667%; }

[dir='ltr'] .ms-smPush4 {
  left: 33.33333%; }

[dir='rtl'] .ms-smPush4 {
  right: 33.33333%; }

[dir='ltr'] .ms-smPush3 {
  left: 25%; }

[dir='rtl'] .ms-smPush3 {
  right: 25%; }

[dir='ltr'] .ms-smPush2 {
  left: 16.66667%; }

[dir='rtl'] .ms-smPush2 {
  right: 16.66667%; }

[dir='ltr'] .ms-smPush1 {
  left: 8.33333%; }

[dir='rtl'] .ms-smPush1 {
  right: 8.33333%; }

[dir='ltr'] .ms-smPush0 {
  left: auto; }

[dir='rtl'] .ms-smPush0 {
  right: auto; }

[dir='ltr'] .ms-smOffset11 {
  margin-left: 91.66667%; }

[dir='rtl'] .ms-smOffset11 {
  margin-right: 91.66667%; }

[dir='ltr'] .ms-smOffset10 {
  margin-left: 83.33333%; }

[dir='rtl'] .ms-smOffset10 {
  margin-right: 83.33333%; }

[dir='ltr'] .ms-smOffset9 {
  margin-left: 75%; }

[dir='rtl'] .ms-smOffset9 {
  margin-right: 75%; }

[dir='ltr'] .ms-smOffset8 {
  margin-left: 66.66667%; }

[dir='rtl'] .ms-smOffset8 {
  margin-right: 66.66667%; }

[dir='ltr'] .ms-smOffset7 {
  margin-left: 58.33333%; }

[dir='rtl'] .ms-smOffset7 {
  margin-right: 58.33333%; }

[dir='ltr'] .ms-smOffset6 {
  margin-left: 50%; }

[dir='rtl'] .ms-smOffset6 {
  margin-right: 50%; }

[dir='ltr'] .ms-smOffset5 {
  margin-left: 41.66667%; }

[dir='rtl'] .ms-smOffset5 {
  margin-right: 41.66667%; }

[dir='ltr'] .ms-smOffset4 {
  margin-left: 33.33333%; }

[dir='rtl'] .ms-smOffset4 {
  margin-right: 33.33333%; }

[dir='ltr'] .ms-smOffset3 {
  margin-left: 25%; }

[dir='rtl'] .ms-smOffset3 {
  margin-right: 25%; }

[dir='ltr'] .ms-smOffset2 {
  margin-left: 16.66667%; }

[dir='rtl'] .ms-smOffset2 {
  margin-right: 16.66667%; }

[dir='ltr'] .ms-smOffset1 {
  margin-left: 8.33333%; }

[dir='rtl'] .ms-smOffset1 {
  margin-right: 8.33333%; }

[dir='ltr'] .ms-smOffset0 {
  margin-left: 0; }

[dir='rtl'] .ms-smOffset0 {
  margin-right: 0; }

@media (min-width: 480px) {
  .ms-md12 {
    width: 100%; } }

@media (min-width: 480px) {
  .ms-md11 {
    width: 91.66666666666666%; } }

@media (min-width: 480px) {
  .ms-md10 {
    width: 83.33333333333334%; } }

@media (min-width: 480px) {
  .ms-md9 {
    width: 75%; } }

@media (min-width: 480px) {
  .ms-md8 {
    width: 66.66666666666666%; } }

@media (min-width: 480px) {
  .ms-md7 {
    width: 58.333333333333336%; } }

@media (min-width: 480px) {
  .ms-md6 {
    width: 50%; } }

@media (min-width: 480px) {
  .ms-md5 {
    width: 41.66666666666667%; } }

@media (min-width: 480px) {
  .ms-md4 {
    width: 33.33333333333333%; } }

@media (min-width: 480px) {
  .ms-md3 {
    width: 25%; } }

@media (min-width: 480px) {
  .ms-md2 {
    width: 16.666666666666664%; } }

@media (min-width: 480px) {
  .ms-md1 {
    width: 8.333333333333332%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull12 {
    right: 100%; }
  [dir='rtl'] .ms-mdPull12 {
    left: 100%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull11 {
    right: 91.66667%; }
  [dir='rtl'] .ms-mdPull11 {
    left: 91.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull10 {
    right: 83.33333%; }
  [dir='rtl'] .ms-mdPull10 {
    left: 83.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull9 {
    right: 75%; }
  [dir='rtl'] .ms-mdPull9 {
    left: 75%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull8 {
    right: 66.66667%; }
  [dir='rtl'] .ms-mdPull8 {
    left: 66.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull7 {
    right: 58.33333%; }
  [dir='rtl'] .ms-mdPull7 {
    left: 58.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull6 {
    right: 50%; }
  [dir='rtl'] .ms-mdPull6 {
    left: 50%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull5 {
    right: 41.66667%; }
  [dir='rtl'] .ms-mdPull5 {
    left: 41.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull4 {
    right: 33.33333%; }
  [dir='rtl'] .ms-mdPull4 {
    left: 33.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull3 {
    right: 25%; }
  [dir='rtl'] .ms-mdPull3 {
    left: 25%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull2 {
    right: 16.66667%; }
  [dir='rtl'] .ms-mdPull2 {
    left: 16.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull1 {
    right: 8.33333%; }
  [dir='rtl'] .ms-mdPull1 {
    left: 8.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPull0 {
    right: auto; }
  [dir='rtl'] .ms-mdPull0 {
    left: auto; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush12 {
    left: 100%; }
  [dir='rtl'] .ms-mdPush12 {
    right: 100%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush11 {
    left: 91.66667%; }
  [dir='rtl'] .ms-mdPush11 {
    right: 91.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush10 {
    left: 83.33333%; }
  [dir='rtl'] .ms-mdPush10 {
    right: 83.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush9 {
    left: 75%; }
  [dir='rtl'] .ms-mdPush9 {
    right: 75%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush8 {
    left: 66.66667%; }
  [dir='rtl'] .ms-mdPush8 {
    right: 66.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush7 {
    left: 58.33333%; }
  [dir='rtl'] .ms-mdPush7 {
    right: 58.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush6 {
    left: 50%; }
  [dir='rtl'] .ms-mdPush6 {
    right: 50%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush5 {
    left: 41.66667%; }
  [dir='rtl'] .ms-mdPush5 {
    right: 41.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush4 {
    left: 33.33333%; }
  [dir='rtl'] .ms-mdPush4 {
    right: 33.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush3 {
    left: 25%; }
  [dir='rtl'] .ms-mdPush3 {
    right: 25%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush2 {
    left: 16.66667%; }
  [dir='rtl'] .ms-mdPush2 {
    right: 16.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush1 {
    left: 8.33333%; }
  [dir='rtl'] .ms-mdPush1 {
    right: 8.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdPush0 {
    left: auto; }
  [dir='rtl'] .ms-mdPush0 {
    right: auto; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset11 {
    margin-left: 91.66667%; }
  [dir='rtl'] .ms-mdOffset11 {
    margin-right: 91.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset10 {
    margin-left: 83.33333%; }
  [dir='rtl'] .ms-mdOffset10 {
    margin-right: 83.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset9 {
    margin-left: 75%; }
  [dir='rtl'] .ms-mdOffset9 {
    margin-right: 75%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset8 {
    margin-left: 66.66667%; }
  [dir='rtl'] .ms-mdOffset8 {
    margin-right: 66.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset7 {
    margin-left: 58.33333%; }
  [dir='rtl'] .ms-mdOffset7 {
    margin-right: 58.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset6 {
    margin-left: 50%; }
  [dir='rtl'] .ms-mdOffset6 {
    margin-right: 50%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset5 {
    margin-left: 41.66667%; }
  [dir='rtl'] .ms-mdOffset5 {
    margin-right: 41.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset4 {
    margin-left: 33.33333%; }
  [dir='rtl'] .ms-mdOffset4 {
    margin-right: 33.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset3 {
    margin-left: 25%; }
  [dir='rtl'] .ms-mdOffset3 {
    margin-right: 25%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset2 {
    margin-left: 16.66667%; }
  [dir='rtl'] .ms-mdOffset2 {
    margin-right: 16.66667%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset1 {
    margin-left: 8.33333%; }
  [dir='rtl'] .ms-mdOffset1 {
    margin-right: 8.33333%; } }

@media (min-width: 480px) {
  [dir='ltr'] .ms-mdOffset0 {
    margin-left: 0; }
  [dir='rtl'] .ms-mdOffset0 {
    margin-right: 0; } }

@media (min-width: 640px) {
  .ms-lg12 {
    width: 100%; } }

@media (min-width: 640px) {
  .ms-lg11 {
    width: 91.66666666666666%; } }

@media (min-width: 640px) {
  .ms-lg10 {
    width: 83.33333333333334%; } }

@media (min-width: 640px) {
  .ms-lg9 {
    width: 75%; } }

@media (min-width: 640px) {
  .ms-lg8 {
    width: 66.66666666666666%; } }

@media (min-width: 640px) {
  .ms-lg7 {
    width: 58.333333333333336%; } }

@media (min-width: 640px) {
  .ms-lg6 {
    width: 50%; } }

@media (min-width: 640px) {
  .ms-lg5 {
    width: 41.66666666666667%; } }

@media (min-width: 640px) {
  .ms-lg4 {
    width: 33.33333333333333%; } }

@media (min-width: 640px) {
  .ms-lg3 {
    width: 25%; } }

@media (min-width: 640px) {
  .ms-lg2 {
    width: 16.666666666666664%; } }

@media (min-width: 640px) {
  .ms-lg1 {
    width: 8.333333333333332%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull12 {
    right: 100%; }
  [dir='rtl'] .ms-lgPull12 {
    left: 100%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull11 {
    right: 91.66667%; }
  [dir='rtl'] .ms-lgPull11 {
    left: 91.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull10 {
    right: 83.33333%; }
  [dir='rtl'] .ms-lgPull10 {
    left: 83.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull9 {
    right: 75%; }
  [dir='rtl'] .ms-lgPull9 {
    left: 75%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull8 {
    right: 66.66667%; }
  [dir='rtl'] .ms-lgPull8 {
    left: 66.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull7 {
    right: 58.33333%; }
  [dir='rtl'] .ms-lgPull7 {
    left: 58.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull6 {
    right: 50%; }
  [dir='rtl'] .ms-lgPull6 {
    left: 50%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull5 {
    right: 41.66667%; }
  [dir='rtl'] .ms-lgPull5 {
    left: 41.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull4 {
    right: 33.33333%; }
  [dir='rtl'] .ms-lgPull4 {
    left: 33.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull3 {
    right: 25%; }
  [dir='rtl'] .ms-lgPull3 {
    left: 25%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull2 {
    right: 16.66667%; }
  [dir='rtl'] .ms-lgPull2 {
    left: 16.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull1 {
    right: 8.33333%; }
  [dir='rtl'] .ms-lgPull1 {
    left: 8.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPull0 {
    right: auto; }
  [dir='rtl'] .ms-lgPull0 {
    left: auto; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush12 {
    left: 100%; }
  [dir='rtl'] .ms-lgPush12 {
    right: 100%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush11 {
    left: 91.66667%; }
  [dir='rtl'] .ms-lgPush11 {
    right: 91.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush10 {
    left: 83.33333%; }
  [dir='rtl'] .ms-lgPush10 {
    right: 83.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush9 {
    left: 75%; }
  [dir='rtl'] .ms-lgPush9 {
    right: 75%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush8 {
    left: 66.66667%; }
  [dir='rtl'] .ms-lgPush8 {
    right: 66.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush7 {
    left: 58.33333%; }
  [dir='rtl'] .ms-lgPush7 {
    right: 58.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush6 {
    left: 50%; }
  [dir='rtl'] .ms-lgPush6 {
    right: 50%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush5 {
    left: 41.66667%; }
  [dir='rtl'] .ms-lgPush5 {
    right: 41.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush4 {
    left: 33.33333%; }
  [dir='rtl'] .ms-lgPush4 {
    right: 33.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush3 {
    left: 25%; }
  [dir='rtl'] .ms-lgPush3 {
    right: 25%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush2 {
    left: 16.66667%; }
  [dir='rtl'] .ms-lgPush2 {
    right: 16.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush1 {
    left: 8.33333%; }
  [dir='rtl'] .ms-lgPush1 {
    right: 8.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgPush0 {
    left: auto; }
  [dir='rtl'] .ms-lgPush0 {
    right: auto; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset11 {
    margin-left: 91.66667%; }
  [dir='rtl'] .ms-lgOffset11 {
    margin-right: 91.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset10 {
    margin-left: 83.33333%; }
  [dir='rtl'] .ms-lgOffset10 {
    margin-right: 83.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset9 {
    margin-left: 75%; }
  [dir='rtl'] .ms-lgOffset9 {
    margin-right: 75%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset8 {
    margin-left: 66.66667%; }
  [dir='rtl'] .ms-lgOffset8 {
    margin-right: 66.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset7 {
    margin-left: 58.33333%; }
  [dir='rtl'] .ms-lgOffset7 {
    margin-right: 58.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset6 {
    margin-left: 50%; }
  [dir='rtl'] .ms-lgOffset6 {
    margin-right: 50%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset5 {
    margin-left: 41.66667%; }
  [dir='rtl'] .ms-lgOffset5 {
    margin-right: 41.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset4 {
    margin-left: 33.33333%; }
  [dir='rtl'] .ms-lgOffset4 {
    margin-right: 33.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset3 {
    margin-left: 25%; }
  [dir='rtl'] .ms-lgOffset3 {
    margin-right: 25%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset2 {
    margin-left: 16.66667%; }
  [dir='rtl'] .ms-lgOffset2 {
    margin-right: 16.66667%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset1 {
    margin-left: 8.33333%; }
  [dir='rtl'] .ms-lgOffset1 {
    margin-right: 8.33333%; } }

@media (min-width: 640px) {
  [dir='ltr'] .ms-lgOffset0 {
    margin-left: 0; }
  [dir='rtl'] .ms-lgOffset0 {
    margin-right: 0; } }

@media (min-width: 1024px) {
  .ms-xl12 {
    width: 100%; } }

@media (min-width: 1024px) {
  .ms-xl11 {
    width: 91.66666666666666%; } }

@media (min-width: 1024px) {
  .ms-xl10 {
    width: 83.33333333333334%; } }

@media (min-width: 1024px) {
  .ms-xl9 {
    width: 75%; } }

@media (min-width: 1024px) {
  .ms-xl8 {
    width: 66.66666666666666%; } }

@media (min-width: 1024px) {
  .ms-xl7 {
    width: 58.333333333333336%; } }

@media (min-width: 1024px) {
  .ms-xl6 {
    width: 50%; } }

@media (min-width: 1024px) {
  .ms-xl5 {
    width: 41.66666666666667%; } }

@media (min-width: 1024px) {
  .ms-xl4 {
    width: 33.33333333333333%; } }

@media (min-width: 1024px) {
  .ms-xl3 {
    width: 25%; } }

@media (min-width: 1024px) {
  .ms-xl2 {
    width: 16.666666666666664%; } }

@media (min-width: 1024px) {
  .ms-xl1 {
    width: 8.333333333333332%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull12 {
    right: 100%; }
  [dir='rtl'] .ms-xlPull12 {
    left: 100%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull11 {
    right: 91.66667%; }
  [dir='rtl'] .ms-xlPull11 {
    left: 91.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull10 {
    right: 83.33333%; }
  [dir='rtl'] .ms-xlPull10 {
    left: 83.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull9 {
    right: 75%; }
  [dir='rtl'] .ms-xlPull9 {
    left: 75%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull8 {
    right: 66.66667%; }
  [dir='rtl'] .ms-xlPull8 {
    left: 66.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull7 {
    right: 58.33333%; }
  [dir='rtl'] .ms-xlPull7 {
    left: 58.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull6 {
    right: 50%; }
  [dir='rtl'] .ms-xlPull6 {
    left: 50%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull5 {
    right: 41.66667%; }
  [dir='rtl'] .ms-xlPull5 {
    left: 41.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull4 {
    right: 33.33333%; }
  [dir='rtl'] .ms-xlPull4 {
    left: 33.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull3 {
    right: 25%; }
  [dir='rtl'] .ms-xlPull3 {
    left: 25%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull2 {
    right: 16.66667%; }
  [dir='rtl'] .ms-xlPull2 {
    left: 16.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull1 {
    right: 8.33333%; }
  [dir='rtl'] .ms-xlPull1 {
    left: 8.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPull0 {
    right: auto; }
  [dir='rtl'] .ms-xlPull0 {
    left: auto; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush12 {
    left: 100%; }
  [dir='rtl'] .ms-xlPush12 {
    right: 100%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush11 {
    left: 91.66667%; }
  [dir='rtl'] .ms-xlPush11 {
    right: 91.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush10 {
    left: 83.33333%; }
  [dir='rtl'] .ms-xlPush10 {
    right: 83.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush9 {
    left: 75%; }
  [dir='rtl'] .ms-xlPush9 {
    right: 75%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush8 {
    left: 66.66667%; }
  [dir='rtl'] .ms-xlPush8 {
    right: 66.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush7 {
    left: 58.33333%; }
  [dir='rtl'] .ms-xlPush7 {
    right: 58.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush6 {
    left: 50%; }
  [dir='rtl'] .ms-xlPush6 {
    right: 50%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush5 {
    left: 41.66667%; }
  [dir='rtl'] .ms-xlPush5 {
    right: 41.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush4 {
    left: 33.33333%; }
  [dir='rtl'] .ms-xlPush4 {
    right: 33.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush3 {
    left: 25%; }
  [dir='rtl'] .ms-xlPush3 {
    right: 25%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush2 {
    left: 16.66667%; }
  [dir='rtl'] .ms-xlPush2 {
    right: 16.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush1 {
    left: 8.33333%; }
  [dir='rtl'] .ms-xlPush1 {
    right: 8.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlPush0 {
    left: auto; }
  [dir='rtl'] .ms-xlPush0 {
    right: auto; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset11 {
    margin-left: 91.66667%; }
  [dir='rtl'] .ms-xlOffset11 {
    margin-right: 91.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset10 {
    margin-left: 83.33333%; }
  [dir='rtl'] .ms-xlOffset10 {
    margin-right: 83.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset9 {
    margin-left: 75%; }
  [dir='rtl'] .ms-xlOffset9 {
    margin-right: 75%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset8 {
    margin-left: 66.66667%; }
  [dir='rtl'] .ms-xlOffset8 {
    margin-right: 66.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset7 {
    margin-left: 58.33333%; }
  [dir='rtl'] .ms-xlOffset7 {
    margin-right: 58.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset6 {
    margin-left: 50%; }
  [dir='rtl'] .ms-xlOffset6 {
    margin-right: 50%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset5 {
    margin-left: 41.66667%; }
  [dir='rtl'] .ms-xlOffset5 {
    margin-right: 41.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset4 {
    margin-left: 33.33333%; }
  [dir='rtl'] .ms-xlOffset4 {
    margin-right: 33.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset3 {
    margin-left: 25%; }
  [dir='rtl'] .ms-xlOffset3 {
    margin-right: 25%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset2 {
    margin-left: 16.66667%; }
  [dir='rtl'] .ms-xlOffset2 {
    margin-right: 16.66667%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset1 {
    margin-left: 8.33333%; }
  [dir='rtl'] .ms-xlOffset1 {
    margin-right: 8.33333%; } }

@media (min-width: 1024px) {
  [dir='ltr'] .ms-xlOffset0 {
    margin-left: 0; }
  [dir='rtl'] .ms-xlOffset0 {
    margin-right: 0; } }

@media (min-width: 1366px) {
  .ms-xxl12 {
    width: 100%; } }

@media (min-width: 1366px) {
  .ms-xxl11 {
    width: 91.66666666666666%; } }

@media (min-width: 1366px) {
  .ms-xxl10 {
    width: 83.33333333333334%; } }

@media (min-width: 1366px) {
  .ms-xxl9 {
    width: 75%; } }

@media (min-width: 1366px) {
  .ms-xxl8 {
    width: 66.66666666666666%; } }

@media (min-width: 1366px) {
  .ms-xxl7 {
    width: 58.333333333333336%; } }

@media (min-width: 1366px) {
  .ms-xxl6 {
    width: 50%; } }

@media (min-width: 1366px) {
  .ms-xxl5 {
    width: 41.66666666666667%; } }

@media (min-width: 1366px) {
  .ms-xxl4 {
    width: 33.33333333333333%; } }

@media (min-width: 1366px) {
  .ms-xxl3 {
    width: 25%; } }

@media (min-width: 1366px) {
  .ms-xxl2 {
    width: 16.666666666666664%; } }

@media (min-width: 1366px) {
  .ms-xxl1 {
    width: 8.333333333333332%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull12 {
    right: 100%; }
  [dir='rtl'] .ms-xxlPull12 {
    left: 100%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull11 {
    right: 91.66667%; }
  [dir='rtl'] .ms-xxlPull11 {
    left: 91.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull10 {
    right: 83.33333%; }
  [dir='rtl'] .ms-xxlPull10 {
    left: 83.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull9 {
    right: 75%; }
  [dir='rtl'] .ms-xxlPull9 {
    left: 75%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull8 {
    right: 66.66667%; }
  [dir='rtl'] .ms-xxlPull8 {
    left: 66.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull7 {
    right: 58.33333%; }
  [dir='rtl'] .ms-xxlPull7 {
    left: 58.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull6 {
    right: 50%; }
  [dir='rtl'] .ms-xxlPull6 {
    left: 50%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull5 {
    right: 41.66667%; }
  [dir='rtl'] .ms-xxlPull5 {
    left: 41.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull4 {
    right: 33.33333%; }
  [dir='rtl'] .ms-xxlPull4 {
    left: 33.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull3 {
    right: 25%; }
  [dir='rtl'] .ms-xxlPull3 {
    left: 25%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull2 {
    right: 16.66667%; }
  [dir='rtl'] .ms-xxlPull2 {
    left: 16.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull1 {
    right: 8.33333%; }
  [dir='rtl'] .ms-xxlPull1 {
    left: 8.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPull0 {
    right: auto; }
  [dir='rtl'] .ms-xxlPull0 {
    left: auto; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush12 {
    left: 100%; }
  [dir='rtl'] .ms-xxlPush12 {
    right: 100%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush11 {
    left: 91.66667%; }
  [dir='rtl'] .ms-xxlPush11 {
    right: 91.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush10 {
    left: 83.33333%; }
  [dir='rtl'] .ms-xxlPush10 {
    right: 83.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush9 {
    left: 75%; }
  [dir='rtl'] .ms-xxlPush9 {
    right: 75%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush8 {
    left: 66.66667%; }
  [dir='rtl'] .ms-xxlPush8 {
    right: 66.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush7 {
    left: 58.33333%; }
  [dir='rtl'] .ms-xxlPush7 {
    right: 58.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush6 {
    left: 50%; }
  [dir='rtl'] .ms-xxlPush6 {
    right: 50%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush5 {
    left: 41.66667%; }
  [dir='rtl'] .ms-xxlPush5 {
    right: 41.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush4 {
    left: 33.33333%; }
  [dir='rtl'] .ms-xxlPush4 {
    right: 33.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush3 {
    left: 25%; }
  [dir='rtl'] .ms-xxlPush3 {
    right: 25%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush2 {
    left: 16.66667%; }
  [dir='rtl'] .ms-xxlPush2 {
    right: 16.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush1 {
    left: 8.33333%; }
  [dir='rtl'] .ms-xxlPush1 {
    right: 8.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlPush0 {
    left: auto; }
  [dir='rtl'] .ms-xxlPush0 {
    right: auto; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset11 {
    margin-left: 91.66667%; }
  [dir='rtl'] .ms-xxlOffset11 {
    margin-right: 91.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset10 {
    margin-left: 83.33333%; }
  [dir='rtl'] .ms-xxlOffset10 {
    margin-right: 83.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset9 {
    margin-left: 75%; }
  [dir='rtl'] .ms-xxlOffset9 {
    margin-right: 75%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset8 {
    margin-left: 66.66667%; }
  [dir='rtl'] .ms-xxlOffset8 {
    margin-right: 66.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset7 {
    margin-left: 58.33333%; }
  [dir='rtl'] .ms-xxlOffset7 {
    margin-right: 58.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset6 {
    margin-left: 50%; }
  [dir='rtl'] .ms-xxlOffset6 {
    margin-right: 50%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset5 {
    margin-left: 41.66667%; }
  [dir='rtl'] .ms-xxlOffset5 {
    margin-right: 41.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset4 {
    margin-left: 33.33333%; }
  [dir='rtl'] .ms-xxlOffset4 {
    margin-right: 33.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset3 {
    margin-left: 25%; }
  [dir='rtl'] .ms-xxlOffset3 {
    margin-right: 25%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset2 {
    margin-left: 16.66667%; }
  [dir='rtl'] .ms-xxlOffset2 {
    margin-right: 16.66667%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset1 {
    margin-left: 8.33333%; }
  [dir='rtl'] .ms-xxlOffset1 {
    margin-right: 8.33333%; } }

@media (min-width: 1366px) {
  [dir='ltr'] .ms-xxlOffset0 {
    margin-left: 0; }
  [dir='rtl'] .ms-xxlOffset0 {
    margin-right: 0; } }

@media (min-width: 1920px) {
  .ms-xxxl12 {
    width: 100%; } }

@media (min-width: 1920px) {
  .ms-xxxl11 {
    width: 91.66666666666666%; } }

@media (min-width: 1920px) {
  .ms-xxxl10 {
    width: 83.33333333333334%; } }

@media (min-width: 1920px) {
  .ms-xxxl9 {
    width: 75%; } }

@media (min-width: 1920px) {
  .ms-xxxl8 {
    width: 66.66666666666666%; } }

@media (min-width: 1920px) {
  .ms-xxxl7 {
    width: 58.333333333333336%; } }

@media (min-width: 1920px) {
  .ms-xxxl6 {
    width: 50%; } }

@media (min-width: 1920px) {
  .ms-xxxl5 {
    width: 41.66666666666667%; } }

@media (min-width: 1920px) {
  .ms-xxxl4 {
    width: 33.33333333333333%; } }

@media (min-width: 1920px) {
  .ms-xxxl3 {
    width: 25%; } }

@media (min-width: 1920px) {
  .ms-xxxl2 {
    width: 16.666666666666664%; } }

@media (min-width: 1920px) {
  .ms-xxxl1 {
    width: 8.333333333333332%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull12 {
    right: 100%; }
  [dir='rtl'] .ms-xxxlPull12 {
    left: 100%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull11 {
    right: 91.66667%; }
  [dir='rtl'] .ms-xxxlPull11 {
    left: 91.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull10 {
    right: 83.33333%; }
  [dir='rtl'] .ms-xxxlPull10 {
    left: 83.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull9 {
    right: 75%; }
  [dir='rtl'] .ms-xxxlPull9 {
    left: 75%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull8 {
    right: 66.66667%; }
  [dir='rtl'] .ms-xxxlPull8 {
    left: 66.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull7 {
    right: 58.33333%; }
  [dir='rtl'] .ms-xxxlPull7 {
    left: 58.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull6 {
    right: 50%; }
  [dir='rtl'] .ms-xxxlPull6 {
    left: 50%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull5 {
    right: 41.66667%; }
  [dir='rtl'] .ms-xxxlPull5 {
    left: 41.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull4 {
    right: 33.33333%; }
  [dir='rtl'] .ms-xxxlPull4 {
    left: 33.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull3 {
    right: 25%; }
  [dir='rtl'] .ms-xxxlPull3 {
    left: 25%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull2 {
    right: 16.66667%; }
  [dir='rtl'] .ms-xxxlPull2 {
    left: 16.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull1 {
    right: 8.33333%; }
  [dir='rtl'] .ms-xxxlPull1 {
    left: 8.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPull0 {
    right: auto; }
  [dir='rtl'] .ms-xxxlPull0 {
    left: auto; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush12 {
    left: 100%; }
  [dir='rtl'] .ms-xxxlPush12 {
    right: 100%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush11 {
    left: 91.66667%; }
  [dir='rtl'] .ms-xxxlPush11 {
    right: 91.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush10 {
    left: 83.33333%; }
  [dir='rtl'] .ms-xxxlPush10 {
    right: 83.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush9 {
    left: 75%; }
  [dir='rtl'] .ms-xxxlPush9 {
    right: 75%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush8 {
    left: 66.66667%; }
  [dir='rtl'] .ms-xxxlPush8 {
    right: 66.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush7 {
    left: 58.33333%; }
  [dir='rtl'] .ms-xxxlPush7 {
    right: 58.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush6 {
    left: 50%; }
  [dir='rtl'] .ms-xxxlPush6 {
    right: 50%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush5 {
    left: 41.66667%; }
  [dir='rtl'] .ms-xxxlPush5 {
    right: 41.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush4 {
    left: 33.33333%; }
  [dir='rtl'] .ms-xxxlPush4 {
    right: 33.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush3 {
    left: 25%; }
  [dir='rtl'] .ms-xxxlPush3 {
    right: 25%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush2 {
    left: 16.66667%; }
  [dir='rtl'] .ms-xxxlPush2 {
    right: 16.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush1 {
    left: 8.33333%; }
  [dir='rtl'] .ms-xxxlPush1 {
    right: 8.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlPush0 {
    left: auto; }
  [dir='rtl'] .ms-xxxlPush0 {
    right: auto; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset11 {
    margin-left: 91.66667%; }
  [dir='rtl'] .ms-xxxlOffset11 {
    margin-right: 91.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset10 {
    margin-left: 83.33333%; }
  [dir='rtl'] .ms-xxxlOffset10 {
    margin-right: 83.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset9 {
    margin-left: 75%; }
  [dir='rtl'] .ms-xxxlOffset9 {
    margin-right: 75%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset8 {
    margin-left: 66.66667%; }
  [dir='rtl'] .ms-xxxlOffset8 {
    margin-right: 66.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset7 {
    margin-left: 58.33333%; }
  [dir='rtl'] .ms-xxxlOffset7 {
    margin-right: 58.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset6 {
    margin-left: 50%; }
  [dir='rtl'] .ms-xxxlOffset6 {
    margin-right: 50%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset5 {
    margin-left: 41.66667%; }
  [dir='rtl'] .ms-xxxlOffset5 {
    margin-right: 41.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset4 {
    margin-left: 33.33333%; }
  [dir='rtl'] .ms-xxxlOffset4 {
    margin-right: 33.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset3 {
    margin-left: 25%; }
  [dir='rtl'] .ms-xxxlOffset3 {
    margin-right: 25%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset2 {
    margin-left: 16.66667%; }
  [dir='rtl'] .ms-xxxlOffset2 {
    margin-right: 16.66667%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset1 {
    margin-left: 8.33333%; }
  [dir='rtl'] .ms-xxxlOffset1 {
    margin-right: 8.33333%; } }

@media (min-width: 1920px) {
  [dir='ltr'] .ms-xxxlOffset0 {
    margin-left: 0; }
  [dir='rtl'] .ms-xxxlOffset0 {
    margin-right: 0; } }

.ms-borderBox, .ms-borderBox::before, .ms-borderBox::after {
  box-sizing: border-box; }

.ms-borderBase {
  border: 1px solid; }

.ms-clearfix {
  *zoom: 1; }
  .ms-clearfix::before, .ms-clearfix::after {
    display: table;
    content: '';
    line-height: 0; }
  .ms-clearfix::after {
    clear: both; }

.ms-normalize {
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 0; }

[dir='ltr'] .ms-textAlignLeft {
  text-align: left; }

[dir='rtl'] .ms-textAlignLeft {
  text-align: right; }

[dir='ltr'] .ms-textAlignRight {
  text-align: right; }

[dir='rtl'] .ms-textAlignRight {
  text-align: left; }

.ms-textAlignCenter {
  text-align: center; }

.ms-screenReaderOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ms-textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

.ms-noWrap {
  white-space: nowrap; }

.ms-Fabric {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #333333;
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px; }
  .ms-Fabric button,
  .ms-Fabric input,
  .ms-Fabric textarea {
    font-family: inherit; }

*[lang^='ar'] .ms-Fabric,
.ms-Fabric *[lang^='ar'],
.ms-Fabric[lang^='ar'] {
  font-family: "Segoe UI Web (Arabic)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='bg'] .ms-Fabric,
.ms-Fabric *[lang^='bg'],
.ms-Fabric[lang^='bg'] {
  font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='cs'] .ms-Fabric,
.ms-Fabric *[lang^='cs'],
.ms-Fabric[lang^='cs'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='el'] .ms-Fabric,
.ms-Fabric *[lang^='el'],
.ms-Fabric[lang^='el'] {
  font-family: "Segoe UI Web (Greek)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='et'] .ms-Fabric,
.ms-Fabric *[lang^='et'],
.ms-Fabric[lang^='et'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='he'] .ms-Fabric,
.ms-Fabric *[lang^='he'],
.ms-Fabric[lang^='he'] {
  font-family: "Segoe UI Web (Hebrew)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='hi'] .ms-Fabric,
.ms-Fabric *[lang^='hi'],
.ms-Fabric[lang^='hi'] {
  font-family: "Nirmala UI", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='hr'] .ms-Fabric,
.ms-Fabric *[lang^='hr'],
.ms-Fabric[lang^='hr'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='hu'] .ms-Fabric,
.ms-Fabric *[lang^='hu'],
.ms-Fabric[lang^='hu'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='ja'] .ms-Fabric,
.ms-Fabric *[lang^='ja'],
.ms-Fabric[lang^='ja'] {
  font-family: "Yu Gothic UI", "Meiryo UI", Meiryo, "MS Pgothic", Osaka, "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='kk'] .ms-Fabric,
.ms-Fabric *[lang^='kk'],
.ms-Fabric[lang^='kk'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='ko'] .ms-Fabric,
.ms-Fabric *[lang^='ko'],
.ms-Fabric[lang^='ko'] {
  font-family: "Malgun Gothic", Gulim, "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='lo'] .ms-Fabric,
.ms-Fabric *[lang^='lo'],
.ms-Fabric[lang^='lo'] {
  font-family: "Leelawadee UI Web", "Lao UI", DokChampa, "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='lt'] .ms-Fabric,
.ms-Fabric *[lang^='lt'],
.ms-Fabric[lang^='lt'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='lv'] .ms-Fabric,
.ms-Fabric *[lang^='lv'],
.ms-Fabric[lang^='lv'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='pl'] .ms-Fabric,
.ms-Fabric *[lang^='pl'],
.ms-Fabric[lang^='pl'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='ru'] .ms-Fabric,
.ms-Fabric *[lang^='ru'],
.ms-Fabric[lang^='ru'] {
  font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='sk'] .ms-Fabric,
.ms-Fabric *[lang^='sk'],
.ms-Fabric[lang^='sk'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='sr-latn'] .ms-Fabric,
.ms-Fabric *[lang^='sr-latn'],
.ms-Fabric[lang^='sr-latn'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='th'] .ms-Fabric,
.ms-Fabric *[lang^='th'],
.ms-Fabric[lang^='th'] {
  font-family: "Leelawadee UI Web", "Kmer UI", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='tr'] .ms-Fabric,
.ms-Fabric *[lang^='tr'],
.ms-Fabric[lang^='tr'] {
  font-family: "Segoe UI Web (East European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='uk'] .ms-Fabric,
.ms-Fabric *[lang^='uk'],
.ms-Fabric[lang^='uk'] {
  font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='vi'] .ms-Fabric,
.ms-Fabric *[lang^='vi'],
.ms-Fabric[lang^='vi'] {
  font-family: "Segoe UI Web (Vietnamese)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='zh-hans'] .ms-Fabric,
.ms-Fabric *[lang^='zh-hans'],
.ms-Fabric[lang^='zh-hans'] {
  font-family: "Microsoft Yahei UI", Verdana, Simsun, "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

*[lang^='zh-hant'] .ms-Fabric,
.ms-Fabric *[lang^='zh-hant'],
.ms-Fabric[lang^='zh-hant'] {
  font-family: "Microsoft Jhenghei UI", Pmingliu, "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

.ms-Fabric--selawik {
  font-family: "Selawik Web", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

body {
  margin: 0px !important;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

h1 {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: lighter;
  margin-top: 31px;
  margin-bottom: 31px; }

h2 {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 20px; }

h3 {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: normal;
  margin-block-start: 0px;
  margin-block-end: 0px; }

.page-header {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: lighter;
  margin-top: 31px;
  margin-bottom: 31px; }

.page-header-with-border {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: lighter;
  margin-top: 31px;
  margin-bottom: 31px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c8c8c8; }

.page-sub-header {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 20px; }

.float-right-content {
  float: right; }

.zero-padding {
  padding: 0px; }

.zero-margin {
  margin: 0px; }

.widthOf50Percent {
  width: 50%; }

.widthOf100Percent {
  width: 100%; }

.hover-content {
  cursor: pointer; }

.offscreen {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* added line */
  width: 1px; }

@media (min-width: 320px) and (max-width: 768px) {
  .page-header {
    margin-bottom: 40px; } }

.pager {
  display: flex; }
  .pager .page-size-selection {
    vertical-align: middle;
    padding-top: 1px; }
    .pager .page-size-selection select {
      height: 25px; }
  .pager .pagination-label {
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    padding-top: 4px; }
  @media (min-width: 320px) and (max-width: 479px) {
    .pager .pagination-label {
      padding: 0px !important;
      margin-top: 5px !important; } }
  @media (min-width: 480px) and (max-width: 639px) {
    .pager .pagination-label {
      padding: 0px !important;
      margin-top: 5px !important; } }
  @media (min-width: 640px) and (max-width: 1023px) {
    .pager .pagination-label {
      padding: 0px !important;
      margin-top: 5px !important; } }
  .pager .pagination {
    display: inherit;
    margin-left: auto; }
    .pager .pagination .pageno-input {
      vertical-align: middle;
      padding-right: 20px;
      padding-left: 10px; }
      .pager .pagination .pageno-input input {
        width: 56px;
        height: 20px;
        font-size: 13px; }
    @media (min-width: 320px) and (max-width: 479px) {
      .pager .pagination .pageno-input {
        width: 140px;
        padding: 0px; }
      .pager .pagination .pageLabel {
        display: none; }
      .pager .pagination .pagesizeLabel {
        display: none; } }
    @media (min-width: 480px) and (max-width: 639px) {
      .pager .pagination .pageno-input {
        width: 140px;
        padding: 0px; }
      .pager .pagination .pageLabel {
        display: none; }
      .pager .pagination .pagesizeLabel {
        display: none; } }
    @media (min-width: 640px) and (max-width: 1023px) {
      .pager .pagination .pageno-input {
        width: 140px;
        padding: 0px; }
      .pager .pagination .pageLabel {
        display: none; }
      .pager .pagination .pagesizeLabel {
        display: none; } }
    .pager .pagination ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center; }
    .pager .pagination ul a {
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      color: #000000;
      position: relative;
      transition: 0.3s; }
    .pager .pagination a::before a::after {
      transition: 0.3s;
      content: "";
      position: absolute;
      height: 50%;
      background-color: #aaa; }
    .pager .pagination a:hover {
      background-color: #0079c1;
      color: #ffffff;
      border: 1px solid #0079c1; }
    .pager .pagination a:hover::before, .pager .pagination a:hover::after {
      background-color: #fff; }
    .pager .pagination a.active {
      background-color: #0079c1;
      color: #fff;
      border: 1px solid #0079c1; }

@media (min-width: 320px) and (max-width: 479px) {
  .search-result-view {
    display: inline-block; }
  .pages {
    margin-top: 5px !important; }
  #pageNo {
    width: 45px; }
  .page-size {
    margin-bottom: 30px; } }

@media (min-width: 480px) and (max-width: 639px) {
  .search-result-view {
    display: inline-block; }
  .pages {
    margin-top: 5px; }
  #pageNo {
    width: 45px; }
  .page-size {
    margin-bottom: 30px; } }

@media (min-width: 640px) and (max-width: 1023px) {
  .search-result-view {
    display: inline-block; }
  .pages {
    margin-top: 5px; }
  #pageNo {
    width: 45px; } }

@media (min-width: 641px) and (max-width: 800px) {
  .page-size {
    margin-bottom: 30px; } }

.wizard-step {
  padding: 0px;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }
  .wizard-step .wizard-step-header {
    font-weight: lighter;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c8c8c8; }
    .wizard-step .wizard-step-header > span {
      font-size: 28px;
      font-weight: lighter; }
  .wizard-step .wizard-step-body {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c8c8c8; }
  .wizard-step .wizard-step-footer {
    text-align: right; }
    .wizard-step .wizard-step-footer button {
      font-size: 15px;
      min-width: 120px;
      margin-left: 10px; }
    @media (min-width: 600px) {
      .wizard-step .wizard-step-footer .button-group {
        margin-bottom: 36px; } }

.wizard-nav-container {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  text-align: center;
  display: inline-block;
  display: flex;
  width: 80%;
  margin: 0 auto; }
  .wizard-nav-container .disabledAction {
    pointer-events: none; }
  .wizard-nav-container .hideProgress {
    position: absolute;
    left: -5000px; }
  .wizard-nav-container .wizard-nav-item-container {
    display: flex;
    width: 100%; }
    .wizard-nav-container .wizard-nav-item-container .wizard-nav-item {
      border-top: 1px solid;
      width: 100%;
      border-color: #c8c8c8; }
      .wizard-nav-container .wizard-nav-item-container .wizard-nav-item .nav-item-status {
        margin-top: 10px; }
        .wizard-nav-container .wizard-nav-item-container .wizard-nav-item .nav-item-status > a {
          font-size: 16px;
          color: #626262;
          text-decoration: none; }
        .wizard-nav-container .wizard-nav-item-container .wizard-nav-item .nav-item-status .hide-element {
          display: none; }
    .wizard-nav-container .wizard-nav-item-container .round {
      width: 30px;
      height: 30px;
      background-color: #c8c8c8;
      border-radius: 100%;
      display: inline-block;
      margin-top: -15px; }
  .wizard-nav-container .wizard-nav-item-container:hover .wizard-nav-item {
    border-color: #0078d4; }
    .wizard-nav-container .wizard-nav-item-container:hover .wizard-nav-item .nav-item-status > a {
      color: #0078d4; }
  .wizard-nav-container .wizard-nav-item-container:hover .round {
    background-color: #0078d4; }
  .wizard-nav-container .not-started .wizard-nav-item {
    border-color: #c8c8c8; }
    .wizard-nav-container .not-started .wizard-nav-item .nav-item-status > a {
      color: #626262; }
  .wizard-nav-container .not-started .round {
    background-color: #c8c8c8; }
  .wizard-nav-container .in-progress .wizard-nav-item {
    border-color: #0078d4; }
    .wizard-nav-container .in-progress .wizard-nav-item .nav-item-status > a {
      color: #0078d4; }
  .wizard-nav-container .in-progress .round {
    background-color: #0078d4; }
  .wizard-nav-container .completed .wizard-nav-item, .wizard-nav-container .completed:hover .wizard-nav-item {
    border-color: #0078d4; }
    .wizard-nav-container .completed .wizard-nav-item .nav-item-status > a, .wizard-nav-container .completed:hover .wizard-nav-item .nav-item-status > a {
      color: #0078d4; }
  .wizard-nav-container .completed .round, .wizard-nav-container .completed:hover .round {
    background-color: #EEEEEE;
    border: 1px solid #0078d4;
    color: #0078d4;
    vertical-align: middle;
    margin-top: -22px; }
    .wizard-nav-container .completed .round i, .wizard-nav-container .completed:hover .round i {
      padding-top: 6px; }
  .wizard-nav-container .disabled .wizard-nav-item, .wizard-nav-container .disabled:hover .wizard-nav-item {
    border-color: #c8c8c8; }
    .wizard-nav-container .disabled .wizard-nav-item .nav-item-status > a, .wizard-nav-container .disabled:hover .wizard-nav-item .nav-item-status > a {
      color: #626262; }
  .wizard-nav-container .disabled .round, .wizard-nav-container .disabled:hover .round {
    background-color: #c8c8c8; }
  .wizard-nav-container .error .wizard-nav-item {
    border-color: #a80000; }
    .wizard-nav-container .error .wizard-nav-item .nav-item-status > a {
      color: #a80000; }
  .wizard-nav-container .error .round {
    background-color: #a80000; }

@media (min-width: 300px) and (max-width: 768px) {
  .wizard-nav-item .nav-item-status {
    display: none; } }

.wizard-nav {
  text-align: center;
  display: inline-block;
  box-shadow: 0 0 15px 1px #a6a6a6;
  overflow: hidden;
  border-radius: 5px;
  /*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
  /*hover/active styles*/
  /*adding the arrows for the breadcrumbs using rotated pseudo elements*/
  /*we dont need an arrow after the last link*/
  /*we will use the :before element to show numbers*/ }
  .wizard-nav a {
    text-decoration: none;
    float: left;
    font-size: 12px;
    line-height: 36px;
    /*need more margin on the left of links to accomodate the numbers*/
    padding: 0 10px 0 40px;
    background: #666666;
    background: linear-gradient(#666666, #333333);
    position: relative;
    font-weight: 300;
    cursor: pointer; }
  .wizard-nav a:first-child {
    padding-left: 30px;
    border-radius: 5px 0 0 5px;
    /*to match with the parent's radius*/ }
  .wizard-nav a:first-child:before {
    left: 14px; }
  .wizard-nav a:last-child {
    border-radius: 0 5px 5px 0;
    /*this was to prevent glitches on hover*/
    padding-right: 30px; }
  .wizard-nav a.active, .wizard-nav a:hover {
    background: #333333;
    background: linear-gradient(#333333, #000000); }
  .wizard-nav a.active:after, .wizard-nav a:hover:after {
    background: #333333;
    background: linear-gradient(135deg, #333333, #000000); }
  .wizard-nav a:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    /*half of square's length*/
    /*same dimension as the line-height of a */
    width: 36px;
    height: 36px;
    /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
        length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
        if diagonal required = 1; length = 1/1.414 = 0.707*/
    transform: scale(0.707) rotate(45deg);
    /*we need to prevent the arrows from getting buried under the next link*/
    z-index: 1;
    /*background same as links but the gradient will be rotated to compensate with the transform applied*/
    background: #666666;
    background: linear-gradient(135deg, #666666, #333333);
    /*stylish arrow design using box shadow*/
    box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4), 3px -3px 0 2px rgba(255, 255, 255, 0.1);
    /*
            5px - for rounded arrows and 
            50px - to prevent hover glitches on the border created using shadows*/
    border-radius: 0 5px 0 50px; }
  .wizard-nav a:last-child:after {
    content: none; }
  .wizard-nav .flat a, .wizard-nav .flat a:after, .wizard-nav .flat a:before {
    color: #000000;
    background: #f4f4f4;
    transition: all 0.2s; }
  .wizard-nav .flat a.not-started:before {
    padding-left: 10px; }
  .wizard-nav .flat a.in-progress, .wizard-nav .flat a.in-progress:after {
    background: #0078d4;
    color: white; }
  .wizard-nav .flat a.in-progress:hover, .wizard-nav .flat a.in-progress:hover:before, .wizard-nav .flat a.in-progress:hover:after {
    cursor: text;
    color: white; }
  .wizard-nav .flat a.completed:before {
    content: '\2713';
    padding-right: 5px; }
  .wizard-nav .flat a.disabled:hover, .wizard-nav .flat a.disabled:hover:before, .wizard-nav .flat a.disabled:hover:after {
    cursor: not-allowed;
    background: #f4f4f4; }
  .wizard-nav .flat a:hover, .wizard-nav .flat a:hover:before, .wizard-nav .flat a:hover:after {
    background: #0078d4; }

.accordion {
  padding: 0px;
  width: 100%; }
  .accordion .accordion-header {
    width: 100%;
    text-align: left;
    display: flex; }
    .accordion .accordion-header .header-toggle-icon {
      font-size: 100%;
      font-weight: bold; }
    .accordion .accordion-header div:nth-child(2) {
      margin-left: auto; }
      .accordion .accordion-header div:nth-child(2) i {
        font-size: 80%;
        font-weight: bold; }
    .accordion .accordion-header .accordion-header-icon {
      cursor: pointer;
      font-weight: bold;
      vertical-align: top;
      margin: auto;
      font-size: 20px;
      margin-right: 0; }
    .accordion .accordion-header .accordion-header-text {
      width: 98%; }
  .accordion .accordion-body {
    width: 100%;
    text-align: left;
    padding: 4px; }
  .accordion .accordion-body-hide {
    display: none; }

.card {
  border: 1px solid #D7D7D7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

.card-body {
  background-color: white;
  padding: 10px; }

.card-header {
  background-color: #EEEEEE;
  padding: 10px; }

.option-button .option-Label {
  text-align: left; }

.option-button .button {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI" "Helvetica Neue";
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #D7D7D7;
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 120px; }

.option-button .button-error {
  border: 1px solid #a80000; }

.option-button .option-error {
  clear: both;
  display: block; }
  .option-button .option-error .option-error-msg {
    color: #a80000; }

.custom-radio.switch-radio label {
  border: 1px solid #D7D7D7;
  float: left;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  background: #ffffff; }
  .custom-radio.switch-radio label:before, .custom-radio.switch-radio label:after {
    display: none;
    height: initial;
    border-radius: 0; }

.custom-radio.switch-radio input:checked ~ label {
  color: #106ebe; }
  .custom-radio.switch-radio input:checked ~ label:before {
    display: block;
    content: "";
    position: absolute;
    width: 4px;
    top: -1px;
    bottom: -1px;
    left: -1px;
    background: #106ebe;
    height: initial;
    border-radius: initial; }
    @media screen and (-ms-high-contrast: black-on-white) {
      .custom-radio.switch-radio input:checked ~ label:before {
        border: 1px solid black;
        background: black !important;
        width: 0px;
        border-left: 4px solid black; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .custom-radio.switch-radio input:checked ~ label:before {
        border: 1px solid white;
        background: white !important;
        width: 0px;
        border-left: 4px solid white; } }

.custom-radio.switch-radio input:disabled + label {
  color: #c8c8c8;
  cursor: not-allowed; }
  .custom-radio.switch-radio input:disabled + label:before {
    background: #c8c8c8; }

.custom-radio.switch-radio .custom-control-input:focus + label {
  outline: 2px solid #0078D4; }
  .custom-radio.switch-radio .custom-control-input:focus + label:focus, .custom-radio.switch-radio .custom-control-input:focus + label:hover {
    border: 1px solid #0078D4; }
    @media screen and (-ms-high-contrast: black-on-white) {
      .custom-radio.switch-radio .custom-control-input:focus + label:focus, .custom-radio.switch-radio .custom-control-input:focus + label:hover {
        border: 1px solid black; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .custom-radio.switch-radio .custom-control-input:focus + label:focus, .custom-radio.switch-radio .custom-control-input:focus + label:hover {
        border: 1px solid white; } }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

input[type="radio"][readonly], input[type="radio"][readonly] ~ label {
  pointer-events: none; }

@media (min-width: 300px) and (max-width: 768px) {
  .custom-radio.switch-radio label {
    margin-top: 10px; } }

.autocomplete {
  text-align: left; }
  .autocomplete .autocomplete-details {
    height: 125px;
    max-height: 125px;
    right: 15px;
    left: 15px;
    border-top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    z-index: 1; }
  .autocomplete .ms-DetailsRow-cell {
    font-size: 16px;
    color: #000000;
    background-color: #EEEEEE; }
    .autocomplete .ms-DetailsRow-cell:hover, .autocomplete .ms-DetailsRow-cell:focus {
      background: #106ebe;
      color: #ffffff;
      font-weight: bold; }
  .autocomplete .loader {
    position: relative; }
  .autocomplete .loader-position {
    position: absolute;
    right: 2px;
    padding: 34px 4px 0 0;
    z-index: 1; }

.auto-complete {
  position: relative;
  display: inline-block;
  width: 100%; }
  .auto-complete .auto-complete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    overflow-y: scroll;
    max-height: 200px; }
  .auto-complete .auto-complete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4; }
  .auto-complete .auto-complete-items div:hover {
    background-color: #e9e9e9; }
  .auto-complete .auto-complete-active {
    background-color: #0078d4 !important;
    color: #ffffff; }

@media screen and (-ms-high-contrast: active) {
  .auto-complete .auto-complete-active {
    -ms-high-contrast-adjust: none; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .auto-complete .auto-complete-active {
    background-color: black !important;
    color: white; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .auto-complete .auto-complete-active {
    background-color: blue !important;
    color: white; } }

.not-found-container {
  text-align: center; }

.loading {
  position: fixed;
  top: 40%;
  right: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 101;
  opacity: 1; }

.panel-loading {
  position: absolute;
  top: 40%;
  right: 0px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 101;
  opacity: 1; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.alertList {
  margin: 15px 0; }

.hidden-alert {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* added line */
  width: 1px; }

@media screen and (-ms-high-contrast: black-on-white) {
  .ms-MessageBar {
    border: 1px solid; }
    .ms-MessageBar .ms-MessageBar-content {
      color: black; }
      .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-text, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-icon, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-dismissSingleLine {
        color: black; }
        .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-text i, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-icon i, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-dismissSingleLine i {
          color: black; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .ms-MessageBar {
    border: 1px solid; }
    .ms-MessageBar .ms-MessageBar-content {
      color: black; }
      .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-text, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-icon, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-dismissSingleLine {
        color: black; }
        .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-text i, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-icon i, .ms-MessageBar .ms-MessageBar-content .ms-MessageBar-dismissSingleLine i {
          color: black; } }

.controls-container {
  margin: 10px 0px 0px 0px;
  text-align: center; }
  @media only screen and (max-width: 1023px) and (min-width: 640px) {
    .controls-container {
      margin-top: 10px;
      margin-bottom: 100px; } }
  @media only screen and (max-width: 639px) and (min-width: 480px) {
    .controls-container {
      margin-top: 10px;
      margin-bottom: 100px; } }
  @media only screen and (max-width: 479px) and (min-width: 320px) {
    .controls-container {
      margin-top: 10px;
      margin-bottom: 100px; } }

.search-result {
  margin-top: 5px; }
  .search-result .search-result-status {
    font-size: 22px;
    margin: 10px 0px 0px 0px;
    padding-bottom: 4px; }
  .search-result .search-result-data {
    clear: both; }
    .search-result .search-result-data .pager-data {
      margin-top: 10px;
      padding: 0px; }
  .search-result .search-filters {
    margin-top: 10px; }
  .search-result .search-filter-icon {
    width: 25px;
    font-size: 16px;
    cursor: pointer; }
  .search-result .search-input-paramters {
    margin: 5px 0px 5px 0px; }
    .search-result .search-input-paramters .label {
      font-size: 14px;
      color: #626262; }
    .search-result .search-input-paramters .value {
      font-size: 14px;
      color: #000000; }
  .search-result .no-search-result-status {
    font-size: 22px;
    margin: 0px 0px 10px 0px;
    padding-bottom: 6px; }
  .search-result .search-filters-header {
    font-size: 20px;
    margin: 0px 0px 20px 0px;
    border-bottom: 1px solid #c8c8c8;
    padding-bottom: 5px;
    display: flex; }
    .search-result .search-filters-header i {
      width: 25px;
      font-size: 16px; }
    .search-result .search-filters-header .search-filters-header-actions {
      margin-left: auto;
      font-size: 16px; }
      .search-result .search-filters-header .search-filters-header-actions a {
        text-decoration: none; }
  .search-result .action-buttons {
    display: flex;
    margin-bottom: 10px; }
    .search-result .action-buttons a {
      text-decoration: none;
      margin-top: 30px;
      margin-right: 10px; }
    .search-result .action-buttons .search-filters-show-header {
      font-size: 20px;
      margin-top: 10px; }
      .search-result .action-buttons .search-filters-show-header i {
        width: 25px;
        font-size: 16px; }
    .search-result .action-buttons .action-buttons-right {
      margin-left: auto;
      display: flex;
      justify-content: flex-end; }
      .search-result .action-buttons .action-buttons-right a {
        display: flex;
        justify-content: flex-end; }
    @media only screen and (max-width: 639px) and (min-width: 480px) {
      .search-result .action-buttons .action-buttons-right {
        display: block; } }
    @media only screen and (max-width: 479px) and (min-width: 320px) {
      .search-result .action-buttons .action-buttons-right {
        display: block; } }
  .search-result .search-filter {
    border-bottom: 1px solid #c8c8c8;
    margin: 15px 0px 10px 0px; }
    .search-result .search-filter .search-filter-name {
      font-size: 18px;
      margin: 0px 0px 5px 0px; }
    .search-result .search-filter .search-filter-items {
      margin: 5px; }
      .search-result .search-filter .search-filter-items .search-filter-item {
        display: flex;
        margin: 3px 0px 3px 0px; }
        .search-result .search-filter .search-filter-items .search-filter-item .search-filter-item-count {
          margin-left: auto; }
  .search-result .filter-badge {
    margin: 10px 0px 10px 0px; }
    .search-result .filter-badge .filter-badge-item {
      border: 1px solid #c8c8c8;
      vertical-align: middle;
      padding: 1px 1px 1px 1px;
      margin-right: 5px;
      display: inline-flex;
      margin-bottom: 5px; }
      .search-result .filter-badge .filter-badge-item .filter-badge-item-text {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px; }
      @media (min-width: 320px) and (max-width: 479px) {
        .search-result .filter-badge .filter-badge-item .filter-badge-item-text {
          max-width: 350px;
          flex-grow: 1; } }
      @media (min-width: 640px) and (max-width: 725px) {
        .search-result .filter-badge .filter-badge-item .filter-badge-item-text {
          max-width: 350px;
          flex-grow: 1; } }
      @media (min-width: 640px) and (max-width: 725px) {
        .search-result .filter-badge .filter-badge-item .filter-badge-item-text {
          max-width: 350px;
          flex-grow: 1; } }
      .search-result .filter-badge .filter-badge-item .filter-badge-item-btn {
        padding: 0px;
        margin: 0px; }

.selected-items {
  margin: 10px 0px 10px 0px;
  padding-bottom: 8px;
  border: 1px solid #fbfbfb;
  background-color: #EEEEEE; }
  .selected-items .selected-items-header {
    margin: 3px 0px 10px 4px; }
  .selected-items .remove-all-button {
    margin: 0px 0px 0px 5px;
    text-decoration: none;
    font-size: 15px; }
  .selected-items .selected-item-badge {
    padding: 0px 0px 3px 5px;
    margin-left: 4px;
    border: 1px solid #c8c8c8;
    vertical-align: middle;
    margin-right: 5px;
    display: inline-flex;
    margin-bottom: 3px;
    width: 270px; }
    @media (min-width: 320px) and (max-width: 479px) {
      .selected-items .selected-item-badge .selected-item-badge {
        margin-left: 10px; } }
    @media (min-width: 480px) and (max-width: 600px) {
      .selected-items .selected-item-badge .selected-item-badge {
        margin-left: 10px; } }
    .selected-items .selected-item-badge .selected-item-badge-text {
      margin-top: 7px;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px; }
      .selected-items .selected-item-badge .selected-item-badge-text .badge-label {
        color: #626262; }
    @media (min-width: 320px) and (max-width: 479px) {
      .selected-items .selected-item-badge .selected-item-badge-text {
        max-width: 250px;
        flex-grow: 1; } }
    @media (min-width: 480px) and (max-width: 600px) {
      .selected-items .selected-item-badge .selected-item-badge-text {
        max-width: 250px;
        flex-grow: 1; } }
    .selected-items .selected-item-badge .selected-item-badge-btn {
      padding: 0px;
      margin: 0px; }

@media (min-width: 320px) and (max-width: 479px) {
  .search-input-paramters {
    padding-bottom: 8px; }
  .filter-item-view {
    display: inline-flex;
    max-width: 250px;
    margin-bottom: 5px; }
  .filter-badge-item {
    padding: 0px; } }

@media (min-width: 480px) and (max-width: 639px) {
  .search-input-paramters {
    padding-bottom: 8px; }
  .filter-item-view {
    display: inline-flex;
    max-width: 250px;
    margin-bottom: 5px; }
  .filter-badge-item {
    padding: 0px; } }

@media (min-width: 640px) and (max-width: 725px) {
  .search-input-paramters {
    padding-bottom: 8px; }
  .filter-item-view {
    display: inline-flex;
    max-width: 250px;
    margin-bottom: 5px; }
  .filter-badge-item {
    padding: 0px; } }

@media only screen and (max-width: 1023px) and (min-width: 640px) {
  .order-main-section {
    margin-top: 10px;
    margin-bottom: 100px; } }

@media only screen and (max-width: 639px) and (min-width: 480px) {
  .order-main-section {
    margin-top: 10px;
    margin-bottom: 100px; } }

@media only screen and (max-width: 479px) and (min-width: 320px) {
  .order-main-section {
    margin-top: 10px;
    margin-bottom: 100px; } }

.create-order-nav {
  text-align: center;
  position: relative;
  top: 10px;
  left: -5%; }

.order-confirmation {
  text-align: center;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }
  .order-confirmation .status-msg-header {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .order-confirmation .status-msg {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px; }

.order-status-text {
  font-size: 16px;
  color: #0078d4; }

.order-confirmation-msg {
  text-align: center; }

.order-status-header {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  text-align: right;
  margin-right: 10%; }
  .order-status-header .order-status {
    font-size: 16px; }

.create-order-title {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: lighter;
  margin-top: 40px;
  margin-bottom: 40px; }

@media (min-width: 300px) and (max-width: 768px) {
  .order-status-header .order-status {
    text-align: left; } }

@media only screen and (max-width: 1023px) and (min-width: 640px) {
  .wizard-nav-item .nav-item-status {
    display: block; } }

@media only screen and (max-width: 639px) and (min-width: 480px) {
  .wizard-nav-item .nav-item-status {
    display: block; } }

@media only screen and (max-width: 479px) and (min-width: 320px) {
  .wizard-nav-item .nav-item-status {
    display: block; } }

@media only screen and (max-width: 1023px) and (min-width: 640px) {
  .create-order-wizard {
    margin-bottom: 50px; } }

@media only screen and (max-width: 639px) and (min-width: 480px) {
  .create-order-wizard {
    margin-bottom: 50px; } }

@media only screen and (max-width: 479px) and (min-width: 320px) {
  .create-order-wizard {
    margin-bottom: 50px; } }

.search-filters .search-filters-text {
  font-size: 20px;
  margin: 0px 0px 20px 0px;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 6px; }

.search-filters .search-filter {
  border-bottom: 1px solid #c8c8c8;
  margin: 10px 0px 10px 0px; }
  .search-filters .search-filter .search-filter-name {
    font-size: 18px;
    margin: 0px 0px 5px 0px; }
  .search-filters .search-filter .search-filter-items {
    margin: 5px; }
    .search-filters .search-filter .search-filter-items .search-filter-item {
      display: flex;
      margin: 3px 0px 3px 0px; }
      .search-filters .search-filter .search-filter-items .search-filter-item .search-filter-item-count {
        margin-left: auto; }

.order-header-container .order-header-sub-header {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: lighter; }

.order-header-container .order-header-padding {
  padding-bottom: 10px; }

.order-header-info .header-text {
  margin-top: 10px;
  margin-bottom: 10px; }

.order-header-info .labelHeader {
  font-size: 14px;
  color: #626262; }

.order-header-info .labelValue {
  font-size: 16px;
  color: #000000;
  word-break: break-word; }

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important; }

.order-line-items .header-text {
  margin-bottom: 10px; }

.order-line-items .order-lineitems-collapse-section {
  display: flex; }
  .order-line-items .order-lineitems-collapse-section .order-lineitems-collapse {
    margin-left: auto;
    text-align: right;
    padding-right: 14px;
    margin-bottom: 10px; }
    .order-line-items .order-lineitems-collapse-section .order-lineitems-collapse i {
      font-weight: bold; }
    .order-line-items .order-lineitems-collapse-section .order-lineitems-collapse .line-items-toggle-icon {
      font-size: 100%;
      font-weight: bold; }

.order-line-items .card {
  background-color: #EEEEEE;
  padding: 7px 10px 3px 5px; }

.order-line-items .order-type {
  margin: 0px 0px 10px 0px; }

.order-line-items .lineitem-card {
  margin-bottom: 10px;
  margin-right: 4px; }
  .order-line-items .lineitem-card .line-item-header {
    display: flex; }
    .order-line-items .lineitem-card .line-item-header .line-item-part {
      margin-left: 15px; }
    .order-line-items .lineitem-card .line-item-header .line-item-qty {
      margin-left: 15px; }
    .order-line-items .lineitem-card .line-item-header .line-item-remove {
      margin-left: auto; }
  .order-line-items .lineitem-card .remove-button {
    padding-right: 3px; }

.order-line-items .card-body {
  background-color: inherit;
  padding: 0px; }

.order-line-items .card-header {
  padding: 0px; }

.order-line-items .labelHeader {
  font-size: 14px;
  color: #626262; }

.order-line-items .labelValue {
  font-size: 16px;
  color: #000000; }

.order-line-items .lineitem-info-msg {
  font-size: 14px !important;
  margin-bottom: 22px;
  font-weight: lighter; }

.order-line-items .add-line-item-button {
  float: right;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #106ebe; }
  .order-line-items .add-line-item-button .iconButtonAddition {
    font-size: 20px;
    margin: 3px 0px 10px 7px; }
  .order-line-items .add-line-item-button .ms-Button-icon {
    font-size: 25px !important;
    margin-left: 10px;
    color: #106ebe; }

.order-line-items .shipment-type-error {
  font-size: 12px;
  color: #a80000; }

.order-line-items-info .header-text {
  margin-bottom: 10px;
  width: 98%; }

.order-line-items-info .order-lineitems-collapse-section {
  display: flex; }
  .order-line-items-info .order-lineitems-collapse-section .order-lineitems-collapse {
    margin-left: auto;
    text-align: right;
    padding-right: 4px;
    margin-bottom: 10px; }
    .order-line-items-info .order-lineitems-collapse-section .order-lineitems-collapse i {
      font-weight: bold; }
    .order-line-items-info .order-lineitems-collapse-section .order-lineitems-collapse .line-items-toggle-icon {
      font-size: 100%;
      font-weight: bold; }

.order-line-items-info .order-type-option {
  margin-top: 0px; }

.order-line-items-info .labelHeader {
  font-size: 14px;
  color: #626262; }

.order-line-items-info .labelValue {
  font-size: 16px;
  color: #000000; }

.order-line-items-info .line-item-info {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  border: 1px solid #c8c8c8;
  background-color: #EEEEEE;
  border-width: 1px;
  margin-top: 10px;
  margin-bottom: 10px; }
  .order-line-items-info .line-item-info .accordion-header-icon {
    padding-right: 5px !important; }
  .order-line-items-info .line-item-info .line-item-header {
    display: flex;
    padding: 10px; }
    .order-line-items-info .line-item-info .line-item-header .line-item-part {
      margin-left: 15px; }
    .order-line-items-info .line-item-info .line-item-header .line-item-qty {
      margin-left: 15px; }
  .order-line-items-info .line-item-info .remove-button {
    padding-right: 3px; }
  .order-line-items-info .line-item-info .line-item-body {
    background-color: white;
    border-left: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8; }
  .order-line-items-info .line-item-info .labelHeader {
    font-size: 14px;
    font-weight: lighter;
    color: #626266; }
  .order-line-items-info .line-item-info .labelValue {
    font-size: 16px;
    font-weight: normal; }

.tempCard {
  width: 100%; }

.commentsArea {
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 15px; }

.review-wizard-step-body {
  padding-bottom: 15px;
  border-bottom: 1px solid #c8c8c8; }

.edit-order {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }
  @media only screen and (max-width: 1023px) and (min-width: 640px) {
    .edit-order {
      margin-top: 10px;
      margin-bottom: 100px; } }
  @media only screen and (max-width: 639px) and (min-width: 480px) {
    .edit-order {
      margin-top: 10px;
      margin-bottom: 100px; } }
  @media only screen and (max-width: 479px) and (min-width: 320px) {
    .edit-order {
      margin-top: 10px;
      margin-bottom: 100px; } }
  .edit-order .edit-order-header {
    font-size: 34px;
    font-weight: lighter;
    margin-top: 31px;
    margin-bottom: 31px;
    border-bottom: 1px solid #c8c8c8; }
  .edit-order .edit-order-header-info {
    padding-bottom: 10px;
    border-bottom: 1px solid #c8c8c8; }
  .edit-order .edit-order-info .header-text {
    margin-top: 10px;
    margin-bottom: 10px; }
  .edit-order .edit-order-info .labelHeader {
    font-size: 14px;
    color: #626262; }
  .edit-order .edit-order-info .labelValue {
    font-size: 16px;
    color: #000000;
    word-break: break-word; }
  .edit-order .edit-order-info .commentsArea {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 15px; }

@media (min-width: 320px) and (max-width: 479px) {
  .root-110 {
    margin-top: 5px; }
  #CommentsValue {
    margin-bottom: 50px; } }

@media (min-width: 480px) and (max-width: 639px) {
  .root-110 {
    margin-top: 5px; }
  #CommentsValue {
    margin-bottom: 50px; } }

@media (min-width: 640px) and (max-width: 1000px) {
  .root-110 {
    margin-top: 5px; }
  #CommentsValue {
    margin-bottom: 50px; } }

.order-shipment-details-header {
  border-bottom: 1px solid #c8c8c8; }

.order-shipment-details-sub-header .labelValue {
  font-size: 18px;
  margin-bottom: 20px; }
  .order-shipment-details-sub-header .labelValue .order-shipment-status {
    color: #0078d4; }

.order-shipment-details-sub-header .actions {
  float: right; }
  .order-shipment-details-sub-header .actions .details-action-button {
    color: #0078d4;
    size: 18px; }

.order-shipment-details {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif; }

.Details-Footer-Button {
  margin-bottom: 5px;
  padding-bottom: 5px;
  text-align: right; }
  .Details-Footer-Button button {
    margin: 2px;
    min-width: 120px;
    text-align: left; }

.row-Spacing {
  margin-top: 10px;
  margin-bottom: 0.5%; }

.row-border-above {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #c8c8c8; }

.align-right {
  text-align: right; }

@media screen and (max-width: 1023px) and (min-width: 640px) {
  .order-shipment-details {
    margin-bottom: 40px; } }

@media screen and (max-width: 639px) and (min-width: 480px) {
  .order-shipment-details {
    margin-bottom: 40px; } }

@media screen and (max-width: 479px) {
  .order-shipment-details {
    margin-bottom: 40px; } }

.order-details-info .order-details-section-title {
  margin-top: 10px;
  margin-bottom: 10px; }

.order-details-info .order-details-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8; }

.order-details-info .order-details-lineitem {
  margin-top: 10px; }

.order-details-info .order-not-found {
  text-align: center;
  margin: 10px; }

.error-boundary {
  text-align: center; }

