@import '../../../../resources/styles/variables/references';

.card {
    border: 1px solid $ms-color-card-border;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);   
}
.card-body {
    background-color: white;
    padding: 10px;
}

.card-header {
    background-color: $ms-color-card-background;
    padding: 10px;
}
