@import '../../../../resources/styles/variables/references';
.search-filters{
    .search-filters-text{
        font-size: 20px;
        margin: 0px 0px 20px 0px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
        padding-bottom: 6px;
    }
    .search-filter{
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
        margin: 10px 0px 10px 0px;  

        .search-filter-name{
            font-size: 18px;
            margin: 0px 0px 5px 0px;            
        }
        .search-filter-items{
            margin: 5px;
            .search-filter-item{
                display: flex;
                margin: 3px 0px 3px 0px;
                .search-filter-item-checkbox{
                    
                }

                .search-filter-item-count{
                    margin-left: auto;
                }
            }

        }
    }
}