//colors variables - Orion control
$ms-color-header-backgorund: #2F2F2F;
$ms-color-active-button: rgb(16, 110, 190);
$ms-color-title-text: #1A0000;
$ms-color-active-text: #3D4954;
$ms-color-disable-text: #c8c8c8;
$ms-color-card-background: #EEEEEE;
$ms-color-card-border: #D7D7D7;
$ms-color-themePrimaryDark:#0068b7;



//Font Variables
$page-header-font-size: 34px !default;
$page-sub-header-font-size: 28px !default;
$section-heading-font-size: 20px !default;

 // Sizes
$ms-font-size-base: 16px !default;
$ms-para-font-size: 14px;
$ms-para-font-weight: 500px;
$ms-h6-font-size: smaller;
$ms-anchor-font-size: smaller;

 //width, height , padding and margin.
$ms-header-width: 54px;
$ms-margin-between-header-items: 10px;

//OptionButton Width and margin
$ms-button-width: 100px;
$ms-button-margin: 10px;
$ms-button-padding: 5px;