@import '../../../../resources/styles/variables/references';


.order-details-info{
    .order-details-section-title{
        margin-top: 10px;
        margin-bottom: 10px; 
    }   
    .order-details-header{
        padding-bottom: 10px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
    }
    .order-details-lineitem{
        margin-top: 10px;
    }
    .order-not-found{
        text-align: center;
        margin: 10px;
    } 
}