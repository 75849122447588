@import '../../../../resources/styles/variables/references';

 .option-button {
    
    .option-Label {
        text-align: left;
    }
   
    .button {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI" "Helvetica Neue";
        padding:8px 16px;
        font-size: 14px;
        font-weight: 600;
        background-color:$ms-color-white;
        border: 1px solid $ms-color-card-border;   
        display: inline-block;
        position: relative;
        text-align: center;
        min-width: 120px;
    }
        
    .button-error{
        border: 1px solid rgb(168, 0, 0);
    }
    .option-error {        
        clear: both;
        display: block;
        .option-error-msg{
            color: rgb(168, 0, 0);
        }
    }
    .option-elements{
        
    }
}

.custom-radio.switch-radio {
    label {
        border: 1px solid $ms-color-card-border;   
        float: left;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        background:$ms-color-white;

        

        &:before, &:after {
            display: none;
            height: initial;
            border-radius: 0;
        }
    }

    input:checked ~ label {
        color: $ms-color-active-button;
        &:before {
            display: block;
            content: "";
            position: absolute;
            width: 4px;
            top: -1px;
            bottom: -1px;
            left: -1px;
            background: $ms-color-active-button;
            height: initial;
            border-radius: initial;

            @media screen and (-ms-high-contrast: black-on-white) {
                border: 1px solid black;
                background:black !important;
                width: 0px;
                border-left: 4px solid black;

             }
             @media screen and (-ms-high-contrast: white-on-black) {
                border: 1px solid white;
                background:white !important; 
                width: 0px;
                border-left: 4px solid white;
             }
            
        }
    }

    input:disabled + label {
        color: $ms-color-disable-text;
        cursor: not-allowed;

        &:before {
            background: $ms-color-disable-text;
        }
    }

    .custom-control-input:focus + label {
        outline: 2px solid #0078D4;
        &:focus,&:hover
        {
            border: 1px solid #0078D4;
            @media screen and (-ms-high-contrast: black-on-white) {
                border: 1px solid black;
             }
             @media screen and (-ms-high-contrast: white-on-black) {
                border: 1px solid white;
             }
        }


    }
}
.custom-control-inline{
    display: inline-flex;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

input[type="radio"][readonly], input[type="radio"][readonly] ~ label {
    pointer-events: none;
}
@media (min-width: 300px) and (max-width: 768px) {
    .custom-radio.switch-radio {
        label {
            margin-top:10px;
        }
    }
}