@import '../../../../resources/styles/variables/references';

.edit-order {
    //large screen	640px - 1023px
    @media only screen and (max-width: 1023px) and (min-width:640px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //medium screen	639px - 480px
    @media only screen and (max-width:639px) and (min-width: 480px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //small screen 320px - 479px
    @media only screen and (max-width: 479px) and (min-width: 320px)  {
        margin-top:10px;
        margin-bottom:100px;
    }   
    font-family: $ms-font-family-fallbacks;

    .edit-order-header {        
        font-size: $page-header-font-size;
        font-weight: lighter;
        margin-top: 31px;
        margin-bottom: 31px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;            
    }

    .edit-order-header-info{
        padding-bottom: 10px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;        
    }

    .edit-order-info{
        .header-text{
            margin-top: 10px;
            margin-bottom: 10px; 
        }
      
        .labelHeader {
            font-size: 14px;
            color: #626262;
        }
        .labelValue {
            font-size: 16px;
            color: $ms-color-black;
            word-break: break-word;
        }
        .commentsArea{
            padding-left:5px;
            padding-top:10px;
            padding-bottom: 15px;
        }         
    }
}
//For small screen
@media (min-width: 320px) and (max-width:479px){
    .root-110{
        margin-top:5px;
    }
    #CommentsValue{
        margin-bottom:50px;
    }
}
//For Medium sized Screen
@media (min-width: 480px) and (max-width:639px){
    .root-110{
        margin-top:5px;
    }
    #CommentsValue{
        margin-bottom:50px;
    }
}
//For Large sized Screen
@media (min-width: 640px) and (max-width:1000px){
    .root-110{
        margin-top:5px;
    }
    #CommentsValue{
        margin-bottom:50px;
    }
}
