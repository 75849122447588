@import '../../../../resources/styles/variables/references';

.tempCard{
    width: 100%;
}

.commentsArea{
    padding-left:5px;
    padding-top:10px;
    padding-bottom: 15px;
}

.review-wizard-step-body{
    padding-bottom: 15px;
    border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
}


