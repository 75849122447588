@import '../../../../resources/styles/variables/references';

.wizard-step{
    padding: 0px;
    font-family: $ms-font-family-fallbacks;

    .wizard-step-header{       
        font-weight: lighter;
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
        >span {
            font-size: $ms-font-size-xxl;
            font-weight: lighter;            
        }
    }

    .wizard-step-body{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
    }

    .wizard-step-footer{
        text-align: right;     
        button {           
            font-size: $ms-font-size-m-plus;   
            min-width: 120px;       
            margin-left: 10px;           
        }
        @media (min-width: 600px) {
            .button-group{
                margin-bottom:36px;
            }
        }
    }
}