@import '../../../../resources/styles/variables/references';

.accordion{
    padding: 0px;
    width: 100%;

    .accordion-header{
        width: 100%;        
        text-align: left;
        display: flex;   
        
        .header-toggle-icon{
            font-size: 100%;
            font-weight: bold;
        }
        div:nth-child(1){
            
        }
        div:nth-child(2){
            margin-left: auto;            
            i{
                font-size: 80%;
                font-weight: bold;
            }
        }          
        .accordion-header-icon{
            cursor: pointer;
            font-weight: bold;           
            vertical-align: top;           
            margin: auto;
            font-size: 20px;             
            margin-right: 0;   
        }
        .accordion-header-text{            
            width: 98%; 
        }
    }
    
    .accordion-body{
        width: 100%;
        text-align: left;        
        padding: 4px;
    }
    
    .accordion-body-hide{
        display: none;
    }
}



