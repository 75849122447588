@import '../../../../resources/styles/variables/references';
.search-result{    
    margin-top: 5px;
   
    .search-result-status{
        font-size: 22px;
        margin: 10px 0px 0px 0px;        
        padding-bottom: 4px;        
    }

    .search-result-data{
        clear: both;
        .pager-data{
            margin-top: 10px;
            padding: 0px;
        }
    }  

    .search-filters{
        margin-top: 10px;
    }

    .search-filter-icon{
        width:25px;
        font-size:16px;
        cursor: pointer;
    }
    .search-input-paramters{
        margin: 5px 0px 5px 0px;
        .label {
            font-size: 14px;
            color: #626262;
        }
        .value {
            font-size: 14px;
            color: $ms-color-black;
        }  
    }
    .no-search-result-status{
        font-size: 22px;
        margin: 0px 0px 10px 0px;        
        padding-bottom: 6px;
    }
    

    .search-filters-header{
        font-size: 20px;
        margin: 0px 0px 20px 0px;  
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;    
        padding-bottom: 5px;
        display: flex;
        i{
            width:25px;
            font-size:16px;            
        }

        .search-filters-header-actions{
            margin-left: auto;
            font-size: 16px;
            a{            
                text-decoration: none;
            }
        }        
    }
    .action-buttons{
        display: flex;
        margin-bottom: 10px;
        a{            
            text-decoration: none;
            margin-top: 30px;
            margin-right: 10px;
        }

        .search-filters-show-header{
            font-size: 20px;
            margin-top: 10px;
            i{
                width:25px;
                font-size:16px;            
            }          
        }

        .action-buttons-right{
            margin-left:auto;
            display: flex;
            justify-content: flex-end;
            a{
                display: flex;
                justify-content: flex-end;
            }
        }
       //medium screen	639px - 480px
@media only screen and (max-width:639px) and (min-width: 480px)  {
            .action-buttons-right{
                display: block;
            }
        }
        //small screen 320px - 479px
@media only screen and (max-width: 479px) and (min-width: 320px)  {
            .action-buttons-right{
                display: block;
            }
        }
    }
    .search-filter{
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
        margin: 15px 0px 10px 0px;  

        .search-filter-name{
            font-size: 18px;
            margin: 0px 0px 5px 0px;    
        
        }
        .search-filter-items{
            margin: 5px;
            .search-filter-item{
                display: flex;
                margin: 3px 0px 3px 0px;
                .search-filter-item-checkbox{
                    
                }
                .search-filter-item-count{
                    margin-left: auto;
                }
            }

        }
    }
    .filter-badge{     
        margin: 10px 0px 10px 0px;
        .filter-badge-item{
            border: 1px solid $ms-color-neutralTertiaryAlt;  
            vertical-align: middle;     
            padding: 1px 1px 1px 1px;
            margin-right: 5px;  
            display: inline-flex;  
            margin-bottom: 5px;
           
            .filter-badge-item-text {                
                max-width: 250px;               
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 5px;
            }
            @media (min-width: 320px) and (max-width:479px){
                .filter-badge-item-text{
                    max-width:350px;
                    flex-grow:1;
                }
            }
            @media (min-width: 640px) and (max-width:725px){
                .filter-badge-item-text{
                    max-width:350px;
                    flex-grow:1;
                }
            }
            @media (min-width: 640px) and (max-width:725px){
                .filter-badge-item-text{
                    max-width:350px;
                    flex-grow:1;
                }
            }
            .filter-badge-item-btn {              
               padding: 0px;
               margin: 0px;
            }            
        }
    }
   
}

.selected-items{     
    margin: 10px 0px 10px 0px;
    padding-bottom: 8px;
    border: 1px solid lighten($ms-color-neutralTertiaryAlt,20%);  
    background-color: #EEEEEE;

    .selected-items-header{
        margin: 3px 0px 10px 4px;
    }
    .remove-all-button{
        margin: 0px 0px 0px 5px;
        text-decoration: none;
        font-size: 15px;
    }

    .selected-item-badge{
        padding: 0px 0px 3px 5px;
        margin-left: 4px;
        border: 1px solid $ms-color-neutralTertiaryAlt;  
        vertical-align: middle;     
        margin-right: 5px;    
        display: inline-flex;  
        margin-bottom: 3px;
        width:270px;
        @media (min-width: 320px) and (max-width:479px){
            .selected-item-badge {                
                margin-left:10px; }
        }
        @media (min-width: 480px) and (max-width:600px){
            .selected-item-badge {                
                margin-left:10px;  }
        }
        .selected-item-badge-text {  
            margin-top:7px;              
            max-width: 250px;           
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px;
            .badge-label{
                color: #626262;
            }
        }
@media (min-width: 320px) and (max-width:479px){
    .selected-item-badge-text {                
        max-width: 250px; 
        flex-grow:1;}
}
@media (min-width: 480px) and (max-width:600px){
    .selected-item-badge-text {                
        max-width: 250px; 
        flex-grow:1;}
}
        .selected-item-badge-btn {              
           padding: 0px;
           margin: 0px;
        }            
    }
}

//For small screen
@media (min-width: 320px) and (max-width:479px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
   
    .filter-badge-item{
        padding: 0px ;
    }
}
//For Medium sized Screen
@media (min-width: 480px) and (max-width:639px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item{
        padding: 0px;
    }
}
//For Large sized Screen
@media (min-width: 640px) and (max-width:725px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item{
        padding: 0px ;
    }
}