@import '../../../../resources/styles/variables/references';

.order-main-section
{
      //large screen	640px - 1023px
      @media only screen and (max-width: 1023px) and (min-width:640px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //medium screen	639px - 480px
    @media only screen and (max-width:639px) and (min-width: 480px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //small screen 320px - 479px
    @media only screen and (max-width: 479px) and (min-width: 320px)  {
        margin-top:10px;
        margin-bottom:100px;
    }   
}

.create-order-nav{
    text-align: center;
    position: relative;
    top:10px;
    left: -5%;
}


.order-confirmation{
    text-align: center;
    font-family: $ms-font-family-fallbacks;
    .status-msg-header{
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
   
    .status-msg{
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.order-status-text {
    font-size: 16px;
    color: $ms-color-themePrimary;
}

.order-confirmation-msg {
    text-align: center;
}
.order-status-header{
    font-family: $ms-font-family-fallbacks;
    text-align: right;  
    margin-right: 10%; 

    .order-status{    
        font-size: 16px;        
    }
}

.create-order-title{
    font-family: $ms-font-family-fallbacks;
    font-size: 34px;
    font-weight: lighter;
    margin-top: 40px;
    margin-bottom: 40px;
}
@media (min-width: 300px) and (max-width: 768px) {
    .order-status-header {
        .order-status {
            text-align: left;
        }
    }
}

.wizard-nav-item 
{ 
    .nav-item-status
        {
            //large screen 640px - 1023px
            @media only screen and (max-width: 1023px) and (min-width:640px)  {
            display: block;
        }
            //medium screen 639px - 480px
            @media only screen and (max-width:639px) and (min-width: 480px)  {
            display: block;
        }
            //small screen 320px - 479px
            @media only screen and (max-width: 479px) and (min-width: 320px)  {
            display: block;
        } 
    }
}

.create-order-wizard
{ 
            //large screen 640px - 1023px
            @media only screen and (max-width: 1023px) and (min-width:640px)  { 
            margin-bottom: 50px;
        }
            //medium screen 639px - 480px
            @media only screen and (max-width:639px) and (min-width: 480px)  {
            margin-bottom: 50px;
        }
            //small screen 320px - 479px
            @media only screen and (max-width: 479px) and (min-width: 320px)  {
            margin-bottom: 50px;
        } 

}
