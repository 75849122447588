@import '../../../../resources/styles/variables/references';

.order-header-container{
    .order-header-sub-header{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: lighter;
    } 
    .order-header-padding{
        padding-bottom: 10px;
    }    
}

.order-header-info{   
    .header-text{
        margin-top: 10px;
        margin-bottom: 10px; 
    } 
   
    .labelHeader {
        font-size: 14px;
        color: #626262;
    }
    .labelValue {
        font-size: 16px;
        color: $ms-color-black;
        word-break: break-word;
    } 
}
button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

