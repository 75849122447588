.controls-container{
    margin: 10px 0px 0px 0px;
    @media only screen and (max-width: 1023px) and (min-width:640px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //medium screen	639px - 480px
    @media only screen and (max-width:639px) and (min-width: 480px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    //small screen 320px - 479px
    @media only screen and (max-width: 479px) and (min-width: 320px)  {
        margin-top:10px;
        margin-bottom:100px;
    }
    text-align: center;
}