@import '../../../../resources/styles/variables/references';

.order-shipment-details-header {        
    border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
}
.order-shipment-details-sub-header {
    .labelValue {
        font-size: 18px;
        .order-shipment-status{
            color: $ms-color-themePrimary;
        }
        margin-bottom: 20px;            
    }
    .actions {
        float: right;
        .details-action-button{
            color: $ms-color-themePrimary;
            size: 18px;
        }
    }
}

.order-shipment-details {
    font-family: $ms-font-family-fallbacks;
}


.Details-Footer-Button {
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-align: right;
    button {
        margin: 2px;
        min-width: 120px;
        text-align: left; 
    }
}

.row-Spacing{
    margin-top: 10px;
    margin-bottom: 0.5%;
}
.row-border-above{
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid $ms-color-neutralTertiaryAlt;
}

.align-right {
    text-align: right; 
}

@media screen and (max-width: 1023px) and (min-width:640px){
    .order-shipment-details{
        margin-bottom: 40px;
    }
}

@media screen and (max-width:639px) and (min-width: 480px){
    .order-shipment-details{
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 479px){
    .order-shipment-details{
        margin-bottom: 40px;
    }
}