@import '../styles/variables/references';

body{
    margin: 0px !important;
    font-family: $ms-font-family-fallbacks;
}


.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }

  h1 {
    font-family: $ms-font-family-fallbacks;
    font-size: $page-header-font-size;
    font-weight: lighter;
    margin-top: 31px;
    margin-bottom: 31px;
  }

  h2 {
    font-family: $ms-font-family-fallbacks;
    font-size: $page-sub-header-font-size;
    font-weight: lighter;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    font-family: $ms-font-family-fallbacks;
    font-size: $section-heading-font-size;
    font-weight: normal;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  
  .page-header {
    font-family: $ms-font-family-fallbacks;
    font-size: $page-header-font-size;
    font-weight: lighter;
    margin-top: 31px;
    margin-bottom: 31px;
  }  
  .page-header-with-border {
    font-family: $ms-font-family-fallbacks;
    font-size: $page-header-font-size;
    font-weight: lighter;
    margin-top: 31px;
    margin-bottom: 31px;
    padding-bottom: 20px;
    border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
  }  

  .page-sub-header {
    font-family: $ms-font-family-fallbacks;
    font-size: 28px;
    font-weight: lighter;
    margin-top: 20px;
    margin-bottom: 20px;
  } 

  .float-right-content{
    float: right;
  }
  .zero-padding{
    padding: 0px;
  }
  .zero-margin{
    margin : 0px;
  }

  .widthOf50Percent{
    width: 50%;
  }

  .widthOf100Percent{
    width: 100%;
  }
  .hover-content{
    cursor: pointer;
  }

  .offscreen
  {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; /* added line */
    width: 1px;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    .page-header{
      margin-bottom:40px;
    }
  }

// .container {
//   padding-right: 15px;
//   padding-left: 15px;
//   margin-right: auto;
//   margin-left: auto;
// }
// @media (min-width: 479px) {
//   .container {
//     width: 479px;
//   }
// }
// @media (min-width: 639px) {
//   .container {
//     width: 639px;
//   }
// }
// @media (min-width: 1023px) {
//   .container {
//     width: 1023px;
//   }
// }
// @media (min-width: 1365px) {
//   .container {
//     width: 1365px;
//   }
// }
// @media (min-width: 1919px) {
//   .container {
//     width: 1919px;
//   }
// }